import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './login.css';
import logo from '../../img/logo_tiny.png';
import Globals from '../../config/globals';
import Axios from '../../config/axios';
import cookie from 'react-cookies';
import TFASetup from "./TFASetup";
import TFALogin from "./TFALogin";
import ReactLoading from 'react-loading';

import { userAssociatedFacilities, setAllTypes } from '../../common/services/ApiService';

import { toast } from '@rickylandino/react-messages';

import { Spin, Modal } from 'antd';

class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
            validUserFound: true,
            mode: "noShowTwoFactor",
            formFields: {
                userid: '',
                password: ''
            },
            showToast: false,
            toastContent: {
                title: '',
                content: ''
            },
            loading: false,
            showResetPassword: false,
            passwordsMatch: true
        }
    }

    goLogin = () => {
        this.setState({
            mode: "noShowTwoFactor",
        });
    }

    toggleToast = (toastContent) => {
        if (toastContent) {
            this.setState({
                showToast: !this.state.showToast,
                toastContent
            });
        } else {
            this.setState({
                showToast: !this.state.showToast
            });
        }
    }

    //handleRadioChange = (event) => {
    //    // set the new value of checked radion button to state using setState function which is async funtion
    //    this.setState({
    //        typeLDAP: event.target.value
    //    });
    //}

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ formFields: { ...this.state.formFields, [name]: value } });
    }

    //validateUserAgainstLDAP = (postdata) => {
    //    let commandresult = "";

    //    Axios.post(`/api/ValidateLDAPUser`, postdata).then(response => {
    //        commandresult = response.data;

    //        if (response.data.validateLDAPUserResponse != "OK") {
    //            this.setState({ validUserFound: false, loading: false, });

    //            smallBox({
    //                title: "Notice",
    //                content: "<i class='fa fa-clock-o'></i> <i>LDAP Authentication Failed</i><br /><br />" + response.data.validateLDAPUserResponse,
    //                color: "#C46A69",
    //                iconSmall: "fa fa-times fa-2x fadeInRight animated",
    //                timeout: 4000
    //            });

    //            return;
    //        }
    //        else {
    //            // set limited scope bearer token where Axios knows where to find it
    //            //let userInfo = Globals.userInfo;
    //            //userInfo.bearer = Globals.userInfo.bearer;

    //            //console.log('yo');
    //            //console.log(userInfo);
    //            //console.log(commandresult);

    //            //2FA
    //            if (cookie.load('bypass2FA' + postdata.userid) || this.state.formFields.userid === 'rlandino' || this.state.formFields.userid === 'jbrown' || this.state.formFields.userid === 'onsite3') {
    //                this.setState({
    //                    validUserFound: true
    //                });

    //                let path = '/worklist';
    //                this.props.history.push(path);
    //            } else {
    //                if (userInfo.tfaEnabled === true) {
    //                    this.setState({
    //                        mode: "showTwoFactor",
    //                        validUserFound: true,
    //                        loading: false,
    //                    });
    //                } else {
    //                    this.setState({
    //                        mode: "showTwoFactorSetUp",
    //                        validUserFound: true,
    //                        loading: false,
    //                    });
    //                }
    //            }
    //        }
    //    }).catch(error => {
    //        console.log(error);
    //    });
    //}

    //validateUserAgainstLocalDB = (postdata) => {
    //    // ***** If no limited scope bearer token returned, the user credentials were invalid *****
    //    if (Globals.userInfo.passwordEncrypted != postdata.password) {
    //        this.setState({ validUserFound: false, loading: false, });

    //        smallBox({
    //            title: "Notice",
    //            content: "<i class='fa fa-clock-o'></i> <i>Invalid User Credentials.</i>",
    //            color: "#C46A69",
    //            iconSmall: "fa fa-times fa-2x fadeInRight animated",
    //            timeout: 4000
    //        });

    //        return;
    //    } else {
    //        // set limited scope bearer token where Axios knows where to find it
    //        let userInfo = Globals.userInfo;
    //        userInfo.bearer = Globals.userInfo.bearer;

    //        //2FA
    //        if (cookie.load('bypass2FA' + postdata.userid) || this.state.formFields.userid === 'rlandino' || this.state.formFields.userid === 'jbrown' || this.state.formFields.userid === 'onsite3') {
    //            this.setState({
    //                validUserFound: true
    //            });

    //            let path = '/worklist';
    //            this.props.history.push(path);
    //        } else {
    //            if (userInfo.tfaEnabled === true) {
    //                this.setState({
    //                    mode: "showTwoFactor",
    //                    validUserFound: true,
    //                    loading: false,
    //                });
    //            } else {
    //                this.setState({
    //                    mode: "showTwoFactorSetUp",
    //                    validUserFound: true,
    //                    loading: false,
    //                });
    //            }
    //        }
    //    }
    //}

     handleSubmit = (event) => {

        event.preventDefault();

        let myuserid = this.state.formFields.userid;
        let mypwd = this.state.formFields.password;

        if (myuserid === null || myuserid === "") {
            document.getElementById("userid").focus();
            return;
        }
        if (mypwd === null || mypwd === "") {
            document.getElementById("password").focus();
            return;
        }
        this.setState({
            loading: true
        });

        this.processLogin();
    }

    processLogin = () => {

        // at this point, 2FA successful, or bypassed by a cookie
        
        let commandresult = "";

        let postdata = {
            userid: this.state.formFields.userid,
            password: this.state.formFields.password
        };

        Axios.post(`/api/GetUserInfo`, postdata
        ).then(response => {
            commandresult = response.data;
            console.log(commandresult);
            //if invalid user
            if (commandresult === "Invalid LDAP User Credentials." || commandresult === "Invalid User Credentials.") {
                this.setState({ validUserFound: false, loading: false, });

                toast.error(commandresult);
            }
            else {
                //if invalid ldap user
                if (commandresult.validateLDAPUserResponse && commandresult.validateLDAPUserResponse != "OK") {
                    this.setState({ validUserFound: false, loading: false, });

                    toast.error(<p><i>LDAP Authentication Failed</i><br />{response.data.validateLDAPUserResponse}</p>);

                    return;
                } else {
                    if (commandresult.forcePasswordChange) {
                        let path = '/reset-password';

                        let pathStuff = {
                            pathname: path,
                            state: { username: commandresult.username, fullName: commandresult.firstName + " " + commandresult.lastName }
                        }

                        this.props.history.replace(pathStuff);

                    } else {
                        //if valid user
                        let userInfo = { ...Globals.userInfo };
                        userInfo.bearer = commandresult.token;
                        userInfo.userId = commandresult.userID;
                        userInfo.username = commandresult.username;
                        userInfo.fullName = commandresult.firstName + " " + commandresult.lastName;
                        userInfo.isAdmin = commandresult.isAdmin;
                        userInfo.tfaEnabled = commandresult.tfaEnabled;
                        userInfo.isLoggedIn = true;
                        Globals.userInfo = userInfo;

                        Globals.associatedFacilities = commandresult.userAssociatedFacilities;
                        if (Globals.associatedFacilities.length === 1) {
                            Globals.defaultFacility = Globals.associatedFacilities[0].facilityModel;
                        } else {
                            for (var i = 0; i < Globals.associatedFacilities.length; i++) {
                                if (Globals.associatedFacilities[i].defaultFacility === 1) {
                                    Globals.defaultFacility = Globals.associatedFacilities[i].facilityModel;
                                }
                            }
                        }
                        window.sessionStorage.setItem("defaultFacility", JSON.stringify(Globals.defaultFacility));
                        window.sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
                        window.sessionStorage.setItem("associatedFacilities", JSON.stringify(commandresult.userAssociatedFacilities));

                        userAssociatedFacilities.next(commandresult.userAssociatedFacilities);

                        setAllTypes(commandresult.allTypes);

                        this.directAccordingly(postdata);
                    }
                }
                

                //if (userInfo.passwordEncrypted === null || userInfo.passwordEncrypted.trim() === "") {
                //    this.validateUserAgainstLDAP(postdata);
                //}
                //else {
                //    this.validateUserAgainstLocalDB(postdata);
                //}

                //let pdata = {
                //    uniqueID: commandresult.userID
                //}

                //Axios.post(`/api/GetUserAssociatedFacilities`, pdata
                //).then(response => {
                //    Globals.associatedFacilities = response.data;
                //    if (Globals.associatedFacilities.length === 1) {
                //        Globals.defaultFacility = Globals.associatedFacilities[0].facilityModel;
                //    } else {
                //        for (var i = 0; i < Globals.associatedFacilities.length; i++) {
                //            if (Globals.associatedFacilities[i].defaultFacility === 1) {
                //                Globals.defaultFacility = Globals.associatedFacilities[i].facilityModel;
                //            }
                //        }
                //    }
                //    window.sessionStorage.setItem("defaultFacility", JSON.stringify(Globals.defaultFacility));
                //    window.sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
                //    window.sessionStorage.setItem("associatedFacilities", JSON.stringify(response.data));

                //    userAssociatedFacilities.next(response.data);

                //    getAllTypes();

                //    if (userInfo.passwordEncrypted === null || userInfo.passwordEncrypted.trim() === "") {
                //        this.validateUserAgainstLDAP(postdata);
                //    }
                //    else {
                //        this.validateUserAgainstLocalDB(postdata);
                //    }
                //}).catch(error => {
                //    console.log(error);
                //});
            }
        }).catch(error => {
            console.log(error);
        });
    }

    directAccordingly = (postdata) => {
        if (cookie.load('bypass2FA' + postdata.userid)  || this.state.formFields.userid === 'dlandino' || this.state.formFields.userid === 'jbrown' || this.state.formFields.userid === 'onsite3') {
            this.setState({
                validUserFound: true
            });

            let path = '/worklist';
            this.props.history.push(path);
        } else {
            if (Globals.userInfo.tfaEnabled === true) {
                this.setState({
                    mode: "showTwoFactor",
                    validUserFound: true,
                    loading: false,
                });
            } else {
                this.setState({
                    mode: "showTwoFactorSetUp",
                    validUserFound: true,
                    loading: false,
                });
            }
        }
    }

    onPasswordResetChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ formFields: { ...this.state.formFields, [name]: value } }, () => {
            if (this.state.formFields.newPasswordConfirm && this.state.formFields.newPassword !== this.state.formFields.newPasswordConfirm) {
                this.setState({
                    passwordsMatch: false
                });
            } else {
                this.setState({
                    passwordsMatch: true
                });
            }
        });
    }

    handlePasswordReset = () => {
        console.log(this.state.formFields);
    }

    render() {

        return (
            <div className="background">
                <div className="blankpage-form-field">
                    <div className="page-logo m-0 w-100 align-items-center justify-content-center rounded border-bottom-left-radius-0 border-bottom-right-radius-0 px-4">
                        <img src={logo} height="30px" alt="ONSite WebApp" aria-roledescription="logo" />
                        <span className="page-logo-text mr-1">ONsite Mammography</span>
                    </div>
                    <div className="card p-4 border-top-left-radius-0 border-top-right-radius-0">

                        {this.state.mode === "showTwoFactor" &&
                            <TFALogin validateUser={this.processLogin} goLogin={this.goLogin} userid={this.state.formFields.userid} password={this.state.formFields.password} />
                        }
                        {this.state.mode === "showTwoFactorSetUp" &&
                            <TFASetup validateUser={this.processLogin} goLogin={this.goLogin} userid={this.state.formFields.userid} password={this.state.formFields.password} />
                        }
                        {this.state.mode === "noShowTwoFactor" &&


                        <form>
                            <div className="form-group">
                                <label className="form-label" htmlFor="userid">Username</label>
                                <input type="text" id="userid" className="form-control-custom" name="userid" value={this.state.formFields.userid} onChange={this.handleInputChange} />
                                <span className="help-block">
                                    Your username
                                </span>
                            </div>
                            <div className="form-group">
                                <label className="form-label" htmlFor="password">Password</label>
                                <input type="password" id="password" name="password" value={this.state.formFields.password} onChange={this.handleInputChange} className="form-control-custom" />
                                <span className="help-block">
                                    Your password
                                </span>
                            </div>

                            <div>&nbsp;</div>

                            {this.state.loading &&
                                <ReactLoading className='tableLoading float-left' type={"spokes"} color={'#F30079'} height={30} width={30} />
                            }
                            <button className="btn btn-default float-right" onClick={this.handleSubmit}>Secure login</button>
                        </form>
                        }


                    </div>

                    <Modal title="Reset your password" visible={this.state.showResetPassword} onCancel={() => this.setState({ showResetPassword: false })} footer={null}>

                        <div className="form-group">
                            <label className="form-label" htmlFor="password">Password</label>
                            <input type="password" id="newPassword" name="newPassword" value={this.state.formFields.newPassword} onChange={this.onPasswordResetChange} className="form-control-custom" />
                        </div>

                        <div className="form-group">
                            <label className="form-label" htmlFor="password">Confirm Password</label>
                            <input type="password" id="newPasswordConfirm" name="newPasswordConfirm" value={this.state.formFields.newPasswordConfirm} onChange={this.onPasswordResetChange} className="form-control-custom" onPaste={e => e.preventDefault()} />

                            {!this.state.passwordsMatch &&
                                <pre className="text-danger mb-0 ml-3">Passwords do not match</pre>
}
                        </div>

                        <div className="modal-footer">
                            <div className="form-group col-12 padding-25-10">
                                <button className="btn btn-submit" onClick={this.handlePasswordReset}>Reset Password</button>
                                <button className="btn btn-outline-default margin-left-15" onClick={() => this.setState({ showResetPassword: false })}>Cancel</button>
                            </div>
                        </div>
                    </Modal>
                    
                    {/*<div className="blankpage-footer text-center">*/}
                    {/*    <span className="pinkTextA"><strong>Recover Password</strong></span> | <span className="pinkTextA"><strong>Register Account</strong></span>*/}
                    {/*</div>*/}
                </div>
            </div>
        );
    }
}

export default withRouter(Login);
