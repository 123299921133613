import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import MaskedInput from 'react-text-mask'

import { FaAngleDown } from 'react-icons/fa';
import $ from 'jquery';
import { Dropdown } from 'react-bootstrap';

import Axios from '../../config/axios';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';

import Globals from '../../config/globals';

import { getPatientsByLookup, patientResults, loadingPatientResults, allRecallTypes, userAssociatedFacilities, allInsuranceCompanies } from '../../common/services/ApiService';

import { Select } from 'antd';

import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

class PatientSearchForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formFields: {
                lName: '',
                fName: '',
                dob: '',
                mrn: '',
                referringMD: '',
                startDate: null,
                endDate: null,
                selectedRecallType: '',
                primaryRecallTypeID: '',
            },
            recallTypesList: [],
            facilityList: [],
            issuersList: [],
            selectedFacilities: [],
            selectedIssuers: [],
            insuranceCompanyList: [],
            selectedInsurance: []
        }

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.alive = true;
        document.addEventListener('mousedown', this.handleClickOutside);

        combineLatest(
            allRecallTypes,
            userAssociatedFacilities, 
            allInsuranceCompanies
        ).pipe(takeWhile(() => this.alive)).subscribe(([x1, x2, x3]) => {

            //let defaultFacility = x2.find(f => f.defaultFacility === 1)?.facilityModel?.facilityID;

            //let selectedFacilities = [];
            //if (defaultFacility) {
            //    selectedFacilities = [defaultFacility];
            //}

            this.setState({
                recallTypesList: x1,
                listsLoaded: true,
                facilityList: x2,
                insuranceCompanyList: x3,
                //selectedFacilities
            })
        });

        this.getIssuers();

    }

    componentWillUnmount() {
        this.alive = false;
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    /**
     * Set the wrapper ref
     */
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
        var isAntItem = $(event.target).hasClass('ant-select-item-option-content');
        
        if (this.state.showDropdown && this.wrapperRef && !this.wrapperRef.contains(event.target) && !isAntItem) {
            this.toggleDropdown();
        }
    }

    toggleDropdown = () => {
        if (this.state.showDropdown) {
            this.setState({
                showDropdown: false,
                formFields: {
                    lName: '',
                    fName: '',
                    dob: '',
                    mrn: '',
                    referringMD: '',
                    startDate: null,
                    endDate: null,
                    issuer: ''
                }
            });
        } else {
            this.setState({ showDropdown: true });
        }
    }

    getRecallTypes = () => {
        Axios.get(`/api/GetAllRecallTypes`
        ).then(response => {
            let recallTypesList = response.data;
            this.setState({
                recallTypesList
            });
        }).catch(error => {
            console.log(error);
        });
    }

    getIssuers = () => {
        Axios.get(`/api/GetAllIssuers`
        ).then(response => {
            let issuersList = response.data;
            this.setState({
                issuersList
            });
        }).catch(error => {
            console.log(error);
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ formFields: { ...this.state.formFields, [name]: value } });
    }

    handleDateChange = (name, date) => {
        this.setState({ formFields: { ...this.state.formFields, [name]: date } }, () => {
            if (JSON.stringify(this.state.formFields) !== JSON.stringify(this.formFields)) {
                this.isDirty = true;  // dirty
            }

        });
    }

    handleDropdownChange = (value) => {
        this.setState({
            value
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        //Globals.loadingPatients = true;
        //this.props.updatePatientsLoading();

        loadingPatientResults.next(true);
        
        let currentRoute = this.props.history.location.pathname;

        if (currentRoute === '/patients') {
            this.props.history.replace({
                pathname: '/patients'
            });
        }
        else {
            this.props.history.push({
                pathname: '/patients'
            });
        }

        let userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

        let postdata = {
            patientModel: {
                fName: this.state.formFields.fName,
                lName: this.state.formFields.lName,
                dob: this.state.formFields.dob,
                mrn: this.state.formFields.mrn                
            },
            facilityList: this.state.selectedFacilities,
            recallStartDate: this.state.formFields.startDate,
            recallEndDate: this.state.formFields.endDate,
            issuerList: this.state.selectedIssuers,
            insuranceList: this.state.selectedInsurance,
            primaryRecallTypeID: this.state.formFields.primaryRecallTypeID !== '' ? this.state.formFields.primaryRecallTypeID : null,
            searchType: event.target.id,
            userID: userInfo.userId,
            showInactivePatients: this.state.formFields.showInactivePatients
        }

        Globals.patientSearchCriteria = JSON.stringify(postdata);

        Globals.searchCriteria = postdata;
        //Globals.patients = [];

        $(".search-menu").removeClass("show");
        $(".search-menu-left").removeClass("show");

        this.setState({
            formFields: {
                fName: '',
                lName: '',
                dob: '',
                mrn: ''
            }
        });

        getPatientsByLookup(postdata).then(data => {
            patientResults.next(data);
            loadingPatientResults.next(false);
        });
    }

    onSelect = (selectedFacilities, facility) => {
        this.setState({
            selectedFacilities
        });
    }

    onRemove = (selectedFacilities, facility) => {
        this.setState({
            selectedFacilities
        });
    }

    onSelectIssuer = (selectedIssuers) => {
        this.setState({
            selectedIssuers
        });
    }

    onRemoveIssuer = (selectedIssuers) => {
        this.setState({
            selectedIssuers
        });
    }


    render() {
        const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
            <span href=""
                ref={ref}
                onClick={e => {
                    e.preventDefault();
                    onClick(e);
                }} className="inline-button" id="dropdownMenuOffset" data-offset="0,10">

                <FaAngleDown />

            </span>
        ));
        const { Option } = Select;

        return (
            <div>
                    <Dropdown className="search-div" show={this.state.showDropdown} ref={this.setWrapperRef}>


                        <form onSubmit={this.handleSubmit} id="quick">
                            <input type="text" id="" name="lName" placeholder="Search for a patient" value={this.state.formFields.lName} onChange={this.handleInputChange} className="form-control-custom" />
                        </form>
                        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" onClick={() => this.toggleDropdown()}
                            onBlur={() => this.toggleDropdown()}>
                            Custom toggle
                        </Dropdown.Toggle>
                        <span onClick={this.handleSubmit} title="Search" className="search-inline-button" id="quick">
                        <i className="fa fa-search" id="quick"/>
                        </span>

                    <Dropdown.Menu className={"dropdown-menu " + (this.props.from === 'nav' ? 'search-menu' : 'search-menu-left')} aria-labelledby="dropdownMenuOffset">
                            <div className="panel-container show">
                                <div className="panel-content">
                                    <form onSubmit={this.handleSubmit} id="detailed">

                                        <div className="row">
                                            <div className="col col-md-6 form-group">
                                                <label className="form-label">Last Name</label>
                                                <input type="text" id="" name="lName" value={this.state.formFields.lName} onChange={this.handleInputChange} className="form-control-custom" />
                                            </div>

                                            <div className="col col-md-6 form-group">
                                                <label className="form-label">First Name</label>
                                                <input type="text" name="fName" value={this.state.formFields.fName} onChange={this.handleInputChange} className="form-control-custom" />
                                            </div>

                                            <div className="col col-md-6 form-group">
                                                <label className="form-label">MRN</label>
                                                <input type="text" name="mrn" value={this.state.formFields.mrn} onChange={this.handleInputChange} className="form-control-custom" />
                                            </div>

                                            <div className="form-group col-lg-6">
                                                <label className="form-label">DOB</label>
                                                <DatePicker
                                                    onChange={(date) => this.handleDateChange('dob', date)}
                                                    className="form-control-custom"
                                                    selected={this.state.formFields.dob && Moment(this.state.formFields.dob).toDate()}
                                                    customInput={
                                                        <MaskedInput
                                                            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                                            keepCharPositions={true}
                                                            guide={true}
                                                        />

                                                    }
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                />
                                            </div>
                                            <div className="form-group col-lg-6">
                                                <label className="form-label">Facility</label>

                                                <Select
                                                    mode="multiple"
                                                    maxTagCount={1}
                                                    onChange={(selectedFacilities) => this.setState({ selectedFacilities })}
                                                    allowClear
                                                    placeholder="Please select 1 or more"
                                                    className="form-control-custom w-100"
                                                    bordered={false}
                                                    value={this.state.selectedFacilities}
                                                >
                                                    {this.state.facilityList.map((fac, idx) => <Option key={idx} value={fac.facilityModel.facilityID}>{fac.facilityModel.facilityName}</Option>)}
                                                </Select>

                                            </div>

                                        <div className="form-group col-lg-6">
                                            <label className="form-label">Issuer</label>
                                            <Select
                                                mode="multiple"
                                                maxTagCount={1}
                                                onChange={(selectedIssuers) => this.setState({ selectedIssuers })}
                                                allowClear
                                                placeholder="Please select 1 or more"
                                                className="form-control-custom w-100"
                                                bordered={false}
                                                value={this.state.selectedIssuers}
                                            >
                                                {this.state.issuersList.map((i, idx) => <Option key={idx} value={i.issuerofPatientID}>{i.issuerName}</Option>)}
                                            </Select>
                                                
                                        </div>

                                        <div className="form-group col-lg-6">
                                            <label className="form-label">Recall Type</label>
                                            <select className="form-control-custom" value={this.state.formFields.primaryRecallTypeID} name="primaryRecallTypeID" onChange={this.handleInputChange}>
                                                <option></option>
                                                {this.state.recallTypesList.map((rt, idx) => <option key={idx} value={rt.recallTypeID}>{rt.recallTypeDescription}</option>)}
                                            </select>
                                        </div>


                                        <div className="form-group col-lg-6">
                                            <label className="form-label">Insurance Companies</label>

                                            <Select
                                                mode="multiple"
                                                maxTagCount={1}
                                                onChange={(selectedInsurance) => this.setState({ selectedInsurance })}
                                                allowClear
                                                placeholder="Please select 1 or more"
                                                className="form-control-custom w-100"
                                                bordered={false}
                                                value={this.state.selectedInsurance}
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {this.state.insuranceCompanyList.map((i, idx) => <Option key={idx} value={i.insuranceID}>{i.insCompName}</Option>)}
                                            </Select>

                                        </div>

                                        <div className="form-group col-lg-6">
                                            <label className="form-label">Show Inactive Patients</label>
                                            <div className="form-control-custom no-border">
                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                    <input type="checkbox" className="custom-control-input" id="showInactivePatients" name="showInactivePatients" value={this.state.formFields.showInactivePatients || ''} onChange={this.handleInputChange} />
                                                    <label className="custom-control-label"></label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group col-lg-6">
                                            <div className="row">
                                                <div className="col-12">
                                                    <label className="form-label">Recall Date</label>
                                                    <DatePicker
                                                        onChange={(date) => this.handleDateChange('startDate', date)}
                                                        selectsStart
                                                        startDate={this.state.formFields.startDate}
                                                        endDate={this.state.formFields.endDate}
                                                        className="form-control-custom"
                                                        selected={this.state.formFields.startDate && Moment(this.state.formFields.startDate).toDate()}
                                                        placeholderText='Start Date'
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        customInput={
                                                            <MaskedInput
                                                                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                                                keepCharPositions={true}
                                                                guide={true}
                                                            />
                                                        }
                                                    />
                                                </div>
                                                <div className="col-12 mt-3">
                                                    <DatePicker
                                                        onChange={(date) => this.handleDateChange('endDate', date)}
                                                        selectsEnd
                                                        startDate={this.state.formFields.startDate}
                                                        endDate={this.state.formFields.endDate}
                                                        minDate={this.state.formFields.startDate}
                                                        className="form-control-custom"
                                                        selected={this.state.formFields.endDate && Moment(this.state.formFields.endDate).toDate()}
                                                        placeholderText='End Date'
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        customInput={
                                                            <MaskedInput
                                                                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                                                keepCharPositions={true}
                                                                guide={true}
                                                            />
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        
                                        </div>

                                        <div className="modal-footer">
                                            <div className="form-group col-12 padding-25-10">
                                                <button className="btn btn-submit" type="submit" onClick={this.handleSubmit}>Search</button>
                                                <button className="btn btn-outline-default margin-left-15" type="button" onClick={this.toggleDropdown}>Cancel</button>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>

                        </Dropdown.Menu>
                    </Dropdown>
            </div>
        );
    }
}

export default withRouter(PatientSearchForm);