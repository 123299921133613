import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import moment from 'moment'

import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../../css/calendar-override.css';

import { Container } from 'react-bootstrap';

import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { userAssociatedFacilities } from '../../common/services/ApiService';
import { getWeeklyExamsByMonth, insertWeeklyExam, updateWeeklyExam } from '../../common/services/ExamService';

import MediaQuery from 'react-responsive'

class DailyExamDashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showPane: false,
            formFields: {
                facilityID: '',
                wellWomanExams: null,
            },
            displayDateString: '',
            weeklyExams: [],
            userAssociatedFacilities: [],
            examDict: {}
        }

        this.alive = false;
    }

    componentDidMount() {
        this.alive = true;

        combineLatest(
            userAssociatedFacilities
        ).pipe(takeWhile(() => this.alive)).subscribe(([af]) => {
            if (af?.length > 0) {

                let defaultFacility = af.find(f => f.defaultFacility === 1)?.facilityModel?.facilityID || af[0].facilityModel?.facilityID;
                let facilityID = this.state.formFields.facilityID === '' ? defaultFacility : this.state.formFields.facilityID;
                getWeeklyExamsByMonth().then(data => {
                    this.setState({
                        userAssociatedFacilities: af,
                        loaded: true,
                        formFields: {
                            facilityID
                        },
                        examDict: data
                    });
                    this.customizeExams(data[facilityID]);
                });
            }
        });
    }

    componentWillUnmount() {
        this.alive = false;
    }

    customizeExams = (data) => {
        if (data) {
            let weeklyExams = data.map(d => ({
                ...d,
                id: d.weeklyExamID,
                title: 'yes',
                startDate: moment(d.fromDate).toDate()
            }));

            this.setState({
                weeklyExams
            });
        }
        
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === 'facilityID') {
            this.customizeExams(this.state.examDict[value] || []);
        }

        this.setState({ formFields: { ...this.state.formFields, [name]: value } });
    }

    handleAdd = ({ start, end }) => {
        this.state.weeklyExams.forEach(d => {
            if (moment(d.fromDate).isSame(start, 'month') && moment(d.fromDate).isSame(start, 'day') && moment(d.fromDate).isSame(start, 'year')) {
                let formFields = d;

                this.setState({
                    formFields
                });
            }
        });

        var displayDateString = moment(start).format('dddd') + ", " + moment(start).format('MMMM Do YYYY');

        this.setState({
            showPane: true,
            displayDateString,
            formFields: { ...this.state.formFields, fromDate: start }
        });
    }

    handleEdit = (event) => {
        var displayDateString = moment(event.fromDate).format('dddd') + ", " + moment(event.fromDate).format('MMMM Do YYYY');

        this.setState({
            showPane: true,
            displayDateString,
            formFields: event
        });
    }

    handleSubmit = () => {
        let weeklyExam = this.state.formFields;
        weeklyExam.wellWomenExams = weeklyExam.wellWomenExams ? parseInt(weeklyExam.wellWomenExams) : 0;

        if (weeklyExam.weeklyExamID) {
            updateWeeklyExam(weeklyExam).then(data => {
                this.customizeExams(data[this.state.formFields.facilityID]);

                this.setState({
                    formFields: {
                        facilityID: this.state.formFields.facilityID
                    },
                    showPane: false
                })
            });
        } else {
            weeklyExam.inactiveYN = '0';
            weeklyExam.deleteYN = '0';
            insertWeeklyExam(weeklyExam).then(data => {
                this.customizeExams(data[this.state.formFields.facilityID]);

                this.setState({
                    formFields: {
                        facilityID: this.state.formFields.facilityID
                    },
                    showPane: false
                })
            });
        }
    }

    hidePane = () => {
        let newFF = {};
        newFF.facilityID = this.state.formFields.facilityID;

        this.setState({
            showPane: false,
            formFields: newFF
        });
    }

    render() {
        const localizer = momentLocalizer(moment)

        function EventAgenda({ event }) {
            return (
                <span>
                    <em style={{ color: 'pink' }}>Added</em>
                </span>
            )
        }

        function Event({ event }) {
            return (
                <span>
                    <i className="fas fa-circle"></i>
                </span>
            )
        }

        const theme = window.sessionStorage.getItem("theme");

        return (
            <div>
                

                <Container>
                    <div className="row">
                        <div className="form-group col-lg-4 mb-3">
                            <label className="form-label">Selected Facility</label>
                            <select className="form-control-custom" value={this.state.formFields.facilityID || ''} name="facilityID" onChange={this.handleInputChange}>
                                {this.state.userAssociatedFacilities.map((f, idx) => <option key={idx} value={f.facilityModel.facilityID}>{f.facilityModel.facilityName}</option>)}
                            </select>
                        </div>
                    </div>
                    <hr />
                    <MediaQuery minWidth={769}>
                    <Calendar
                        selectable
                        localizer={localizer}
                        startAccessor="startDate"
                        endAccessor="startDate"
                        events={this.state.weeklyExams}
                        style={{ height: '75vh' }}
                        onSelectEvent={event => this.handleEdit(event)}
                        onSelectSlot={this.handleAdd}
                        components={{
                            event: Event,
                            agenda: {
                                event: EventAgenda,
                            },

                        }}
                        />
                    </MediaQuery>
                    <MediaQuery maxWidth={768}>
                        <Calendar
                            selectable
                            defaultView={Views.DAY}
                            localizer={localizer}
                            startAccessor="startDate"
                            endAccessor="startDate"
                            events={this.state.weeklyExams}
                            style={{ height: '75vh' }}
                            onSelectEvent={event => this.handleEdit(event)}
                            onSelectSlot={this.handleAdd}
                            components={{
                                event: Event,
                                agenda: {
                                    event: EventAgenda,
                                },

                            }}
                        />
                    </MediaQuery>
                </Container>

                <SlidingPane
                    className='w-50'
                    overlayClassName='pinkCard'
                    className={theme === 'dark' ? "dark-theme" : 'light-theme'}
                    isOpen={this.state.showPane}
                    title='Daily Exam'
                    onRequestClose={this.hidePane}
                >
                    <div className="slide-pane-body" ref={(div) => {
                        this.messageList = div;
                    }}>
                        
                        <div className="row">
                            <div className="col col-12">
                                <h3>{this.state.displayDateString}</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-lg-6 col-12 mb-3">
                                <label className="form-label">Well Woman Exams</label>
                                <input type="number" name="wellWomenExams" value={this.state.formFields.wellWomenExams || ''} onChange={this.handleInputChange} className="form-control-custom" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-lg-6 col-12 mb-3">
                                <label className="form-label">Scheduled Mammograms</label>
                                <input type="number" name="mammograms" value={this.state.formFields.mammograms || ''} onChange={this.handleInputChange} className="form-control-custom" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-lg-6 col-12 mb-3">
                                <label className="form-label">Add On Exams</label>
                                <input type="number" name="addOn" value={this.state.formFields.addOn || ''} onChange={this.handleInputChange} className="form-control-custom" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-lg-6 col-12 mb-3">
                                <label className="form-label">No Shows</label>
                                <input type="number" name="noShow" value={this.state.formFields.noShow || ''} onChange={this.handleInputChange} className="form-control-custom" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-lg-6 col-12 mb-3">
                                <label className="form-label">Cancelled and Rescheduled</label>
                                <input type="number" name="cancelledAndRescheduled" value={this.state.formFields.cancelledAndRescheduled || ''} onChange={this.handleInputChange} className="form-control-custom" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-lg-6 col-12 mb-3">
                                <label className="form-label">Cancelled and NOT Rescheduled</label>
                                <input type="number" name="cancelledAndNotRescheduled" value={this.state.formFields.cancelledAndNotRescheduled || ''} onChange={this.handleInputChange} className="form-control-custom" />
                            </div>
                        </div>
                    </div>

                    <div className="modal-footer">
                        <div className="form-group col-12 padding-25-10">
                            <button className="btn btn-submit" onClick={this.handleSubmit}>{this.state.formFields.weeklyExamID ? <span>Update</span> : <span>Save New</span>}</button>

                            <button className="btn btn-outline-default margin-left-15" onClick={this.hidePane}>Cancel</button>
                        </div>
                    </div>
                </SlidingPane>
            </div>    
        );
    }
}

export default withRouter(DailyExamDashboard);