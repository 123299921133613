import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ProviderTable from './ProviderTable';
import Axios from '../../../config/axios';
import NumberFormat from 'react-number-format'
import { Modal } from 'react-bootstrap';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Providers extends Component {

    constructor(props) {
        super(props);

        this.state = {
            providers: [],
            provider: null,
            insuranceCompany: null,
            loading: true,
            loadProvider: true,
            showModal: false,
            formFields: {
                defRadiologist: "0",
                inactiveYN: "0"
            },
            showToast: false,
            toastContent: {
                title: '',
                content: ''
            }
        }

        this.handleClick = this.handleClick.bind(this);
        this.GetAllProviders = this.GetAllProviders.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.toggleToast = this.toggleToast.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.notifyFail = this.notifyFail.bind(this);
        this.notifySuccess = this.notifySuccess.bind(this);

    }

    componentDidMount() {
        this.GetAllProviders();
    }

    notifySuccess = () => toast.success('Provider updated.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true
    });

    notifyFail = () => toast.error('Something went wrong', {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true
    });

    handleClick(event) {
        event.preventDefault();

        switch (event.target.id) {
            case 'backToResults':

                this.setState({
                    patient: null,
                    loading: true,
                    loadPatient: true
                }, () => this.GetAllPatients());
                break;
            case 'closeModal':
                this.setState({
                    showModal: !this.state.showModal
                });
                break;
            default:
                break;
        }
    }

    editProvider(provider) {

        this.setState({
            formFields: provider,
            showModal: true
        });
    }

    toggleModal(event) {
        event.preventDefault();

        this.setState({
            formFields: {
                defRadiologist: "0",
                inactiveYN: "0"
            },
            showModal: !this.state.showModal
        });
    }

    toggleToast(toastContent) {
        if (toastContent) {
            this.setState({
                showToast: !this.state.showToast,
                toastContent
            });
        } else {
            this.setState({
                showToast: !this.state.showToast
            });
        }
    }

    GetAllProviders() {
        Axios.get(`/api/GetAllProviders`
        ).then(response => {
            this.setState({
                providers: response.data,
                loading: false
            });
        }).catch(error => {
            console.log(error);
        });
    }

    handleInputChange(event) {
        const target = event.target;
        var value = "";
        if (target.type === 'checkbox') {
            value = target.checked === true ? "1" : "0";
        }
        else {
            value = target.value;
        }
        const name = target.name;

        this.setState({ formFields: { ...this.state.formFields, [name]: value } });
    }

    handleSubmit(event) {
        event.preventDefault();

        let ffCopy = { ...this.state.formFields };

        ffCopy.phoneNo = ffCopy.phoneNo.toString();
        ffCopy.phoneNo = ffCopy.phoneNo.replace('(', '');
        ffCopy.phoneNo = ffCopy.phoneNo.replace(')', '');
        ffCopy.phoneNo = ffCopy.phoneNo.replace('-', '');
        ffCopy.phoneNo = ffCopy.phoneNo.replace(' ', '');

        ffCopy.phoneNo = parseInt(ffCopy.phoneNo);

        let postdata = {
            provider: ffCopy
        };

        let toastContent = {
            title: '',
            content: ''
        };

        if (ffCopy.providerID) {
            //if updating current entry
            
            Axios.post(`/api/UpdateProvider`, postdata
            ).then(response => {
                toastContent.title = 'Success';
                toastContent.content = "Provider has been added."
                this.toggleToast(toastContent);
                this.setState({
                    showModal: !this.state.showModal
                });
                this.GetAllProviders();
            }).catch(error => {
                this.notifyFail('Fail');
            });
        } else {
            //if new entry
            
            Axios.post(`/api/InsertProvider`, postdata
            ).then(response => {
                toastContent.title = 'Success';
                toastContent.content = "Provider has been added."
                this.toggleToast(toastContent);
                this.setState({
                    showModal: !this.state.showModal
                });
                this.GetAllProviders();
            }).catch(error => {
                console.log(error);
            });
        }
    }

    render() {
        return (
            <div>
                {
                    this.state.loadProvider &&
                        <div className="row">
                            < div className="col-lg-12" >
                            <div className="panel-hdr">
                                <h2>
                                    Provider Maintenance
                            </h2>

                                <button type="button" onClick={this.toggleModal} className="btn btn-outline-primary margin-0-10">
                                    New
                                </button>
                            </div>
                                <div className="frame-wrap">
                                {!this.state.loading &&
                                    <ProviderTable data={this.state.providers} editProvider={(provider) => this.editProvider(provider)} />
                                    }

                                </div>
                            </div >
                        </div >
                }

                <Modal size='lg' show={this.state.showModal} onHide={this.toggleModal} backdrop='static'>
                    <Modal.Header>
                        Add New Provider
                        <span className="alignRight a hover" id="closeModal" onClick={this.handleClick}>&times;</span>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="panel-content">
                            <form>
                                <div className="row">
                                    <div className="form-group col-lg-4">
                                        <label className="form-label">First Name</label>
                                        <input type="text" objtype="patient" name="fName" value={this.state.formFields.fName || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <label className="form-label">Middle Name</label>
                                        <input type="text" objtype="patient" name="mName" value={this.state.formFields.mName || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <label className="form-label">Last Name</label>
                                        <input type="text" name="lName" value={this.state.formFields.lName || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <label className="form-label">Title</label>
                                        <select className="form-control-custom" name="title" value={this.state.formFields.title || ''} onChange={this.handleInputChange}>
                                            <option hidden>Please Select</option>
                                            <option>DO</option>
                                            <option>MD</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <label className="form-label">Email Address</label>
                                        <input type="text" name="email" value={this.state.formFields.email || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <label className="form-label">Phone Number</label>
                                        <NumberFormat className="form-control-custom" name="phoneNo" value={this.state.formFields.phoneNo || ''} onChange={this.handleInputChange} format="(###) ###-####" placeholder="Cell Phone" mask="_" />
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <label className="form-label">Specialty</label>
                                        <select className="form-control-custom" name="specialty" value={this.state.formFields.specialty || ''} onChange={this.handleInputChange}>
                                            <option hidden>Please Select</option>
                                            <option>OB</option>
                                            <option>Radiologist</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-lg-4 text-center">
                                        <div className="custom-control custom-checkbox custom-control-inline checkbox-margin">
                                            <input type="checkbox" className="custom-control-input" id="inactiveYN" name="inactiveYN" checked={this.state.formFields.inactiveYN === '1' ? true : false} onChange={this.handleInputChange} />
                                            <label className="custom-control-label" htmlFor="inactiveYN">Inactive</label>
                                        </div>
                                    </div>
                                    <div className="form-group col-lg-4 text-center">
                                        <div className="custom-control custom-checkbox custom-control-inline checkbox-margin">
                                            <input type="checkbox" className="custom-control-input" id="defRadiologist" name="defRadiologist" checked={this.state.formFields.defRadiologist === '1' ? true : false} onChange={this.handleInputChange} />
                                            <label className="custom-control-label" htmlFor="defRadiologist">Default Radiologist</label>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" onClick={this.handleSubmit} className="btn btn-primary">
                            Save
                        </button>
                        <span className="a hover" id="closeModal" onClick={this.handleClick}>Cancel</span>
                        
                    </Modal.Footer>
                </Modal>

            </div>
        );
    }
}

export default withRouter(Providers);