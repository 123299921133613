import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import Globals from '../../config/globals';
import { getInsuranceCompaniesByFacility, updateInsuranceItem } from '../../common/services/MaintenanceService';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Moment from 'moment';
import $ from 'jquery';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import { Empty } from 'antd';

import { Modal } from 'react-bootstrap';

import { toast } from '@rickylandino/react-messages';

function Insurance() {

    const { register, handleSubmit, setValue, getValues, watch, setError, clearErrors,
        formState: { errors }, control } = useForm();

    const { SearchBar } = Search;

    var mounted;
    const [selectedFacility, setSelectedFacility] = useState({});

    const [modalInfo, setModalInfo] = useState({
        showModal: false,
        insuranceItem: {
            name: '',
            active: true
        }
    });

    const [state, setState] = useState({
        insuranceList: [],
        loaded: false
    });

    useEffect(() => {
        mounted = true;

        let defaultFacility = JSON.parse(window.sessionStorage.getItem("defaultFacility"));
        console.log(defaultFacility);
        if (defaultFacility.facilityID) {
            setValue("facilityID", defaultFacility.facilityID);

            setFacility(defaultFacility.facilityID);

            setSelectedFacility(defaultFacility);
        } 

        return () => {
            mounted = false;
        }
    }, []);

    // Callback version of watch.  It's your responsibility to unsubscribe when done.
    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (name === 'facilityID') {
                setFacility(value.facilityID);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    function setFacility(facilityID) {
        getInsuranceCompaniesByFacility(facilityID).then(data => {

            setState({
                insuranceList: data,
                loaded: true
            });
        });
    }

    function closeModal() {
        setModalInfo({
            showModal: false,
            insuranceItem: {
                name: '',
                active: true
            }
        });
    }

    function handleUpdate() {
        var insuranceItem = modalInfo.insuranceItem;

        insuranceItem.inactiveYN = getValues().inactiveYN ? 1 : 0;
        insuranceItem.insCompName = getValues().insuranceCompanyName;

        console.log(insuranceItem);

        setState({
            ...state,
            loaded: false
        });

        updateInsuranceItem(insuranceItem).then(data => {
            toast.success("Insurance Updated");

            setState({
                insuranceList: [...data],
                loaded: true
            });

            closeModal();
        });
    }

    function openBlank() {
        setValue("inactiveYN", false);
        setValue("insuranceCompanyName", '');

        console.log(getValues().facilityID);

        setModalInfo({
            show: true,
            insuranceItem: {
                name: '',
                active: true,
                facilityID: getValues().facilityID
            }
        });
    }

    const columns = [{
        dataField: 'insCompName',
        text: 'Insurance Company',
        sort: true
    }];

    const sizePerPageOptionRenderer = ({
        text,
        page,
        onSizePerPageChange
    }) => (
        <li
            key={text}
            role="presentation"
            className="form-control-custom hover"
            tabIndex="-1"
            data-page={page}
            onMouseDown={(e) => {
                e.preventDefault();
                onSizePerPageChange(page);
            }}
            style={{ color: 'pink' }}
        >
            <span
                tabIndex="-1"
                role="menuitem"
                data-page={page}
                onMouseDown={(e) => {
                    e.preventDefault();
                    onSizePerPageChange(page);
                }}
                style={{ color: 'rgba(243, 0, 121, 1)' }}
            >
                {text}
            </span>
        </li>
    );

    const pagination = {
        sizePerPage: 5,
        sizePerPageList: [{ text: "5", value: 5 }, { text: "10", value: 10 }, { text: "50", value: 50 }, { text: "100", value: 100 }], // A numeric array is also available. the purpose of above example is custom the text
        sizePerPageOptionRenderer
    };

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            console.log(row);

            setValue("insuranceCompanyName", row.insCompName);
            setValue("inactiveYN", row.inactiveYN);

            setModalInfo({
                insuranceItem: row,
                show: true
            });

        }
    };

    const rowClasses = (row, rowIndex) => {
        if (row.inactiveYN) {
            return "user-inactive";
        }
    };

    return (
        <div>
            <div className="secondary-header">
                {Globals.associatedFacilities.length === 1 ?
                    <div className="form-group col-lg-3">
                        <label className="form-label">Facility</label>
                        <div>{selectedFacility.facilityName}</div>
                    </div>
                    :
                    <div className="form-group col-lg-3">
                        <select className="form-control-custom" {...register("facilityID")}>
                            <option value={null} hidden>Select a facility</option>
                            {Globals.associatedFacilities.map((facility, idx) => <option key={facility.facilityModel.facilityID} value={facility.facilityModel.facilityID}>{facility.facilityModel.facilityName}</option>)}
                        </select>
                    </div>
                }

            </div>

            <div className="row">
                <div className="col-lg-12" >
                    <div className="panel-hdr pt-6">
                        <h2>
                            Patients
                            </h2>

                        {selectedFacility.facilityID &&
                            <button type="button" className="btn btn-submit margin-0-10" onClick={openBlank}>
                                New Insurance Company
                            </button>
                        }

                    </div>
                </div>
            </div>

            <div className="mt-5">
                {!state.loaded ?
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={
                            <span>
                                No Companies Found
                                                        </span>
                        }
                    >
                    </Empty>
                    :
                    <div className="m-5">
                        <ToolkitProvider
                            keyField='insuranceID'
                            data={state.insuranceList}
                            columns={columns}
                            columnToggle
                            search
                        >
                            {
                                props => (
                                    <div>
                                        <SearchBar {...props.searchProps} />
                                        <BootstrapTable
                                            pagination={paginationFactory(pagination)}
                                            {...props.baseProps}
                                            rowEvents={rowEvents}
                                            rowClasses={rowClasses}
                                            hover condensed />
                                    </div>
                                )
                            }
                        </ToolkitProvider>
                    </div>
                }
            </div>

            <Modal size='lg' show={modalInfo.show} backdrop='static' onHide={closeModal}>
                <Modal.Header>
                    <h1>Update Insurance Company</h1>
                </Modal.Header>
                <Modal.Body>
                    <div className="panel-content">
                        <div className="row">
                            <div className="form-group col-12">
                                <label className="form-label">Insurance Company</label>
                                <input className="form-control-custom" {...register("insuranceCompanyName")}/>
                            </div>

                            <div className="form-group col-12">
                                <label className="form-label">Set Inactive</label>
                                <div className="form-control-custom no-border">
                                    <div className="custom-control custom-checkbox custom-control-inline">
                                        <input type="checkbox" className="custom-control-input" {...register("inactiveYN")} />
                                        <label className="custom-control-label"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <div className="form-group col-12 padding-25-10">
                            <button className="btn btn-submit" onClick={handleUpdate}>Save</button>
                            <button className="btn btn-outline-default margin-left-15" id="closeModal" onClick={closeModal}>Cancel</button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default withRouter(Insurance);