import Axios from '../../config/axios';

export function getInsuranceCompaniesByFacility(facilityID) {
    return Axios.get('/api/GetInsuranceCompaniesByFacility', {
        params: {
            facilityID
        }
    }).then(response => response.data);
}

export function updateInsuranceItem(insuranceItem) {
    return Axios.post('/api/UpdateInsuranceItem', insuranceItem).then(response => response.data);
}

export function insertRadiologist(radiologist) {
    return Axios.post('/api/InsertRadiologist', radiologist).then(response => response.data);
}

export function updateRadiologist(radiologist) {
    return Axios.post('/api/UpdateRadiologist', radiologist).then(response => response.data);
}

export function insertProvider(provider) {
    return Axios.post('/api/InsertProvider', provider).then(response => response.data);
}

export function insertIssuer(issuer) {
    return Axios.post('/api/InsertIssuer', issuer).then(response => response.data);
}

export function insertPrimaryCarePhysician(pcp) {
    return Axios.post('/api/InsertPrimaryCarePhysician', pcp).then(response => response.data);
}

export function insertCategory(category) {
    return Axios.post('/api/InsertCategory', category).then(response => response.data);
}