import Axios from '../../config/axios';
import { BehaviorSubject } from 'rxjs';

export const printTypes = new BehaviorSubject([]);
export const recallPrintTypes = new BehaviorSubject([]);
export const pllPrintTypes = new BehaviorSubject([]);

export function getRecallInformation(facilityID) {
    Axios.get(`/api/GetRecallInformation`, { params: {facilityID}}).then(response => {

        printTypes.next(response.data.printTypes);
        recallPrintTypes.next(response.data.recallPrintTypes);

    }).catch(error => {
        console.log(error);
    });
}

export function getAdminRecallInformation(facilityID) {
    Axios.get(`/api/GetAdminRecallInformation`, { params: { facilityID } }).then(response => {

        printTypes.next(response.data.printTypes);
        recallPrintTypes.next(response.data.recallPrintTypes);

    }).catch(error => {
        console.log(error);
    });
}

export function getAdminPLLPrintTypeInformation(facilityID) {
    Axios.get(`/api/GetAdminPLLPrintTypeInformation`, { params: { facilityID } }).then(response => {

        printTypes.next(response.data.printTypes);
        pllPrintTypes.next(response.data.pllPrintTypes);

    }).catch(error => {
        console.log(error);
    });
}



export function getPrintRecallSearchResults(facilityID, recallPrintTypes, showPreviousPrinted, showUpcoming, showInactivePatients) {
    let postdata = {
        facilityID,
        recallPrintTypes,
        showPreviousPrinted,
        showUpcoming,
        showInactivePatients
    }

    return Axios.post('/api/GetPrintRecallSearchResults', postdata).then(response => response.data).catch(error => console.log(error));
}

export function updateRecallPrintTypes(postdata) {
    return Axios.post('/api/UpdateRecallPrintTypes', postdata).then(response => response.data).catch(error => console.log(error));
}

export function updatePLLPrintTypes(postdata) {
    return Axios.post('/api/UpdatePLLPrintTypes', postdata).then(response => response.data).catch(error => console.log(error));
}