import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { Select } from 'antd';
import NumberFormat from 'react-number-format';

import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { userAssociatedFacilities } from '../../../common/services/ApiService';

import Axios from '../../../config/axios';

import Moment from 'moment';

import { toast } from '@rickylandino/react-messages';

class OpenExamsReportModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: props.showModal,
            allFacilities: [],
            selectedFacilities: [],
            availableBirads: [],
            selectedBirads: [],
            selectedDates: {
                startDate: null,
                endDate: new Date()
            }
        }

        this.alive = false;
    }

    componentDidMount() {
        this.alive = true;

        combineLatest(
            userAssociatedFacilities
        ).pipe(takeWhile(() => this.alive)).subscribe(([f]) => {
            if (f?.length > 0) {
                this.setState({
                    allFacilities: f,
                    loaded: true,
                });
            }
        });
    }

    componentWillUnmount() {
        this.alive = false;
    }

    handleFacilityChange = (selectedFacilities) => {
        if (!this.state.selectedFacilities.length > 0) {
            let availableBirads = this.state.allFacilities.find(f => f.facilityModel.facilityID === selectedFacilities[0])?.availableBirads;
            this.setState({
                selectedFacilities,
                availableBirads
            });
        } else {

            //create list with all birads, including duplicates
            let concatBirads = [];
            selectedFacilities.forEach(f => {

                let facBirads = this.state.allFacilities.find(fac => fac.facilityModel.facilityID === f)?.availableBirads;

                concatBirads = concatBirads.concat(facBirads);
            });

            //filter duplicates out so we are left with only unique birads
            const seen = new Set();

            const availableBirads = concatBirads.filter(el => {
                const duplicate = seen.has(el.birads);
                seen.add(el.birads);
                return !duplicate;
            });

            //sort by alpabetical order
            availableBirads.sort(function (a, b) {
                var nameA = a.birads.toUpperCase(); // ignore upper and lowercase
                var nameB = b.birads.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            });


            this.setState({
                selectedFacilities,
                availableBirads
            })
        }
    }

    handleBiradChange = (selectedBirads) => {
        this.setState({
            selectedBirads
        });
    }

    handleClear = () => {
        this.setState({
            selectedFacilities: [],
            selectedBirads: [],
            availableBirads: [],
            selectedDates: {
                startDate: null,
                endDate: new Date()
            }
        })
    }

    handleNewDateChange = (name, date) => {
        this.setState({ selectedDates: { ...this.state.selectedDates, [name]: date } });
    }

    handleSubmit = () => {

        let endDate = this.state.selectedDates.endDate ? Moment(this.state.selectedDates.endDate).format("YYYY-MM-DD") : null;
        let startDate = this.state.selectedDates.startDate ? Moment(this.state.selectedDates.startDate).format("YYYY-MM-DD") : null;

        if (startDate
            || endDate
            || this.state.selectedBirads.length > 0
            || this.state.selectedFacilities.length > 0) {
            const q = Axios.defaults.baseURL + "api/RenderOpenExamReport";

            var form = document.createElement("form");
            form.target = "_blank";
            form.method = "POST";
            form.action = q;
            form.style.display = "none";

            var input = document.createElement("input");
            input.type = "hidden";
            input.name = "startDate";
            input.value = startDate;
            form.appendChild(input);

            var input = document.createElement("input");
            input.type = "hidden";
            input.name = "endDate";
            input.value = endDate;
            form.appendChild(input);

            var input = document.createElement("input");
            input.type = "hidden";
            input.name = "selectedFacilies";
            input.value = this.state.selectedFacilities;
            form.appendChild(input);

            var input = document.createElement("input");
            input.type = "hidden";
            input.name = "birads";
            input.value = this.state.selectedBirads;
            form.appendChild(input);

            document.body.appendChild(form);
            form.submit();
            document.body.removeChild(form);
        } else {
            toast.error("Please provide at least one search criteria");
        }

    }


    render() {

        const { Option } = Select;

        const theme = window.sessionStorage.getItem("theme");

        return (
            <Modal size='lg' show={this.state.showModal} onHide={this.toggleModal} backdrop='static' dialogClassName={theme === 'dark' ? 'dark-theme' : 'light-theme'}>
                <Modal.Header>
                    <h1>Open Exams</h1>
                </Modal.Header>
                <Modal.Body className="no-y-overflow">
                    <div className="panel-content">
                        {this.state.loaded &&
                            <div className="row">

                                <div className="form-group col col-6">
                                    <div className="form-label">Start Range</div>
                                    <DatePicker
                                        onChange={(date) => this.handleNewDateChange('startDate', date)}
                                        selectsStart
                                        startDate={this.state.selectedDates.startDate}
                                        endDate={this.state.selectedDates.endDate}
                                        className="form-control-custom"
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                        placeholderText='Start Date'
                                        selected={this.state.selectedDates.startDate && Moment(this.state.selectedDates.startDate).toDate()}
                                        customInput={
                                            <NumberFormat format="##/##/####" mask="_" />
                                        }
                                    />
                                </div>
                                <div className="col col-6">
                                    <div className="form-label">End Range</div>
                                    <DatePicker
                                        onChange={(date) => this.handleNewDateChange('endDate', date)}
                                        selectsEnd
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                        startDate={this.state.selectedDates.startDate}
                                        endDate={this.state.selectedDates.endDate}
                                        className="form-control-custom"
                                        placeholderText='End Date'
                                        selected={this.state.selectedDates.endDate && Moment(this.state.selectedDates.endDate).toDate()}
                                        customInput={
                                            <NumberFormat format="##/##/####" mask="_" />
                                        }
                                    />

                                </div>
                                <div className="form-group col col-lg-6 col-12">
                                    <div className="form-label">Facility</div>
                                    <Select
                                        mode="multiple"
                                        maxTagCount={2}
                                        allowClear
                                        placeholder="Please select"
                                        onChange={this.handleFacilityChange}
                                        className="form-control-custom w-100"
                                        bordered={false}
                                        value={this.state.selectedFacilities}
                                    >
                                        {this.state.allFacilities.map((fac, idx) => <Option key={idx} key={fac.facilityModel.facilityID} value={fac.facilityModel.facilityID}>{fac.facilityModel.facilityName}</Option>)}
                                    </Select>
                                </div>
                                <div className="form-group col col-lg-6 col-12">
                                    <div className="form-label">BIRADS</div>
                                    <Select
                                        mode="multiple"
                                        maxTagCount={4}
                                        allowClear
                                        placeholder="Please select"
                                        onChange={this.handleBiradChange}
                                        className="form-control-custom w-100"
                                        bordered={false}
                                        value={this.state.selectedBirads}
                                    >
                                        {this.state.availableBirads.map((b, idx) => <Option key={b.birads} value={b.birads}>{b.birads}</Option>)}
                                        <Option key="No Result Entered" value="No Result Entered">No Result Entered</Option>
                                    </Select>
                                </div>
                            </div>


                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <div className="form-group col-12 padding-25-10">
                            <button className="btn btn-submit" onClick={this.handleSubmit}>Run Report</button>
                            <button className="btn btn-outline-default margin-left-15" onClick={this.handleClear}>Clear</button>
                            <button className="btn btn-outline-default margin-left-15" id="closeModal" onClick={() => this.props.hideModal()}>Cancel</button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withRouter(OpenExamsReportModal);