import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import { useForm, Controller  } from "react-hook-form";

import { Select } from 'antd';

import { getAllFacilities, isUsernameUnique, isEmailUnique, getUserInformationByID, updateUser } from '../../common/services/ApiService';
import { createUser } from '../../common/services/AuthService';

import { toast } from '@rickylandino/react-messages';
import NumberFormat from 'react-number-format';
import { allUsers } from '../../common/services/ReportService';


function ManageUser(props) {
    console.log(props);
    const [showPane, setShowPane] = useState(true);
    const [allFacilities, setAllFacilities] = useState([]);

    const theme = window.sessionStorage.getItem("theme");

    const { register, handleSubmit, setValue, getValues, watch, setError, clearErrors,
        formState: { errors }, control    } = useForm();

    const watchAdmin = watch('isAdmin', false);

    const onSubmit = user => {
        if (props.userID) {
            user.isLDAP = false;

            updateUser(user).then(data => {
                toast.success(`${user.firstName} ${user.lastName} has been updated`);

                allUsers.next(data);

                hidePane();
            });

        } else {
            user.isLDAP = true;

            if (!user.facilities?.length > 0 && !user.isAdmin) {
                toast.error("Please fill in all required information");
            } else {
                createUser(user).then(data => {
                    toast.success(`${user.firstName} ${user.lastName} has been added`);

                    if (props.from === 'manageFacility') {

                        let u = {
                            fName: user.firstName,
                            lName: user.lastName,
                            userID: data
                        };

                        setTimeout(() => {
                            props.hidePane(u);
                        }, 1000);
                    }

                    hidePane();
                });
            }
        }
    }

    const { Option } = Select;

    useEffect(() => {
        register("facilities");
        //register("isLdap");

        if (props.userID) {
            getUserInformationByID(props.userID).then(data => {
                console.log(data);
                register("userID");

                setValue("facilities", data.facilities);

                setValue("userID", props.userID);
                setValue("firstName", data.user?.fName);
                setValue("lastName", data.user?.lName);
                setValue("username", data.user?.username);
                setValue("email", data.user?.email);
                setValue("cellPhone", data.user?.cellPhone);
                setValue("isAdmin", data.user?.adminYN ? true : false);
                setValue("isTech", data.user?.technologistYN ? true : false);
                setValue("isLdap", data.isLdapUser ? true : false);
            });
        } else {
            setValue("isLdap", true);
        }
    }, [])

    useEffect(() => {

        getAllFacilities().then(data => {
            setAllFacilities(data);
        });

    }, [register]);

    function hidePane() {
        setShowPane(false);

        if (props.from !== 'manageFacility') {
            setTimeout(() => {
                props.hidePane();
            }, 1000)
        }
        
    }

    const onError = (errors, e) => {
        toast.error("Please fill in all required information");
    }

    function checkValidUsername() {
        if (getValues().username) {
            isUsernameUnique(getValues().username).then(data => {
                if (!data) {
                    setError("username", {
                        type: "manual",
                        message: "This username is already taken.",
                    });
                } else {
                    clearErrors("username");
                }
            })
        } else {
            clearErrors("username");
        }
        
    }

    function checkValidEmail() {
        if (getValues().email) {
            isEmailUnique(getValues().email).then(data => {
                if (!data) {
                    setError("email", {
                        type: "manual",
                        message: "This email is already taken.",
                    });
                } else {
                    clearErrors("email");
                }
            })
        } else {
            clearErrors("email");
        }
    }

    return (
        <SlidingPane
            overlayClassName='pinkCard'
            className={theme === 'dark' ? "dark-theme smallPane" : 'light-theme smallPane'}
            isOpen={showPane || false}
            /*title={<div style={{ height: '50px' }}><span style={{marginTop: '15px', position: 'fixed' }}>Add a Candidate</span> <button className="btn btn-submit float-right">Submit</button></div>}*/
            title={props.userID ? 'Manage User' : 'Add User'}
            onRequestClose={hidePane}
            shouldCloseOnEsc={false}
        >

            <div className="slide-pane-body">
                <form>

                    <div className="row">
                        <div className="form-group col-12">
                            <label className="form-label">First Name <span className="color-pink">*</span></label>
                            <input {...register("firstName", { required: true })} className="form-control-custom" autoComplete="off" type="text" />
                        </div>

                        <div className="form-group col-12">
                            <label className="form-label">Last Name <span className="color-pink">*</span></label>
                            <input {...register("lastName", { required: true })} className="form-control-custom" autoComplete="off" />
                        </div>

                        <div className="form-group col-12">
                            <label className="form-label">Username <span className="color-pink">*</span></label>
                            <input {...register("username", { required: true })} className="form-control-custom" onBlur={checkValidUsername} />
                            {errors.username && <pre className="text-danger mb-0 ml-3">{errors.username.message}</pre>}
                        </div>
                        

                        <div className="form-group col-12">
                            <label className="form-label">Email <span className="color-pink">*</span></label>
                            <input {...register("email", {
                                pattern: {
                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: 'Please enter a valid email'
                                }, required: true
                            })} className="form-control-custom" onBlur={checkValidEmail} />
                            {errors.email && <pre className="text-danger mb-0 ml-3">{errors.email.message}</pre>}
                        </div>

                        {/*<div className="form-group col-12">*/}
                        {/*    <label className="form-label">Cell Phone</label>*/}
                        {/*    <NumberFormat className="form-control-custom" {...register("cellPhone")} defaultValue={getValues().cellPhone} format="(###) ###-####" mask="_" onValueChange={(val) => setValue("cellPhone", val.formattedValue)}/>*/}
                        {/*</div>*/}

                        <div className="form-group col-12">
                            <label className="form-label">Cell Phone</label>
                            <Controller
                                name="cellPhone"
                                control={control}
                                defaultValue={getValues().cellPhone}
                                render={({ field }) => <NumberFormat {...field} className="form-control-custom" format="(###) ###-####" mask="_" onValueChange={(val) => setValue("cellPhone", val.formattedValue)} />}
                            />
                            {/*<NumberFormat className="form-control-custom" {...register("cellPhone")} defaultValue={getValues().cellPhone} format="(###) ###-####" mask="_" onValueChange={(val) => setValue("cellPhone", val.formattedValue)}/>*/}
                        </div>

                        <div className="form-group col-4">
                            <label className="form-label">Admin</label>
                            <div className="form-control-custom no-border">
                                <div className="custom-control custom-checkbox custom-control-inline">
                                    <input type="checkbox" className="custom-control-input" {...register("isAdmin")} />
                                    <label className="custom-control-label"></label>
                                </div>
                            </div>
                        </div>

                        <div className="form-group col-4">
                            <label className="form-label">Technologist</label>
                            <div className="form-control-custom no-border">
                                <div className="custom-control custom-checkbox custom-control-inline">
                                    <input type="checkbox" className="custom-control-input" {...register("isTech")} />
                                    <label className="custom-control-label"></label>
                                </div>
                            </div>
                        </div>

                        <div className="form-group col-4">
                            <label className="form-label">LDAP User</label>
                            <div className="form-control-custom no-border">
                                <div className="custom-control custom-checkbox custom-control-inline">
                                    <input type="checkbox" className="custom-control-input" {...register("isLdap")} disabled />
                                    <label className="custom-control-label"></label>
                                </div>
                            </div>
                        </div>

                        {!watchAdmin ?
                            <div className="form-group col-12">
                            <label className="form-label">Facilities <span className="color-pink">*</span></label>

                                <Select
                                    name="facilities"
                                    mode="multiple"
                                    maxTagCount={1}
                                    onChange={(selectedFacilities) => setValue("facilities", selectedFacilities)}
                                    allowClear
                                    placeholder="Please select 1 or more"
                                    className="form-control-custom w-100"
                                    bordered={false}
                                    value={getValues().facilities}
                                >
                                    {allFacilities.map((fac, idx) => <Option key={idx} value={fac.facilityID}>{fac.facilityName}</Option>)}
                                </Select>

                            </div>
                            :
                            <div className="form-group col-12">
                                <p>Note: As an Admin, this user will have access to all facilities</p>
                            </div>
                        }

                        
                    </div>
                </form>
            </div>

            <div className="modal-footer">
                <div className="form-group col-12 padding-25-10">
                    <button className="btn btn-submit" onClick={handleSubmit(onSubmit, onError)} disabled={(errors.username || errors.email) ? true : false}>{props.userID ? 'Update User' : 'Add User'}</button>
                </div>
            </div>
        </SlidingPane>
    );
}

export default withRouter(ManageUser);