import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { Select } from 'antd';
import NumberFormat from 'react-number-format';

import Moment from 'moment';

import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { userAssociatedFacilities } from '../../../common/services/ApiService';

import Axios from '../../../config/axios';

import { toast } from '@rickylandino/react-messages';

class RadDetailsReportModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: props.showModal,
            allFacilities: [],
            selectedFacility: '',
            availableBirads: [],
            selectedBirad: '',
            availableRads: [],
            selectedRad: '',
            availableExamTypes: [],
            selectedExamTypes: [],
            selectedDates: {
                startDate: null,
                endDate: new Date()
            }
        }

        this.alive = false;
    }

    componentDidMount() {
        this.alive = true;

        combineLatest(
            userAssociatedFacilities
        ).pipe(takeWhile(() => this.alive)).subscribe(([f]) => {
            if (f?.length > 0) {
                this.setState({
                    allFacilities: f,
                    loaded: true,
                });
            }
        });
    }

    componentWillUnmount() {
        this.alive = false;
    }

    handleDateChange = (dates, selectedDateRange) => {
        this.setState({
            selectedDateRange,
            dates
        });
    }

    handleFacilityChange = (selectedFacility) => {
        this.setState({
            selectedFacility,
            availableRads: this.state.allFacilities.find(f => f.facilityModel.facilityID === selectedFacility)?.availableRads || [],
            availableBirads: this.state.allFacilities.find(f => f.facilityModel.facilityID === selectedFacility)?.availableBirads || [],
            selectedRad: '',
            selectedBirad: '',
            availableExamTypes: this.state.allFacilities.find(f => f.facilityModel.facilityID === selectedFacility)?.availableExamTypes || [],
        });
    }

    handleBiradChange = (selectedBirad) => {
        this.setState({
            selectedBirad
        });
    }

    handleRadChange = (selectedRad) => {
        this.setState({
            selectedRad
        });
    }

    handleExamTypesChange = (selectedExamTypes) => {
        this.setState({
            selectedExamTypes
        });
    }

    handleClear = () => {
        this.setState({
            selectedFacility: '',
            selectedBirad: '',
            availableBirads: [],
            availableRads: [],
            selectedRad: '',
            selectedExamTypes: [],
            selectedDates: {
                startDate: null,
                endDate: new Date()
            }
        })
    }

    handleNewDateChange = (name, date) => {
        this.setState({ selectedDates: { ...this.state.selectedDates, [name]: date } });
    }

    handleSubmit = () => {

        let endDate = this.state.selectedDates.endDate ? Moment(this.state.selectedDates.endDate).format("YYYY-MM-DD") : null;
        let startDate = this.state.selectedDates.startDate ? Moment(this.state.selectedDates.startDate).format("YYYY-MM-DD") : null;

        if (this.state.selectedFacility
             && this.state.selectedRad) {
            const q = Axios.defaults.baseURL + "api/RenderRadDetailsAndSummary";

            var form = document.createElement("form");
            form.target = "_blank";
            form.method = "POST";
            form.action = q;
            form.style.display = "none";

            var input = document.createElement("input");
            input.type = "hidden";
            input.name = "startDate";
            input.value = startDate;
            form.appendChild(input);

            var input = document.createElement("input");
            input.type = "hidden";
            input.name = "endDate";
            input.value = endDate;
            form.appendChild(input);

            var input = document.createElement("input");
            input.type = "hidden";
            input.name = "selectedFacility";
            input.value = this.state.selectedFacility;
            form.appendChild(input);

            var input = document.createElement("input");
            input.type = "hidden";
            input.name = "selectedRad";
            input.value = this.state.selectedRad;
            form.appendChild(input);

            var input = document.createElement("input");
            input.type = "hidden";
            input.name = "selectedBirad";
            input.value = this.state.selectedBirad;
            form.appendChild(input);

            var input = document.createElement("input");
            input.type = "hidden";
            input.name = "selectedExamTypes";
            input.value = this.state.selectedExamTypes;
            form.appendChild(input);

            document.body.appendChild(form);
            form.submit();
            document.body.removeChild(form);
        } else {
            toast.error("Please provide at least the facility and radiologist.");
        }
        
    }
    

    render() {

        const { Option } = Select;

        const { RangePicker } = DatePicker;

        const theme = window.sessionStorage.getItem("theme");

        return (
            <Modal size='lg' show={this.state.showModal} onHide={this.toggleModal} backdrop='static' dialogClassName={theme === 'dark' ? "dark-theme" : 'light-theme'}>
                <Modal.Header>
                    <h1>Radiologist Details and Summary</h1>
                </Modal.Header>
                <Modal.Body>
                    <div className="panel-content">
                        {this.state.loaded &&
                            <div className="row">

                            <div className="form-group col col-6">
                                <div className="form-label">Start Range</div>
                                <DatePicker
                                    onChange={(date) => this.handleNewDateChange('startDate', date)}
                                    selectsStart
                                    startDate={this.state.selectedDates.startDate}
                                    endDate={this.state.selectedDates.endDate}
                                    className="form-control-custom"
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText='Start Date'
                                    selected={this.state.selectedDates.startDate && Moment(this.state.selectedDates.startDate).toDate()}
                                    customInput={
                                        <NumberFormat format="##/##/####" mask="_" />
                                    }
                                />
                            </div>
                            <div className="col col-6">
                                <div className="form-label">End Range</div>
                                <DatePicker
                                    onChange={(date) => this.handleNewDateChange('endDate', date)}
                                    selectsEnd
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    startDate={this.state.selectedDates.startDate}
                                    endDate={this.state.selectedDates.endDate}
                                    className="form-control-custom"
                                    placeholderText='End Date'
                                    selected={this.state.selectedDates.endDate && Moment(this.state.selectedDates.endDate).toDate()}
                                    customInput={
                                        <NumberFormat format="##/##/####" mask="_" />
                                    }
                                />

                            </div>

                                <div className="form-group col col-lg-6 col-12">
                                <div className="form-label">Facility <span className="color-pink">*</span></div>
                                    <Select
                                        allowClear
                                        placeholder="Please select"
                                        onChange={this.handleFacilityChange}
                                        className="form-control-custom w-100"
                                    bordered={false}
                                    value={this.state.selectedFacility}
                                    >
                                        {this.state.allFacilities.map((fac, idx) => <Option key={idx} key={fac.facilityModel.facilityID} value={fac.facilityModel.facilityID}>{fac.facilityModel.facilityName}</Option>)}
                                    </Select>
                            </div>
                            <div className="form-group col col-lg-6 col-12">
                                <div className="form-label">Radiologist <span className="color-pink">*</span></div>
                                <Select
                                    allowClear
                                    placeholder="Please select"
                                    onChange={this.handleRadChange}
                                    className="form-control-custom w-100"
                                    bordered={false}
                                    value={this.state.selectedRad}
                                >
                                    {this.state.availableRads.map((r, idx) => <Option key={idx} key={r.radiologistID} value={r.radiologistID}>{r.fName} {r.lName}</Option>)}
                                </Select>
                            </div>

                            <div className="form-group col col-lg-6 col-12">
                                <div className="form-label">BIRADS</div>
                                <Select
                                    allowClear
                                    placeholder="Please select"
                                    onChange={this.handleBiradChange}
                                    className="form-control-custom w-100"
                                    bordered={false}
                                    value={this.state.selectedBirad}
                                >
                                    {this.state.availableBirads.map((b, idx) => <Option key={idx} key={b.birads} value={b.birads}>{b.birads}</Option>)}
                                    <Option key="No Result Entered" value="No Result Entered">No Result Entered</Option>
                                </Select>
                            </div>

                            <div className="form-group col col-lg-6 col-12">
                                <div className="form-label">Exam Types</div>
                                <Select
                                    mode="multiple"
                                    maxTagCount={1}
                                    allowClear
                                    placeholder="Please select"
                                    onChange={this.handleExamTypesChange}
                                    className="form-control-custom w-100"
                                    bordered={false}
                                    value={this.state.selectedExamTypes}
                                >
                                    {this.state.availableExamTypes.map((e, idx) => <Option key={idx} key={e.examTypeID} value={e.examTypeID}>{e.examType}</Option>)}
                                </Select>
                            </div>
                        </div>
                            


                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <div className="form-group col-12 padding-25-10">
                            <button className="btn btn-submit" onClick={this.handleSubmit}>Run Report</button>
                            <button className="btn btn-outline-default margin-left-15" onClick={this.handleClear}>Clear</button>
                            <button className="btn btn-outline-default margin-left-15" id="closeModal" onClick={() => this.props.hideModal()}>Cancel</button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withRouter(RadDetailsReportModal);