import React, { Component } from 'react';
import { Route, withRouter, Redirect, Switch } from 'react-router';
import { Layout } from './components/Layout';
import Login from './components/Auth/Login';
import Providers from './components/Maintenance/Providers/Providers';
import Globals from '../src/config/globals';
import PatientsHome from './components/Patient/PatientsHome';
import PatientDashboard from './components/Patient/PatientDashboard';

import Modal from 'react-modal';
import ExamSearch from './components/Exams/ExamSearch';
import RecallHome from './components/Recall/RecallHome';
import AdminHome from './components/Admin/AdminHome';
import WorklistHome from './components/Worklist/WorklistHome';

import Templates from './components/Report/Templates/ReportTemplates';
import Components from './components/Report/Components/ReportComponents';
import Definitions from './components/Report/Definitions/ReportDefinitions';
import Library from './components/Report/Library/ReportLibrary';
import DefinitionHome from './components/Report/Definition/DefinitionHome';
import { userAssociatedFacilities, getAllTypes } from './common/services/ApiService';
import DailyExamDashboard from './components/DailyExams/DailyExamDashboard';
import { activeRoute } from './common/services/SpaService';
import ResetPassword from './components/Auth/ResetPassword';
import DetectActivity from './components/Helpers/DetectActivity';
import Insurance from './components/Maintenance/Insurance';

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedFacility: {},
            theme: window.sessionStorage.getItem("theme") || "light"
        }

        if (window.sessionStorage.getItem("userInfo")) {
            Globals.userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
            getAllTypes();
        }

        if (window.sessionStorage.getItem("associatedFacilities")) {
            Globals.associatedFacilities = JSON.parse(window.sessionStorage.getItem("associatedFacilities"));
            userAssociatedFacilities.next(Globals.associatedFacilities);
        }

        if (window.sessionStorage.getItem("defaultFacility")) {
            Globals.defaultFacility = JSON.parse(window.sessionStorage.getItem("defaultFacility"));
        }
    }

    componentWillMount() {
        this.unlisten = this.props.history.listen((location, action) => {
            activeRoute.next(location.pathname);
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }

    componentDidMount() {
        activeRoute.next(this.props.history.location.pathname);
        Modal.setAppElement('#root');
    }

    changeFacility = () => {
        this.setState({
            selectedFacility: Globals.selectedFacility
        });
    }

    updatePatientsLoading = () => {
        this.setState({
            loadingPatients: Globals.loadingPatients
        });
    }

    updatePatients = () => {
        this.setState({
            patientsLoaded: true,
            loadingPatients: false
        });
        Globals.loadingPatients = false;
    }

    toggleDarkMode = (theme) => {

        this.setState({
            theme
        });

        Globals.theme = theme;
    }

    render() {
        return (
            <div className="nav-function-top" data-theme={this.state.theme}>
                {
                    (this.props.location.pathname === '/' || this.props.location.pathname === '/reset-password') ? (
                        <div>
                            <Route exact path='/' component={Login} />
                            <Route exact path='/reset-password' component={ResetPassword} />
                        </div>
                    ) : (
                            <DetectActivity>
                            <Layout changeFacility={this.changeFacility} updatePatientsLoading={this.updatePatientsLoading} updatePatients={this.updatePatients} toggleDarkMode={this.toggleDarkMode}>
                                
                                <Switch>
                                    <Route path='/patients' render={(props) => <PatientsHome selectedFacility={this.state.selectedFacility} loadingPatients={this.state.loadingPatients} patientsLoaded={this.state.patientsLoaded} />} />
                                    <Route exact path='/patient-dashboard' render={(props) => (this.props.location.state ? <PatientDashboard updatePatients={this.updatePatients} /> : <Redirect to='/patients' />)} />
                                    <Route exact path='/admin-panel' render={(props) => (JSON.parse(window.sessionStorage.getItem("userInfo")).isAdmin ? <AdminHome /> : <Redirect to='/patients' />)} />
                                    <Route exact path='/report-definitions' render={(props) => (JSON.parse(window.sessionStorage.getItem("userInfo")).isAdmin ? <DefinitionHome /> : <Redirect to='/patients' />)} />
                                </Switch>

                                <Route path='/recall' component={RecallHome} />
                                <Route path='/providers' component={Providers} />
                                <Route path='/insurance-maintenance' component={Insurance} />
                                <Route path='/exam-search' component={ExamSearch} />
                                <Route path='/worklist' render={(props) => <WorklistHome selectedFacility={this.state.selectedFacility} />} />
                                <Route path='/reporttemplates' component={Templates} />
                                <Route path='/reportcomponents' component={Components} />
                                <Route path='/reportdefinitions' component={Definitions} />
                                <Route path='/reportlibrary' component={Library} />
                                <Route path='/daily-exams' component={DailyExamDashboard} />
                                
                                
                                </Layout>
                            </DetectActivity>)
                }

            </div>
        );
    }
}

export default withRouter(App);