import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Moment from 'moment';
import Axios from '../../config/axios';
import Globals from '../../config/globals';

import "react-datepicker/dist/react-datepicker.css";

import BootstrapTable from 'react-bootstrap-table-next';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import Card from 'react-bootstrap/Card';
import '../../css/card.css';
import { getDefinitionIconsByFacility, reportDefinitionIcons, downloadPreviousReport, getPrintHistoryByIcon } from '../../common/services/ReportService';
import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

import { Modal } from 'react-bootstrap';

class LettersTab extends Component {

    constructor(props) {
        super(props);

        this.alive = false;

        this.state = {
            tableList: [
                {
                    letter: 'Non-compliant Letter',
                    printIcon: '',
                    printIconName: 'LettersTab.NonCompliantLetter',
                    printDate: '',
                    viewLetter: '',
                    printedBy: '',
                    notes: ''
                }, {
                    letter: 'Individual Recall Letter',
                    printIcon: '',
                    printIconName: 'LettersTab.IndividualRecallLetter',
                    printDate: '',
                    viewLetter: '',
                    printedBy: '',
                    notes: ''
                }, {
                    letter: 'Risk Assessment Survey',
                    printIcon: '',
                    printIconName: 'LettersTab.RiskAssessmentSurvey',
                    printDate: '',
                    viewLetter: '',
                    printedBy: '',
                    notes: ''
                }, {
                    letter: 'Prior Authorization Form',
                    printIcon: '',
                    printIconName: 'LettersTab.PriorAuthorizationForm',
                    printDate: '',
                    viewLetter: '',
                    printedBy: '',
                    notes: ''
                }, {
                    letter: 'Customized Letter',
                    printIcon: '',
                    printIconName: 'LettersTab.CustomizedLetter',
                    printDate: '',
                    viewLetter: '',
                    printedBy: '',
                    notes: ''
                }
            ],
            definitionIconList: [],
            loaded: false,
            showHistoryModal: false
        }
    }

    componentDidMount() {
        getDefinitionIconsByFacility(this.props.patient.facilityID);

        this.alive = true;

        combineLatest(
            reportDefinitionIcons
        ).pipe(takeWhile(() => this.alive)).subscribe(([rdi]) => {
            if (rdi?.length > 0) {
                let definitionIconList = rdi.filter(i => i.reportDefinitionIconModel.iconGroup === 'Letters');
                this.setState({
                    definitionIconList,
                    loaded: true
                });
            }
        });
    }

    componentWillUnmount() {
        this.alive = false;
    }

    toggleModal = () => {
        this.setState({
            showHistoryModal: !this.state.showHistoryModal
        });
    }

    render() {
        function printFormatter(cell, row) {
            return (
                <div>{row.reportDefinitionIconDefaultsModel?.printIconName !== 'LettersTab.IndividualRecallLetter' && <i id='print' title={row.printIconName} className='fas fa-print hover fa-125x color-pink'></i>}</div>
            );
        }

        function historyFormatter(cell, row) {
            return (
                <i id='history' title={row.printIconName} className='fas fa-history hover fa-125x color-pink'></i>
            );
        }

        function nameFormatter(cell, row) {
            let text = cell.split('.')[1];
            var result = text.replace(/([A-Z])/g, " $1");
            var finalResult = result.charAt(0).toUpperCase() + result.slice(1);

            return (
                <span>{finalResult}</span>
            );
        }

        const columns = [{
            dataField: 'reportDefinitionIconModel.printIconName',
            text: 'Letters/Forms',
            formatter: nameFormatter
        }, {
                dataField: 'dummyIcon',
                isDummyField: true,
                text: 'Print',
                formatter: printFormatter,
                align: 'center',
                headerAlign: 'center'
            }, {
                dataField: 'dum2',
                isDummyField: true,
                text: 'Print History',
                formatter: historyFormatter,
                align: 'center',
                headerAlign: 'center'
            } 
        //}, {
        //    dataField: 'printDate',
        //    text: 'Print Date',
        //    formatter: cell => Moment(new Date(cell.substring(0, (cell.length - 8)))).format('L')
        //}, {
        //    dataField: 'viewLetter',
        //    text: 'View Letter'
        //}, {
        //    dataField: 'printedBy',
        //    text: 'Printed By'
        //}, {
        ];

        const selectRow = {
            mode: 'radio',
            clickToSelect: true,
            hideSelectColumn: true
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                if (e.target.id === 'history') {
                    getPrintHistoryByIcon(row.reportDefinitionIconModel.reportDefinitionIconID, this.props.patient.patientID).then(data => {
                        this.setState({
                            showHistoryModal: true,
                            reportPrintHistory: data
                        });
                    }).catch(error => {
                        console.log(error);
                    });
                } else if (e.target.id === 'download') {
                    downloadPreviousReport(row.reportPrintHistoryModel.reportPrintHistoryID).then(data => {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url;
                        // the filename you want
                        a.download = this.props.patient.fName + this.props.patient.lName + "_" + new Date().toISOString() + '_printHistory.pdf';
                        //document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);

                    }).catch(error => {
                        console.log(error);
                    });
                } else {
                    const q = Axios.defaults.baseURL + "api/renderreport";

                    if (e.target.id === 'print') {
                        window.open(q + "?printiconname=" + row.reportDefinitionIconModel.printIconName + "&patientid=" + this.props.patient.patientID + "&uid=" + Globals.userInfo.userId, "_blank");
                    }
                }
            }
        };

        function fullNameFormatter(cell, row) {
            return (
                <p>{row.usersModel.fName} {row.usersModel.lName}</p>
            );
        }

        function downloadFormatter(cell, row) {
            return (
                <i id='download' title={row.reportPrintHistoryModel.reportPrintHistoryID || null} className='fas fa-download text-center fa-125x hover color-pink'></i>
            );
        }

        const modalColumns = [{
            dataField: 'reportPrintHistoryModel.datePrinted',
            text: 'Date Printed',
            formatter: cell => cell ? Moment(cell).utc().format('L') : null
        }, {
            dataField: 'usersModel.fName',
                text: 'Printed By',
            formatter: fullNameFormatter
            },{
                dataField: 'dummyIcon',
                isDummyField: true,
                text: 'Download Letter',
                formatter: downloadFormatter,
                align: 'center',
                headerAlign: 'center'
            }, 
        ];

        const theme = window.sessionStorage.getItem("theme");

        return (
            <div>
                <Card className="pinkCard">
                    <Card.Header><span>Letters</span>
                    </Card.Header>
                    <Card.Body>
                        {this.state.loaded &&
                            <div className="fullTable">
                                <BootstrapTable keyField='reportDefinitionIconModel.reportDefinitionIconID'
                                    data={this.state.definitionIconList}
                                    columns={columns}
                                    rowEvents={rowEvents}
                                    selectRow={selectRow}
                                    condensed />
                            </div>
                        }
                    </Card.Body>
                </Card>

                <Modal size='lg' show={this.state.showHistoryModal} onHide={this.toggleModal} backdrop='static' className={theme === 'dark' ? 'dark-theme' : "light-theme"}>
                    <Modal.Header>
                        <h1>Icon Print History</h1>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="panel-content">
                            <div className="row">
                                {this.state.showHistoryModal &&
                                    <div className="fullTable">
                                    <BootstrapTable keyField='reportPrintHistoryModel.reportPrintHistoryID'
                                        data={this.state.reportPrintHistory}
                                        columns={modalColumns}
                                            rowEvents={rowEvents}
                                            selectRow={selectRow}
                                            condensed />
                                    </div>
                                }
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="row">
                            <div className="form-group col-12 padding-25-10">
                                <button className="btn btn-outline-default margin-left-15" id="closeModal" onClick={this.toggleModal}>Close</button>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default withRouter(LettersTab);