import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import Axios from '../../config/axios';
import Globals from '../../config/globals';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';
import NumberFormat from 'react-number-format';

import { SmartMessageBox, smallBox } from "../../common/utils/functions";
import $ from 'jquery';

import Card from 'react-bootstrap/Card';
import '../../css/card.css';
import { Fragment } from 'react';
import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

import { toast, confirm } from '@rickylandino/react-messages';

import {
    allProviders,
    allTechnologists,
    allInsuranceCompanies,
    allDensity,
    allRecallTypes,
    allRecommendations,
    allBirads,
    allExamReasons,
    allBiopsyTypes
} from '../../common/services/ApiService';
import { FollowUpResultCard } from './FollowUpResultCard';
import { PrimaryResultCard } from './PrimaryResultCard';
import { updateExamAndResults } from '../../common/services/ExamService';
import PatientAttachments from '../Patient/PatientAttachments';

class PatientExamPane extends Component {

    constructor(props) {
        super(props);
        
        let formFields = props.examInfo && { ...props.examInfo.patientExamModel };
        formFields.radiologistID = props.examInfo.patientExamResultModel && props.examInfo.patientExamResultModel.radiologistID;
        formFields.breastDensityID = props.examInfo.densityModel && props.examInfo.densityModel.breastDensityID;
        formFields.recallTypeID = props.examInfo.recallTypesModel && props.examInfo.recallTypesModel.recallTypeID;
        formFields.recommendationID = props.examInfo.recommendationsModel && props.examInfo.recommendationsModel.recommendationsID;
                this.state = {
            showPane: true,
            recallableDisabled: props.examInfo?.examTypeModel?.recallable === 0 ? true : false,
            formFields,
            persistentFormFields: { ...formFields },
            examHistory: props.examHistory,
            persistentExamHistory: props.examHistory,
            examInfo: props.examInfo,
            patientExam: props.examInfo.patientExamModel,
            patientExamResult: props.examInfo.patientExamResultModel,
            radiologist: props.examInfo.radiologistModel,
            density: props.examInfo.densityModel,
            recallType: props.examInfo.recallTypesModel,
            examType: props.examInfo.examTypeModel,
            provider: props.examInfo.providerModel,
            providersList: [],
            insuranceCompanies: [],
            radiologistList: [],
            densityList: [],
            recallTypesList: [],
            recommendationsList: [],
            technologistList: [],
            showAddFollowUp: false,
            followUpAddFields: {
                resultDate: null
            },
            biradsList: [],
            allBirads: [],
            allRecommendations: [],
            allDensities: [],
            examTypes: [],
            examReasons: [],
            readOnly: formFields.isExamCompleteYN === 1 ? true : false,
            showExamReasonDropdown: false,
            ffDirty: false,
            missingFields: false,
            primaryResult: {
                resultDate: null,
            },
            missingRecallDate: false,
            categoryName: '',
            biradRecommendations: {},
            newBiradRecommendations: {},
            sharedFollowUpIDs: props.sharedFollowUpIDs,
            biradsFindings: {},
            findingsList: [],
            riskGrades: {},
            selectedRiskGrades: [],
            showRiskGrades: false,
            allBiopsyTypes: [],
            showBiopsyTypes: false,
            pbSelected: false,
            h2nrInd: false,
            numAxnDis: false,
            recommendationsAddList: [],
            selectedAddRiskGrades: [],
            findingsAddList: [],
            itemsLoaded: false,
            examIsSCRMAM: props.examInfo?.examTypeModel?.categoryID === '4d172b6f-936c-422c-9a81-de5befaa3ef7' ? true : false,
            showAttachments: false,
            pastRecallDate: false
        }

        this.isDirty = false;
        this.checkOnDirty = true;
        this.allowModal = true;
        this.popEventListnerAdded = false;

        this.alive = false;
    }

    componentDidMount() {
        this.alive = true;

        let props = this;
        if (!this.popEventListnerAdded) {
            $(window).on("popstate", function (event) {
                props.popEventListnerAdded = true;
                event.preventDefault();
                event.stopPropagation();

                if (props.isDirty && props.allowModal && Globals.isDirtyPage) {

                    confirm(
                        {
                            title: "You are about to lose all changes.",
                            content:
                                "Are you sure you would like to proceed?",
                            buttons: ["Yes", "No"],
                            theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
                        },
                        (ButtonPressed) => {
                            if (ButtonPressed === "Yes") {
                                //window.history.back();
                                props.hidePane();
                                props.allowModal = false;
                                props.isDirty = false;
                                Globals.isDirtyPage = false;
                                return 0;
                            }
                            if (ButtonPressed === "No") {
                                window.history.pushState(null, null, null);
                                return 0;
                            }
                        }
                    );
                }
            });
        }



        combineLatest(
            allProviders,
            allTechnologists,
            allInsuranceCompanies,
            allDensity,
            allRecallTypes,
            allRecommendations,
            allBirads,
            allExamReasons,
            allBiopsyTypes
        ).pipe(takeWhile(() => this.alive)).subscribe(([x1, x2, x3, x4, x5, x6, x7, x8, x9]) => {

            let insuranceCompanies = x3.filter(i => i.facilityID === this.state.examInfo.patientExamModel?.facilityID);

            this.setState({
                insuranceCompanies,
                allDensities: x4,
                recallTypesList: x5,
                allRecommendations: x6,
                allBirads: x7,
                examReasons: x8,
                allBiopsyTypes: x9,
                listsLoaded: true
            })
        });

        this.handleComponentMount();
    }

    handleComponentMount = () => {
        Axios.get(`/api/GetCategoryByFacility`, {
            params: {
                facilityID: this.state.examInfo.patientExamModel?.facilityID
            }
        }).then(response => {
            
            let categoryName = '';
            if (response.data.hasSingleCategory) {
                if (response.data.categoryID === '4d172b6f-936c-422c-9a81-de5befaa3ef7') {
                    categoryName = 'SCRMAM';
                }
            }

            let showExamReasonDropdown = response.data.examTypeList.find(et => et.examTypeID === this.state.formFields.examTypeID)?.reasonforExamPromptYN === 1 ? true : false;


            this.setState({
                biradsList: response.data.biradList,
                biradRecommendations: response.data.biradRecommendations,
                newBiradRecommendations: response.data.newBiradRecommendations,
                recommendationsList: response.data.recommendationList,
                densityList: response.data.densityList,
                examTypes: response.data.examTypeList,
                radiologistList: response.data.radiologistList,
                categoryName,
                showExamReasonDropdown,
                biradsFindings: response.data.biradsFindings,
                riskGrades: response.data.riskGrades,
                itemsLoaded: true,
                technologistList: response.data.technologists,
                providersList: response.data.providers
            });

        }).catch(error => {
            console.log(error);
        });
    }

    componentWillUnmount() {
        this.alive = false;
    }

    hidePane = () => {

        if (this.isDirty) {
            confirm(
                {
                    title: "You are about to lose all changes.",
                    content:
                        "Are you sure you would like to proceed?",
                    buttons: ["Yes", "No"],
                    theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
                },
                (ButtonPressed) => {
                    if (ButtonPressed === "Yes") {
                        //window.history.back();
                        this.setState({
                            showPane: false,
                            examHistory: this.state.persistentExamHistory,
                            patientExam: {},
                            patientExamResult: {},
                            showAddFollowUp: false,
                            followUpAddFields: {
                                resultDate: null
                            },
                            primaryResult: {
                                resultDate: null,
                            },
                            showExamReasonDropdown: false,
                            formFields: this.state.persistentFormFields,
                            biradsFindings: {},
                            findingsList: [],
                            riskGrades: {},
                            selectedRiskGrades: [],
                            showRiskGrades: false,
                            allBiopsyTypes: [],
                            showBiopsyTypes: false,
                            pbSelected: false,
                            h2nrInd: false,
                            numAxnDis: false,
                            recommendationsAddList: [],
                            selectedAddRiskGrades: [],
                            findingsAddList: [],
                            itemsLoaded: false,
                        }, () => { setTimeout(() => this.props.hidePane(), 1000) });
                        this.allowModal = false;
                        this.isDirty = false;
                        Globals.isDirtyPage = false;
                        return 0;
                    }
                    if (ButtonPressed === "No") {
                        window.history.pushState(null, null, null);
                        return 0;
                    }
                }
            );
        }
        else {
            this.setState({
                showPane: false,
                examHistory: this.state.persistentExamHistory,
                patientExam: {},
                patientExamResult: {},
                showAddFollowUp: false,
                followUpAddFields: {
                    resultDate: null
                },
                primaryResult: {
                    resultDate: null,
                },
                showExamReasonDropdown: false,
                formFields: this.state.persistentFormFields,
                biradsFindings: {},
                findingsList: [],
                riskGrades: {},
                selectedRiskGrades: [],
                showRiskGrades: false,
                allBiopsyTypes: [],
                showBiopsyTypes: false,
                pbSelected: false,
                h2nrInd: false,
                numAxnDis: false,
                recommendationsAddList: [],
                selectedAddRiskGrades: [],
                findingsAddList: [],
                itemsLoaded: false,
            }, () => { setTimeout(() => this.props.hidePane(), 1000) });
            this.allowModal = false;
            this.isDirty = false;
            Globals.isDirtyPage = false;
        }
    }

    handlePrimaryResultChange = (event) => {
        const target = event.target;
        var value = null;
        const name = target.name;

        let result = this.state.primaryResult;
        let formFields = this.state.formFields;

        if (target.type === 'checkbox') {
            value = target.checked === true ? '1' : '0';
        }
        else {


            this.isDirty = true;  // dirty
            Globals.isDirtyPage = true;

            switch (target.name) {
                case 'birad':
                    let bir = this.state.biradsList.find(birad => birad?.birads === target.value);

                    const findingsList = bir ? this.state.biradsFindings[bir.birads] : [];

                    result.birad = bir.birads;
                    result.findingID = null;

                    //Get recs based on default recommend ID
                    const recommendationsList = bir ? this.state.biradRecommendations[bir.birads] : [];
                    const newRecommendationsList = bir ? this.state.newBiradRecommendations[bir.birads] : [];
                    
                    var recommendationID = null;

                    newRecommendationsList.forEach(rec => {
                        if (rec.biradRecommendation?.defaultYN) {
                            recommendationID = rec.biradRecommendation.recommendationID;
                        }
                    });

                    let r1 = recommendationsList.find(rec => rec.recommendationID === recommendationID);

                    if (recommendationID)
                        result.recommendationID = recommendationID;

                    //Get Reecall default stuff
                    var recallTypeID = (r1 && !this.state.recallableDisabled) ? r1.defaultRecallTypeID : null;
                    var daysUntilRecall = (r1 && !this.state.recallableDisabled) ? r1.daysUntilRecall : null;

                    if (recallTypeID)
                        result.recallTypeID = recallTypeID;
                    else
                        result.recallTypeID = '00000000-0000-0000-0000-000000000000';

                    if (daysUntilRecall) {
                        var recallDate = Moment(new Date(this.state.examInfo.patientExamModel.examDateTime)).add(daysUntilRecall, 'day').toDate();
                        result.recallDate = recallDate;

                    } else {
                        result.recallDate = null;
                    }

                    //Check if exam should auto complete
                    var isDefaultComplete = bir ? bir.examAutoCompleteYN : null;

                    if (isDefaultComplete) {
                        formFields.isExamCompleteYN = 1;
                    } else {
                        formFields.isExamCompleteYN = 0;
                    }

                    var showBiopsyTypes = false;
                    var pbSelected = false;

                    if (bir?.birads === 'B4' || bir?.birads === 'B5') {
                        result.showBiopsyRefused = true;
                    } else if (bir?.birads === 'NB') {
                        showBiopsyTypes = true;
                    } else if (bir?.birads === 'PB') {
                        showBiopsyTypes = true;
                        pbSelected = true;
                    } else {
                        result.showBiopsyRefused = false;
                    }

                    this.setState({
                        recommendationsList,
                        findingsList,
                        selectedRiskGrades: {},
                        showRiskGrades: false,
                        showBiopsyTypes,
                        pbSelected
                    });

                    break;
                case 'recommendationID':
                    let recommend = this.state.recommendationsList.find(rec => rec.recommendationID === target.value);

                    var recallTypeID = (recommend && !this.state.recallableDisabled) ? recommend.defaultRecallTypeID : null;
                    var daysUntilRecall = (recommend && !this.state.recallableDisabled) ? recommend.daysUntilRecall : null;

                    if (recallTypeID)
                        result.recallTypeID = recallTypeID;

                    if (daysUntilRecall) {
                        var recallDate = Moment(this.state.primaryResult.resultDate).add(daysUntilRecall, 'day').toDate();
                        result.recallDate = recallDate;

                    } else {
                        result.recallDate = null;
                    }

                    break;
                case 'densityID':
                    var density = this.state.densityList.find(d => d.breastDensityID === target.value);
                    result.isBreastDense = (density && density.isDenseYN) ? density.isDenseYN.toString() : '0';
                    break;
                case 'losttoFollowUp':
                    if (value === "1") {
                        formFields.isExamCompleteYN = 1;
                    }
                    break;
                case 'findingID':
                    const finding = this.state.findingsList.find(f => f.findingID === target.value);
                    

                    if (finding?.riskGradeYN) {
                        const selectedRiskGrades = this.state.riskGrades[finding.findingID];

                        let pResult = { ...this.state.primaryResult };
                        pResult.riskGradeID = selectedRiskGrades[0].riskGradeID;

                        if (selectedRiskGrades?.length === 1) {
                            result.riskGradeID = selectedRiskGrades[0].riskGradeID;
                        }


                        this.setState({
                            selectedRiskGrades,
                            showRiskGrades: true
                        });
                    }
                    break;
                case 'her2NeuReceptor':
                    this.setState({
                        h2nrInd: target.value === 'Indeterminate' ? true : false
                    });
                    
                    break;
                case 'axillaryNodeStatus':
                    this.setState({
                        numAxnDis: target.value === 'Positive' ? true : false
                    });

                    break;
                default:
                    break;

            }

            value = target.value;
        }
        result[name] = value;
        result["isDirty"] = true;

        let missingRecallDate = false;

        let pastRecallDate = false;

        if (!result.recallDate && !this.state.recallableDisabled && (result.birad === 'B1' || result.birad === 'B2')) {
            missingRecallDate = true
        }

        if (result.recallDate && Moment(result.recallDate).isBefore(Moment(), "day")) {
            pastRecallDate = true;
        }

        const primaryResult = result;


        this.setState({
            primaryResult,
            formFields,
            missingRecallDate,
            pastRecallDate
        });
    }

    handlePrimaryResultDateChange = (date, dateName) => {
        let primaryResult = this.state.primaryResult;
        primaryResult[dateName] = date;

        if (!this.state.recallableDisabled && ((primaryResult?.birads === 'B1' || primaryResult?.birads === 'B2') && dateName === 'recallDate' && !date)) {
            this.setState({
                missingRecallDate: true
            });
        }

        if (dateName === 'recallDate') {
            this.setState({
                pastRecallDate: Moment(date).isBefore(Moment(), "day")
            });
        }

        this.isDirty = true;  // dirty
        Globals.isDirtyPage = true;

        this.setState({
            primaryResult
        });
    }

    addPrimaryResult = (toPrintAfter) => {
        
        let newFF = this.state.primaryResult;

        if (!newFF.resultDate ||
            !newFF.radiologistID ||
            !newFF.birad ||
            !newFF.recommendationID ||
            (this.state.examIsSCRMAM && (!newFF.densityID || newFF.densityID === '' || newFF.densityID === '00000000-0000-0000-0000-000000000000')) ||
            !newFF.recallTypeID ||
            (!newFF.findingID && this.state.findingsList?.length > 0)) {

            toast.error("Please fill in all the required information");
        } else {
            if (this.state.missingRecallDate) {
                confirm(
                    {
                        title: "You are attempting to save a normal result without supplying a recall date.",
                        content:
                            "Are you sure you would like to proceed?",
                        buttons: ["Yes", "No"],
                        theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
                    },
                    (ButtonPressed) => {
                        if (ButtonPressed === "Yes") {
                            this.handlePrimaryResultAdd(newFF, toPrintAfter);
                            return 0;
                        }
                        if (ButtonPressed === "No") {
                            window.history.pushState(null, null, null);
                            return 0;
                        }
                    }
                );
            } else if (this.state.pastRecallDate) {
                confirm(
                    {
                        title: "You are attempting to save a result with a recall date in the past.",
                        content:
                            "Are you sure you would like to proceed?",
                        buttons: ["Yes", "No"],
                        theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
                    },
                    (ButtonPressed) => {
                        if (ButtonPressed === "Yes") {
                            this.handlePrimaryResultAdd(newFF, toPrintAfter);
                            return 0;
                        }
                        if (ButtonPressed === "No") {
                            window.history.pushState(null, null, null);
                            return 0;
                        }
                    }
                );
            } else {
                this.handlePrimaryResultAdd(newFF, toPrintAfter);
            }

           
        }
    }

    //Insert Primary Result
    handlePrimaryResultAdd = (newFF, toPrintAfter) => {
        
        newFF.patientID = this.state.formFields.patientID;
        newFF.patientExamID = this.state.formFields.patientExamID;
        newFF.resultNumber = 1;
        newFF.internalExam = 1;
        
        newFF.modifiedByID = Globals.userInfo.userId.toString();
        newFF.isBreastDense = newFF.isBreastDense && newFF.isBreastDense.toString();

        newFF.sizeofFinding = newFF.sizeofFinding ? parseInt(newFF.sizeofFinding) : null;
        newFF.numberofAxillaryNodes = newFF.numberofAxillaryNodes ? parseInt(newFF.numberofAxillaryNodes) : null;

        newFF.findingID = !newFF.findingID || newFF.findingID === '' ? null : newFF.findingID;

        const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

        let postdata = {
            result: newFF,
            toClose: false,
            userID: userInfo.userId
        }

        

        Axios.post(`/api/InsertPatientResult`, postdata
        ).then(response => {
            if (toPrintAfter) {
                this.printPlainLanguageLetter(newFF.birad, response.data[0]?.patientExamResultModel?.patientResultID);
            }

            this.setState({
                persistentPrimaryResult: this.state.primaryResult,
                primaryResult: {
                    resultDate: null,
                }
            });

            if (this.state.formFields.isExamCompleteYN === 1) {
                let patientExam = this.state.patientExam;
                patientExam.isExamCompleteYN = this.state.formFields.isExamCompleteYN;
                patientExam.uid = userInfo.userId;

                Axios.post(`/api/UpdatePatientExam`, patientExam
                ).then(res => {

                    this.isDirty = false;
                    Globals.isDirtyPage = false;

                    toast.success("Primary Result has been added");

                    this.props.updateExamInfo(res.data);
                    this.hidePane();
                    
                }).catch(error => {
                    toast.error("Something went wrong");
                });
            } else {
                this.isDirty = false;
                Globals.isDirtyPage = false;

                toast.success("Primary Result has been added");

                this.props.updateExamInfo(response.data);
                this.hidePane();
            }

        }).catch(error => {
            toast.error("Something went wrong");
        });
    }

    handleInputChange = (event) => {
        const target = event.target;
        var value = null;
        const name = target.name;

        if (target.type === 'checkbox') {
            value = target.checked ? 1 : 0;
        }
        else {
            value = target.value;
        }

        if (name === 'isExamCompleteYN') {
            this.setState({
                readOnly: value === 1 ? true : false,
                manuallyCompleted: true
            });
        } else if (name === 'examTypeID') {

            let showExamReasonDropdown = this.state.examTypes.find(et => et.examTypeID === value)?.reasonforExamPromptYN === 1 ? true : false;
            let defaultComplete = this.state.examTypes.find(et => et.examTypeID === value)?.defaultComplete === 1 ? true : false;

            let formFields = this.state.formFields;

            if (defaultComplete) {
                formFields.isExamCompleteYN = 1
            } else {
                formFields.isExamCompleteYN = 0
            }

            formFields.reasonforExamID = showExamReasonDropdown ? this.state.formFields.showExamReasonDropdown : null;

            this.setState({
                showExamReasonDropdown,
                defaultComplete,
                formFields
            });
        }

        
        this.setState({ formFields: { ...this.state.formFields, [name]: value }, ffDirty: true }, () => {
            if (JSON.stringify(this.state.formFields) !== JSON.stringify(this.formFields)) {
                this.isDirty = true;  // dirty
                Globals.isDirtyPage = true;
            }

        });
    }

    updateFollowUpInfo = (examHistory, ff, formFieldsTouched, ffDirty, missingFields, missingRecallDate, pastRecallDate) => {
        let formFields = { ...this.state.formFields };
        if (formFieldsTouched) {
            formFields = ff;
        }

        //TODO
        this.setState({
            examHistory,
            formFields,
            ffDirty: true,
            missingFields,
            missingRecallDate,
            pastRecallDate
        });

        this.isDirty = true;
    }

    updateHistoryDateInfo = (examHistory, ffDirty, missingRecallDate, pastRecallDate) => {
        //TODO
        this.setState({
            examHistory,
            ffDirty: true,
            missingRecallDate,
            pastRecallDate
        });
    }

    addEmptyHistory = () => {

        this.setState({
            showAddFollowUp: true,
            missingFields: true
        }, () => this.scrollToBottom());
        
    }

    scrollToBottom = () => {
        const scrollHeight = this.messageList.scrollHeight;
        const height = this.messageList.clientHeight;
        const maxScrollTop = scrollHeight - height;
        this.messageList.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }

    handleFollwUpChange = (event, date) => {
        const target = event.target;
        var value = null;

        let formFields = this.state.formFields;
        let ffDirty = this.state.ffDirty;
        var missingFields = false;

        if (!date) {
            if (target.type === 'checkbox') {
                value = target.checked ? 1 : 0;
            }
            else {
                let followUpAddFields = this.state.followUpAddFields;
                switch (target.name) {
                    
                    case 'birad':
                        let bir = this.state.biradsList.find(birad => birad?.birads === target.value);
                        followUpAddFields.birad = bir?.birads;

                        const findingsAddList = bir ? this.state.biradsFindings[bir.birads] : [];

                        followUpAddFields.findingID = null;

                        //Get recs based on default recommend ID
                        const recommendationsAddList = bir ? this.state.biradRecommendations[bir.birads] : [];
                        const newRecommendationsAddList = bir ? this.state.newBiradRecommendations[bir.birads] : [];

                        var recommendationID = null;

                        newRecommendationsAddList.forEach(rec => {
                            if (rec.biradRecommendation?.defaultYN) {
                                recommendationID = rec.biradRecommendation.recommendationID;
                            }
                        });

                        //var recommendationID = bir ? bir.defaultRecommendID : null;
                        let r1 = this.state.recommendationsList.find(rec => rec.recommendationID === recommendationID);

                        if (recommendationID)
                            followUpAddFields.recommendationID = recommendationID;

                        //Get Reecall default stuff
                        var recallTypeID = (r1 && !this.state.recallableDisabled) ? r1.defaultRecallTypeID : null;
                        var daysUntilRecall = (r1 && !this.state.recallableDisabled) ? r1.daysUntilRecall : null;

                        if (recallTypeID)
                            followUpAddFields.recallTypeID = recallTypeID;
                        else
                            followUpAddFields.recallTypeID = '00000000-0000-0000-0000-000000000000';

                        if (daysUntilRecall) {
                            var recallDate = Moment(new Date(this.state.examInfo.patientExamModel.examDateTime)).add(daysUntilRecall, 'day').toDate();
                            followUpAddFields.recallDate = recallDate;

                        } else {
                            followUpAddFields.recallDate = null;
                        }

                        //Check if exam should auto complete
                        var isDefaultComplete = bir ? bir.examAutoCompleteYN : null;

                        if (isDefaultComplete) {
                            formFields.isExamCompleteYN = 1;
                        } else {
                            formFields.isExamCompleteYN = 0;
                        }

                        var showBiopsyTypes = false;
                        var pbSelected = false;

                        if (bir?.birads === 'B4' || bir?.birads === 'B5') {
                            followUpAddFields.showBiopsyRefused = true;
                        } else if (bir?.birads === 'NB') {
                            showBiopsyTypes = true;
                        } else if (bir?.birads === 'PB') {
                            showBiopsyTypes = true;
                            pbSelected = true;
                        } else {
                            followUpAddFields.showBiopsyRefused = false;
                        }

                        ffDirty = true;
                        
                        this.setState({
                            recommendationsAddList,
                            formFields,
                            findingsAddList,
                            ffDirty,
                            selectedAddRiskGrades: [],
                            showAddRiskGrades: false,
                            showBiopsyTypes,
                            pbSelected
                        });

                        break;
                    case 'recommendationID':

                        followUpAddFields.recommendationID = target.value;

                        let recommend = this.state.recommendationsList.find(rec => rec.recommendationID === target.value);

                        var recallTypeID = (recommend && !this.state.recallableDisabled) ? recommend.defaultRecallTypeID : null;
                        var daysUntilRecall = (recommend && !this.state.recallableDisabled) ? recommend.daysUntilRecall : null;

                        if (recallTypeID)
                            followUpAddFields.recallTypeID = recallTypeID;

                        if (daysUntilRecall) {
                            var recallDate = Moment(this.state.examInfo.patientExamModel.examDateTime).add(daysUntilRecall, 'day').toDate();
                            followUpAddFields.recallDate = recallDate;

                        }

                        this.setState({
                            followUpAddFields
                        });

                        break;
                    case 'densityID':
                        
                        var density = this.state.densityList.find(d => d.breastDensityID === target.value);

                        followUpAddFields.isBreastDense = density ? density.isDenseYN : 0;
                        break;
                    case 'findingID':
                        const finding = this.state.findingsAddList.find(f => f.findingID === target.value);

                        if (finding?.riskGradeYN) {
                            const selectedAddRiskGrades = this.state.riskGrades[finding.findingID];
                            let pResult = { ...this.state.primaryResult };
                            pResult.riskGradeID = selectedAddRiskGrades[0].riskGradeID;

                            if (selectedAddRiskGrades?.length === 1) {
                                followUpAddFields.riskGradeID = selectedAddRiskGrades[0].riskGradeID;
                            }

                            this.setState({
                                selectedAddRiskGrades,
                                showAddRiskGrades: true
                            });
                        }
                        break;
                    case 'her2NeuReceptor':
                        this.setState({
                            h2nrInd: target.value === 'Indeterminate' ? true : false
                        });
                        break;
                    case 'axillaryNodeStatus':
                        this.setState({
                            numAxnDis: target.value === 'Positive' ? true : false
                        });
                        break;
                    default:
                        break;

                }

                value = target.value;

                this.checkMissingFields(followUpAddFields, target.name, value);

                
            }
        }

        const name = target.name;

        this.setState({ followUpAddFields: { ...this.state.followUpAddFields, [name]: value } }, () => {
            if (JSON.stringify(this.state.followUpAddFields) !== JSON.stringify(this.followUpAddFields)) {
                this.isDirty = true;  // dirty
                Globals.isDirtyPage = true;
            }

        });
    }

    handleDateChange = (name, date) => {
        this.setState({ followUpAddFields: { ...this.state.followUpAddFields, [name]: date } }, () => {
            this.checkMissingFields(this.state.followUpAddFields, name, date);
            if (JSON.stringify(this.state.followUpAddFields) !== JSON.stringify(this.followUpAddFields)) {
                this.isDirty = true;  // dirty
            }

        });
    }

    handleExamDateChange = (name, date) => {
        this.setState({ formFields: { ...this.state.formFields, [name]: date }, ffDirty: true });
    }

    checkMissingFields = (result, name, value) => {
        let missingFields = false;
        let missingRecallDate = false;

        let pastRecallDate = false;

        if (name !== 'closedforDxExam' &&
            name !== 'losttoFollowUp' &&
            name !== 'notes' &&
            name !== 'recallDate') {
            if (value === null || value === '' || !value) {
                missingFields = true;
            }
        }

        console.log(((!result.findingID || result.findingID === '' || result.findingID === '00000000-0000-0000-0000-000000000000') && this.state.findingsAddList?.length > 0));
        console.log(!result.recallTypeID || result.recallTypeID === '' || result.recallTypeID === '00000000-0000-0000-0000-000000000000');

        if (!result.resultDate ||
            !result.birad ||
            //(this.state.categoryName === 'SCRMAM' && (!result.densityID || result.densityID === '' || result.densityID === '00000000-0000-0000-0000-000000000000')) || //this one broken
            !result.recommendationID || result.recommendationID === '' || result.recommendationID === '00000000-0000-0000-0000-000000000000' ||
            !result.recallTypeID || result.recallTypeID === '' || result.recallTypeID === '00000000-0000-0000-0000-000000000000' ||
            ((!result.findingID || result.findingID === '' || result.findingID === '00000000-0000-0000-0000-000000000000') && this.state.findingsAddList?.length > 0) ||
            (this.state.showAddRiskGrades && (!result.riskGradeID || result.riskGradeID === '' || result.riskGradeID === '00000000-0000-0000-0000-000000000000')) ||
            (this.state.showBiopsyTypes && (!result.biopsyTypeID || result.biopsyTypeID === '' || result.biopsyTypeID === '00000000-0000-0000-0000-000000000000'))) {
            missingFields = true;
        }

        if (!result.recallDate && (result.birad === 'B1' || result.birad === 'B2')) {
            missingRecallDate = true
        }

        if (result.recallDate && Moment(result.recallDate).isBefore(Moment(), "day")) {
            pastRecallDate = true;
        }

        this.setState({
            missingFields,
            missingRecallDate,
            pastRecallDate
        });
    }

    hideAddFollowUp = () => {
        this.setState({
            showAddFollowUp: false,
            followUpAddFields: {
                resultDate: null,

            },
            numAxnDis: false,
            h2nrInd: false,
            showRiskGrades: false,
            showBiopsyTypes: false,
            pbSelected: false
        });
    }

    handleSubmit = () => {
        if (this.state.missingFields) {
            toast.error("Please fill in all required information.");
        } else {
            if (this.state.missingRecallDate) {
                confirm(
                    {
                        title: "You are attempting to save a normal result without supplying a recall date.",
                        content:
                            "Are you sure you would like to proceed?",
                        buttons: ["Yes", "No"],
                        theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
                    },
                    (ButtonPressed) => {
                        if (ButtonPressed === "Yes") {
                            this.insertPatientResultAction();
                            return 0;
                        }
                        if (ButtonPressed === "No") {
                            window.history.pushState(null, null, null);
                            return 0;
                        }
                    }
                );
            } else if (this.state.pastRecallDate) {
                confirm(
                    {
                        title: "You are attempting to save a result with a recall date in the past.",
                        content:
                            "Are you sure you would like to proceed?",
                        buttons: ["Yes", "No"],
                        theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
                    },
                    (ButtonPressed) => {
                        if (ButtonPressed === "Yes") {
                            this.setState({
                                pastRecallDate: false
                            }, () => this.insertPatientResultAction());
                            
                            return 0;
                        }
                        if (ButtonPressed === "No") {
                            window.history.pushState(null, null, null);
                            return 0;
                        }
                    }
                );
            } else {
                this.insertPatientResultAction();
            }
        }
    }

    insertPatientResultAction = () => {
        let newFF = this.state.followUpAddFields;
        newFF.patientID = this.state.formFields.patientID;
        newFF.patientExamID = this.state.formFields.patientExamID;
        newFF.resultNumber = this.state.examHistory.length + 1;
        newFF.internalExam = 0;
        newFF.modifiedByID = Globals.userInfo.userId.toString();
        newFF.isBreastDense = newFF.isBreastDense ? newFF.isBreastDense.toString() : null;
        newFF.losttoFollowUp = newFF.losttoFollowUp ? newFF.losttoFollowUp.toString() : null;

        let outsideRad = this.state.radiologistList.find(r => r.fName === 'Outside' && r.lName === 'Radiologist');
        newFF.radiologistID = outsideRad ? outsideRad.radiologistID : null;

        const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

        let postdata = {
            result: newFF,
            examIDs: this.state.sharedFollowUpIDs,
            toClose: this.state.formFields.isExamCompleteYN === 1 ? true : false,
            userID: userInfo.userId
        }

        Axios.post(`/api/InsertPatientResult`, postdata
        ).then(response => {
            this.handleUpdate();
            let biradObj = this.state.allBirads.find(b => b.birads === newFF.birads);

            if (biradObj && biradObj.examAutoCompleteYN) {

                this.setState({
                    patientExam: {
                        ...this.state.patientExam, isExamCompleteYN: 1
                    },
                    primaryResult: {
                        resultDate: null,
                    }
                });
            }

            let examHistory = response.data;
            //examHistory.push(newFF);
            this.setState({
                examHistory,
                numAxnDis: false,
                h2nrInd: false,
                showRiskGrades: false,
                showBiopsyTypes: false,
                pbSelected: false
            });
            this.isDirty = false;
            Globals.isDirtyPage = false;
            this.hideAddFollowUp();
            this.props.updateExamInfo(examHistory);

            toast.success("Result has been added.");

        }).catch(error => {
            console.log(error);
        });
    }

    handleUpdate = () => {
        if (this.state.missingFields) {
            toast.error("Please fill in all required information.");
        }
        else {
            if (this.state.examHistory[this.state.examHistory.length - 1] && this.state.examHistory[this.state.examHistory.length - 1].birad === 'B0' && this.state.formFields.isExamCompleteYN === 1) {
                confirm(
                    {
                        title: "You are attempting to complete an exam with a latest BIRAD of B0.",
                        content:
                            "Are you sure you would like to proceed?",
                        buttons: ["Yes", "No"],
                        theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
                    },
                    (ButtonPressed) => {
                        if (ButtonPressed === "Yes") {
                            this.checkMissingRecallBeforeUpdate();
                            return 0;
                        }
                        if (ButtonPressed === "No") {
                            return 0;
                        }
                    }
                );
            } else {
                this.checkMissingRecallBeforeUpdate();
            }
        }
        
    }

    checkMissingRecallBeforeUpdate = () => {
        if (this.state.missingRecallDate) {
            confirm(
                {
                    title: "You are attempting to save a normal result without supplying a recall date.",
                    content:
                        "Are you sure you would like to proceed?",
                    buttons: ["Yes", "No"],
                    theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
                },
                (ButtonPressed) => {
                    if (ButtonPressed === "Yes") {
                        this.updatePatientExamAndResults();
                        return 0;
                    }
                    if (ButtonPressed === "No") {
                        window.history.pushState(null, null, null);
                        return 0;
                    }
                }
            );
        }
        else if (this.state.pastRecallDate) {
            confirm(
                {
                    title: "You are attempting to save a result with a recall date in the past.",
                    content:
                        "Are you sure you would like to proceed?",
                    buttons: ["Yes", "No"],
                    theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
                },
                (ButtonPressed) => {
                    if (ButtonPressed === "Yes") {
                        this.updatePatientExamAndResults();
                        return 0;
                    }
                    if (ButtonPressed === "No") {
                        window.history.pushState(null, null, null);
                        return 0;
                    }
                }
            );
        }
        else {
            this.updatePatientExamAndResults();
        }
    }

    updatePatientExamAndResults = () => {
        if (this.state.ffDirty) {

            let dirtyHistory = []
            for (var i = 0; i < this.state.examHistory.length; i++) {
                let history = this.state.examHistory[i];
                if (history.isDirty) {
                    history.recommendationID = history.recommendationID ? history.recommendationID : null;
                    history.resultDate = history.resultDate ? new Date(history.resultDate) : null;
                    history.isBreastDense = (history.isBreastDense !== null && history.isBreastDense >= 0) ? history.isBreastDense.toString() : '0';
                    history.sizeofFinding = history.sizeofFinding && parseInt(history.sizeofFinding);
                    history.numberofAxillaryNodes = history.numberofAxillaryNodes && parseInt(history.numberofAxillaryNodes);
                    history.patientExamID = this.state.examInfo.patientExamModel?.patientExamID;
                    //history.patientExamID = this.state.formFields.patientExamID;
                    dirtyHistory.push(this.state.examHistory[i]);
                }
            }

            let patientExam = { ...this.state.formFields };
            //patientExam.isExamCompleteYN = this.state.formFields.isExamCompleteYN.toString();

            let postdata = {
                patientExam,
                patientResults: dirtyHistory
            }

            updateExamAndResults(postdata).then(data => {
                toast.success("Exam information updated.");

                this.props.updateExamInfo(data);
            });
        }
        this.isDirty = false;
        Globals.isDirtyPage = false;
        this.hidePane();

        this.hideAddFollowUp();
        
    }

    clear = () => {

        this.setState({
            examInfo: this.props.examInfo,
            formFields: this.state.persistentFormFields,
            patientExam: this.props.examInfo.patientExamModel,
            patientExamResult: this.props.examInfo.patientExamResultModel,
            showAddFollowUp: false,
            followUpAddFields: {
                resultDate: null
            },
            primaryResult: {
                resultDate: null,
            },
            numAxnDis: false,
            h2nrInd: false,
            showRiskGrades: false,
            showBiopsyTypes: false,
            pbSelected: false,
            readOnly: this.state.persistentFormFields.isExamCompleteYN === 1 ? true : false
        });

        this.isDirty = false;
        Globals.isDirtyPage = false;
    }

    deleteResult = (e, idx) => {
        const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

            confirm(
                {
                    title: "You are about to permanently delete this result.",
                    content:
                        "Are you sure you would like to proceed?",
                    buttons: ["Yes", "No"],
                    theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
                },
                (ButtonPressed) => {
                    if (ButtonPressed === "Yes") {

                        if (idx === 0) {
                            let postdata = {
                                uniqueID: this.state.examHistory[idx].patientResultID,
                                deleteAllResults: true,
                                userID: userInfo.userId
                            };

                            Axios.post(`/api/DeletePatientExamResult`, postdata
                            ).then(response => {

                                toast.success("All results have been deleted.");

                                this.props.updateExamInfo(response.data);

                                let examHistory = [];

                                this.setState({
                                    examHistory,
                                    persistentExamHistory: examHistory,
                                    formFields: { ...this.state.formFields, isExamCompleteYN: 0 },
                                    readOnly: false
                                });
                            }).catch(error => {

                                toast.error("Result could not be deleted at this time.");
                            });
                        } else {
                            
                            let postdata = {
                                uniqueID: this.state.examHistory[idx].patientResultID,
                                userID: userInfo.userId
                            };

                            Axios.post(`/api/DeletePatientExamResult`, postdata
                            ).then(response => {

                                toast.success("This result has been deleted");

                                this.props.updateExamInfo(response.data);

                                let examHistory = this.state.examHistory;
                                examHistory.splice(idx, 1);

                                this.setState({
                                    examHistory
                                });
                            }).catch(error => {
                                toast.error("Result could not be deleted at this time.");
                            });
                        }
                        return 0;
                    }
                    if (ButtonPressed === "No") {
                        window.history.pushState(null, null, null);
                        return 0;
                    }
                }
            );
        
    }

    printPlainLanguageLetter = (birad, resultID) => {
        console.log(resultID);
        const q = Axios.defaults.baseURL + "api/RenderPlainLanguageLetter";

        var form = document.createElement("form");
        form.target = "_blank";
        form.method = "POST";
        form.action = q;
        form.style.display = "none";

        var input = document.createElement("input");
        input.type = "hidden";
        input.name = "birad";
        input.value = birad;
        form.appendChild(input);

        var input = document.createElement("input");
        input.type = "hidden";
        input.name = "facilityID";
        input.value = this.state.examInfo.patientExamModel?.facilityID;
        form.appendChild(input);

        var input = document.createElement("input");
        input.type = "hidden";
        input.name = "patientExamID";
        input.value = this.state.examInfo.patientExamModel?.patientExamID;
        form.appendChild(input);

        var input = document.createElement("input");
        input.type = "hidden";
        input.name = "patientResultID";
        input.value = resultID;
        form.appendChild(input);

        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
    }

    hideAttachments = () => {
        this.setState({
            showAttachments: false
        });
    }

    render() {
        const theme = window.sessionStorage.getItem("theme");

        const disableCompleteCheckbox = this.props.examInfo?.examTypeModel?.recallable === 1 && this.state.examHistory.length === 0;

        return (
            <SlidingPane
                className='some-custom-class'
                className={theme === 'dark' ? "dark-theme" : 'light-theme'}
                overlayClassName='pinkCard'
                isOpen={this.state.showPane}
                title='Exam Details'
                onRequestClose={this.hidePane}
            >
                <div className="slide-pane-body" ref={(div) => {
                    this.messageList = div;
                }}>
                    <div className="row justify-content-end">
                        <div className="form-group col-12 text-right">
                            <span className="ml-3 fs-24 color-pink">*</span> = Required Field
                        </div>
                    </div>
                    <Card className="pinkCard">
                        <Card.Header>
                            <span>Exam</span>
                            <i className="fas fa-paperclip fa-125x text-pink float-right hover" onClick={() => this.setState({showAttachments: true})}></i>
                        </Card.Header>
                        <Card.Body>

                            <div className="row">
                                <div className="form-group col-lg-5">
                                    <label className="form-label">Exam Date</label>
                                    <DatePicker
                                        selected={(this.state.formFields.examDateTime) ? Moment(this.state.formFields.examDateTime).toDate() : null}
                                        onChange={(date) => this.handleExamDateChange('examDateTime', date)}
                                        className="form-control-custom"
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        customInput={
                                            <NumberFormat format="##/##/####" mask="_" />
                                        }
                                    />
                                </div>

                                

                                <div className="form-group col-lg-5">
                                    <label className="form-label">Exam Type</label>
                                    <select className="form-control-custom" value={this.state.formFields.examTypeID} name="examTypeID" onChange={this.handleInputChange}>
                                        <option />
                                        {this.state.examTypes.map((type, idx) => <option key={idx} value={type.examTypeID} onChange={this.handleInputChange}>{type.examType}</option>)}

                                    </select>
                                </div>

                                <div className="col-lg-2">
                                    <label className="form-label">Complete</label>
                                    <div className="form-control-custom no-border">
                                        <div className="custom-control custom-checkbox custom-control-inline">
                                            <input type="checkbox" className="custom-control-input" id="isExamCompleteYN" name="isExamCompleteYN" value={this.state.formFields.isExamCompleteYN || ''} onChange={this.handleInputChange}
                                                checked={this.state.formFields.isExamCompleteYN === 1 ? true : false} disabled={disableCompleteCheckbox}
                                                title={disableCompleteCheckbox ? "An exam can't be completed without a result" : ''} />
                                            <label className="custom-control-label"></label>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group col-lg-4">
                                    <label className="form-label">Technologist</label>
                                    <select className="form-control-custom" value={this.state.formFields.performingTechUserID} name="performingTechUserID" onChange={this.handleInputChange}>
                                        <option />
                                        {this.state.technologistList.map((tech, idx) => <option key={idx} value={tech.userID} onChange={this.handleInputChange}>{tech.fName} {tech.lName}</option>)}

                                    </select>
                                </div>

                                <div className="form-group col-lg-4">
                                    <label className="form-label">Insurance</label>
                                    <select className="form-control-custom" value={this.state.formFields.insuranceID} name="insuranceID" onChange={this.handleInputChange}>
                                        <option />
                                        {this.state.insuranceCompanies.map((company, idx) => <option key={idx} value={company.insuranceID} onChange={this.handleInputChange}>{company.insCompName}</option>)}

                                    </select>
                                </div>

                                <div className="form-group col-lg-4">
                                    <label className="form-label">Referring MD</label>
                                    <select className="form-control-custom" value={this.state.formFields.providerID} name="providerID" onChange={this.handleInputChange}>
                                        <option />
                                        {this.state.providersList.map((provider, idx) => <option key={idx} value={provider.providerID}>{provider.fName + ' ' + provider.lName + ', ' + provider.title}</option>)}

                                    </select>
                                </div>

                                {this.state.showExamReasonDropdown &&
                                    <div className="form-group col-lg-4">
                                        <label className="form-label">Exam Reason <span className="color-pink">*</span></label>
                                        <select className="form-control-custom" value={this.state.formFields.reasonforExamID} name="reasonforExamID" onChange={this.handleInputChange}>
                                            <option />
                                            {this.state.examReasons.map((reason, idx) => <option key={idx} value={reason.reasonforExamID}>{reason.reasonforExam}</option>)}

                                        </select>
                                    </div>
                                }

                            </div>

                        </Card.Body>
                    </Card>

                    {this.state.itemsLoaded && this.state.listsLoaded && this.state.examHistory.length > 0 &&
                        <div>

                        <PrimaryResultCard idx={0} historyItem={this.state.examHistory[0]} readOnly={this.state.readOnly}
                            recallableDisabled={this.state.recallableDisabled} allBiopsyTypes={this.state.allBiopsyTypes}
                            biradsFindings={this.state.biradsFindings} biradsList={this.state.biradsList}
                            riskGrades={this.state.riskGrades} recommendationsList={this.state.recommendationsList} recallTypesList={this.state.recallTypesList}
                            examHistory={this.state.examHistory} biradRecommendations={this.state.biradRecommendations} newBiradRecommendations={this.state.newBiradRecommendations} findingsList={this.state.findingsList} densityList={this.state.densityList}
                            radiologistList={this.state.radiologistList} categoryName={this.state.categoryName}
                            primaryResultDate={this.state.primaryResult.resultDate} examInfo={this.state.examInfo} formFields={this.state.formFields}
                            updateFollowUpInfo={this.updateFollowUpInfo} deleteResult={this.deleteResult} updateHistoryDateInfo={this.updateHistoryDateInfo}
                            examIsSCRMAM={this.state.examIsSCRMAM} />


                        {this.state.examHistory.map((history, i) =>
                            <div key={i}>
                                {i > 0 &&
                                    <FollowUpResultCard idx={i} historyItem={history} readOnly={this.state.readOnly}
                                        recallableDisabled={this.state.recallableDisabled} allBiopsyTypes={this.state.allBiopsyTypes}
                                        biradsFindings={this.state.biradsFindings} biradsList={this.state.biradsList}
                                    riskGrades={this.state.riskGrades} recommendationsList={this.state.recommendationsList} recallTypesList={this.state.recallTypesList}
                                    examHistory={this.state.examHistory} biradRecommendations={this.state.biradRecommendations} newBiradRecommendations={this.state.newBiradRecommendations} findingsList={this.state.findingsList}
                                        primaryResultDate={this.state.primaryResult.resultDate} examInfo={this.state.examInfo} formFields={this.state.formFields}
                                    updateFollowUpInfo={this.updateFollowUpInfo} deleteResult={this.deleteResult} updateHistoryDateInfo={this.updateHistoryDateInfo} />
                                }
                                </div>
                        
                        )}

                        
                            
                        </div>
                    }

                    {this.state.showAddFollowUp &&
                        <Fragment>
                        {this.state.examHistory.length > 0 ? 
                            <Card className="pinkCard">
                                <Card.Header><span>Follow Up Result</span><span onClick={this.hideAddFollowUp} className="float-right">&times;</span>
                                </Card.Header>
                                <Card.Body>

                                    <div className="row">
                                        <div className="form-group col-lg-3">
                                            <label className="form-label">Result Date <span className="color-pink">*</span></label>
                                            <DatePicker
                                                selected={this.state.followUpAddFields.resultDate && this.state.followUpAddFields.resultDate}
                                                onChange={(date) => this.handleDateChange('resultDate', date)}
                                                className="form-control-custom"
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                customInput={
                                                    <NumberFormat format="##/##/####" mask="_" />
                                                }
                                            />
                                        </div>


                                        <div className="form-group col-lg-2">
                                            <label className="form-label">BIRADS/Results <span className="color-pink">*</span></label>
                                            <select className="form-control-custom" name="birad" value={this.state.followUpAddFields.birad || ''} onChange={this.handleFollwUpChange}>
                                                <option />
                                                {this.state.biradsList.map((birad, idx) => <option key={idx} value={birad.birads}>{birad.description}</option>)}

                                            </select>
                                        </div>

                                        <div className="form-group col-lg-2">
                                            {this.state.findingsAddList?.length > 0 &&
                                                <Fragment>
                                                    <label className="form-label">Finding <span className="color-pink">*</span></label>
                                                <select className="form-control-custom" name="findingID" value={this.state.followUpAddFields.findingID || ''} onChange={this.handleFollwUpChange}>
                                                        <option />
                                                    {this.state.findingsAddList.map((f, idx) => <option key={idx} value={f.findingID}>{f.findingDescription}</option>)}
                                                    </select>
                                                </Fragment>
                                            }
                                        </div>
                                        <div className="col-lg-2">
                                            {this.state.showAddRiskGrades &&
                                                <Fragment>
                                                    {this.state.selectedAddRiskGrades?.length === 1 ?
                                                        <Fragment>
                                                        <label className="form-label">Risk/Grade <span className="color-pink">*</span></label>
                                                        <input className="form-control-custom no-border" value={this.state.selectedAddRiskGrades[0].riskGradeDescription} readOnly />
                                                        </Fragment>
                                                        :
                                                        <Fragment>
                                                        <label className="form-label">Risk/Grade <span className="color-pink">*</span></label>
                                                        <select className="form-control-custom" value={this.state.followUpAddFields.riskGradeID || ''} name="riskGradeID" onChange={this.handleFollwUpChange}>
                                                                <option />
                                                                {this.state.selectedAddRiskGrades.map((rg, idx) => <option key={idx} value={rg.riskGradeID}>{rg.riskGradeDescription}</option>)}
                                                            </select>
                                                        </Fragment>
                                                    }
                                                </Fragment>
                                            }
                                        </div>

                                        <div className="col-lg-2">
                                            {this.state.showBiopsyTypes &&
                                                <Fragment>
                                                <label className="form-label">Biopsy Type <span className="color-pink">*</span></label>
                                                <select className="form-control-custom" value={this.state.followUpAddFields.biopsyTypeID || ''} name="biopsyTypeID" onChange={this.handleFollwUpChange}>
                                                        <option />
                                                        {this.state.allBiopsyTypes.map((b, idx) => <option key={idx} value={b.biopsyTypeID}>{b.biopsyTypeDescription}</option>)}
                                                    </select>
                                                </Fragment>
                                            }
                                        </div>


                                        

                                        <div className="form-group col-lg-6">
                                            <label className="form-label">Recommendation <span className="color-pink">*</span></label>
                                            <select className="form-control-custom" name="recommendationID" value={this.state.followUpAddFields.recommendationID || ''} onChange={this.handleFollwUpChange}>
                                                <option />
                                                {this.state.recommendationsAddList.map((rec, idx) => <option key={idx} value={rec.recommendationID}>{rec.description}</option>)}

                                            </select>
                                        </div>


                                        <div className="form-group col-lg-3">
                                            <label className="form-label">Recall Type {!this.state.recallableDisabled && <span className="color-pink">*</span>}</label>
                                            <select className="form-control-custom" name="recallTypeID" value={this.state.followUpAddFields.recallTypeID || ''} onChange={this.handleFollwUpChange} disabled={this.state.recallableDisabled}>
                                                <option />
                                                {this.state.recallTypesList.map((type, idx) => <option key={idx} value={type.recallTypeID}>{type.recallTypeDescription}</option>)}

                                            </select>
                                        </div>

                                        <div className="form-group col-lg-3">
                                            <label className="form-label">Recall Date</label>
                                            <DatePicker
                                                selected={(this.state.followUpAddFields?.recallDate && Moment(this.state.followUpAddFields.recallDate).isValid()) ? Moment(this.state.followUpAddFields.recallDate).toDate() : null}
                                                onChange={(date) => this.handleDateChange('recallDate', date)}
                                                className="form-control-custom"
                                                disabled={this.state.recallableDisabled}
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                customInput={
                                                    <NumberFormat format="##/##/####" mask="_" />
                                                }
                                            />
                                        </div>
                                        {(this.state.followUpAddFields.birad === 'B0' || this.state.followUpAddFields.birad === 'B3' || this.state.followUpAddFields.birad === 'B4' || this.state.followUpAddFields.birad === 'B5') &&
                                            <div className="col-lg-2">
                                                <label className="form-label">Non-Compliant</label>
                                                <div className="form-control-custom no-border">
                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                        <input type="checkbox" className="custom-control-input" id="losttoFollowUp" name="losttoFollowUp" value={this.state.followUpAddFields.losttoFollowUp || ''} onChange={this.handleFollwUpChange} />
                                                        <label className="custom-control-label"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {this.state.pbSelected &&
                                            <div className="col-12">
                                                <fieldset>
                                                    <legend>&nbsp;Biopsy Details&nbsp;</legend>
                                                    <div className="row">
                                                        <div className="form-group col col-lg-3 col-sm-4 col-12">
                                                            <label className="form-label">ER</label>
                                                            <select className="form-control-custom" value={this.state.followUpAddFields.erreceptor || ''} name="erreceptor" onChange={this.handleFollwUpChange}>
                                                                <option />
                                                                <option>Positive</option>
                                                                <option>Negative</option>
                                                            </select>
                                                        </div>
                                                        <div className="form-group col col-lg-3 col-sm-4 col-12">
                                                            <label className="form-label">PR</label>
                                                            <select className="form-control-custom" value={this.state.followUpAddFields.prreceptor || ''} name="prreceptor" onChange={this.handleFollwUpChange}>
                                                                <option />
                                                                <option>Positive</option>
                                                                <option>Negative</option>
                                                            </select>
                                                        </div>
                                                        <div className="form-group col col-lg-3 col-sm-4 col-12">
                                                            <label className="form-label">Her2/Neu</label>
                                                            <select className="form-control-custom" value={this.state.followUpAddFields.her2NeuReceptor || ''} name="her2NeuReceptor" onChange={this.handleFollwUpChange}>
                                                                <option />
                                                                <option>Positive</option>
                                                                <option>Negative</option>
                                                                <option>Indeterminate</option>
                                                            </select>
                                                        </div>
                                                        <div className="form-group col col-lg-3 col-sm-4 col-12">
                                                            {this.state.h2nrInd &&
                                                                <Fragment>
                                                                    <label className="form-label">FISH</label>
                                                                    <select className="form-control-custom" value={this.state.followUpAddFields.fishreceptor || ''} name="fishreceptor" onChange={this.handleFollwUpChange}>
                                                                        <option />
                                                                        <option>Positive</option>
                                                                        <option>Negative</option>
                                                                    </select>
                                                                </Fragment>
                                                            }
                                                        </div>
                                                        <div className="form-group col-lg-2 col-sm-4 col-12">
                                                            <label className="form-label">Size of Finding (cm)</label>
                                                            <input type="number" name="sizeofFinding" value={this.state.followUpAddFields.sizeofFinding || ''} onChange={this.handleFollwUpChange} className="form-control-custom" />
                                                        </div>
                                                        <div className="col col-lg-2 col-sm-4 col-12">
                                                            <label className="form-label">Axillary Node Status</label>
                                                            <select className="form-control-custom" value={this.state.followUpAddFields.axillaryNodeStatus || ''} name="axillaryNodeStatus" onChange={this.handleFollwUpChange}>
                                                                <option />
                                                                <option>Positive</option>
                                                                <option>Negative</option>
                                                            </select>
                                                        </div>
                                                        <div className="col col-lg-3 col-sm-4 col-12">
                                                            {this.state.numAxnDis &&
                                                                <Fragment>
                                                                    <label className="form-label"># of Axillary Nodes Dissected</label>
                                                                    <input type="number" name="numberofAxillaryNodes" value={this.state.followUpAddFields.numberofAxillaryNodes || ''} onChange={this.handleFollwUpChange} className="form-control-custom" />
                                                                </Fragment>
                                                            }
                                                        </div>
                                                        <div className="col col-lg-2 col-sm-4 col-12">
                                                            <label className="form-label">Stage</label>
                                                            <select className="form-control-custom" value={this.state.followUpAddFields.stage || ''} name="stage" onChange={this.handleFollwUpChange}>
                                                                <option />
                                                                <option>0</option>
                                                                <option>I</option>
                                                                <option>II</option>
                                                                <option>III</option>
                                                                <option>IV</option>
                                                            </select>
                                                        </div>
                                                        <div className="col col-lg-2 col-sm-4 col-12">
                                                            <label className="form-label">MRI Performed</label>
                                                            <select className="form-control-custom" value={this.state.followUpAddFields.mriPerformed || ''} name="mripPerformed" onChange={this.handleFollwUpChange}>
                                                                <option />
                                                                <option>Yes</option>
                                                                <option>No</option>
                                                            </select>
                                                        </div>

                                                    </div>
                                                </fieldset>
                                            </div>
                                        }

                                        <div className="form-group col-lg-8">
                                            <label className="form-label">Notes</label>
                                            <textarea className="form-control-custom" rows="6" name="notes" value={this.state.followUpAddFields.notes || ''} onChange={this.handleFollwUpChange} />
                                        </div>

                                    </div>

                                </Card.Body>
                            </Card>
                            :
                            <Card className="pinkCard">
                                <Card.Header><span>Primary Result</span>
                                </Card.Header>
                                <Card.Body>

                                    <div className="row">
                                        <div className="form-group col-lg-2">
                                            <label className="form-label">Result Date <span className="color-pink">*</span></label>
                                            <DatePicker
                                                selected={this.state.primaryResult?.resultDate && Moment(this.state.primaryResult.resultDate).toDate()}
                                                onChange={(date, dateName) => this.handlePrimaryResultDateChange(date, "resultDate")}
                                                className="form-control-custom"
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                customInput={
                                                    <NumberFormat format="##/##/####" mask="_" />
                                                }
                                            />
                                        </div>

                                        <div className="form-group col-lg-2">
                                            <label className="form-label">Radiologist <span className="color-pink">*</span></label>
                                            <select className="form-control-custom" value={this.state.primaryResult.radiologistID || ''} name="radiologistID" onChange={this.handlePrimaryResultChange}>
                                                <option />
                                                {this.state.radiologistList.map((rad, idx) => <option key={idx} value={rad.radiologistID}>{rad.fName + ' ' + rad.lName}</option>)}

                                            </select>
                                        </div>

                                        <div className="form-group col-lg-2">
                                            <label className="form-label">BIRADS/Results <span className="color-pink">*</span></label>
                                            <select className="form-control-custom" name="birad" value={this.state.primaryResult.birad || ''} onChange={this.handlePrimaryResultChange}>
                                                <option />
                                                {this.state.biradsList.filter(b => b.primaryResultYN).map((birad, idx) => <option key={idx} value={birad.birads}>{birad.description}</option>)}

                                            </select>
                                        </div>
                                        <div className="form-group col-lg-2">
                                            {this.state.findingsList?.length > 0 &&
                                            <Fragment>
                                                <label className="form-label">Finding <span className="color-pink">*</span></label>
                                                <select className="form-control-custom" name="findingID" value={this.state.primaryResult.findingID || ''} onChange={this.handlePrimaryResultChange}>
                                                    <option />
                                                {this.state.findingsList.map((f, idx) => <option key={idx} value={f.findingID}>{f.findingDescription}</option>)}
                                                </select>
                                            </Fragment>
                                        }
                                        </div>
                                        <div className="col-lg-2">
                                            {this.state.showRiskGrades &&
                                                <Fragment>
                                                    {this.state.selectedRiskGrades?.length === 1 ?
                                                        <Fragment>
                                                        <label className="form-label">Risk/Grade <span className="color-pink">*</span></label>
                                                        <input className="form-control-custom no-border" defaultValue={this.state.selectedRiskGrades[0].riskGradeDescription || ''} />
                                                        </Fragment>
                                                        :
                                                        <Fragment>
                                                        <label className="form-label">Risk/Grade <span className="color-pink">*</span></label>
                                                        <select className="form-control-custom" value={this.state.primaryResult.riskGradeID || ''} name="riskGradeID" onChange={this.handlePrimaryResultChange}>
                                                            <option />
                                                            {this.state.selectedRiskGrades.map((rg, idx) => <option key={idx} value={rg.riskGradeID}>{rg.riskGradeDescription}</option>)}
                                                        </select>
                                                        </Fragment>
                                                    }
                                            </Fragment>
                                        }
                                        </div>

                                        <div className="col-lg-2">
                                            {this.state.showBiopsyTypes &&
                                                <Fragment>
                                                    <label className="form-label">Biopsy Type</label>
                                                <select className="form-control-custom" value={this.state.primaryResult.biopsyTypeID || ''} name="biopsyTypeID" onChange={this.handlePrimaryResultChange}>
                                                        <option />
                                                    {this.state.allBiopsyTypes.map((b, idx) => <option key={idx} value={b.biopsyTypeID}>{b.biopsyTypeDescription}</option>)}
                                                    </select>
                                                </Fragment>
                                            }
                                        </div>

                                        <div className="col-lg-2">
                                        {this.state.primaryResult.showBiopsyRefused &&
                                            <Fragment>
                                                <label className="form-label">Biopsy refused/not performed</label>
                                                <div className="form-control-custom no-border">
                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                        <input type="checkbox" className="custom-control-input" id="" name="" disabled={this.state.readOnly} />
                                                        <label className="custom-control-label"></label>
                                                    </div>
                                                </div>
                                            </Fragment>
                                        }
                                        </div>

                                        

                                        <div className="form-group col-lg-3">
                                            <label className="form-label">Recommendation <span className="color-pink">*</span></label>
                                            <select className="form-control-custom" value={this.state.primaryResult.recommendationID || ''} name="recommendationID" onChange={this.handlePrimaryResultChange}>
                                                <option />
                                                {this.state.recommendationsList.map((rec, idx) => <option key={idx} value={rec.recommendationID}>{rec.description}</option>)}

                                            </select>
                                        </div>

                                        <div className="form-group col-lg-3">
                                            <label className="form-label">Density {this.state.examIsSCRMAM && <span className="color-pink">*</span>}</label>
                                            <select className="form-control-custom" value={this.state.primaryResult.densityID || ''} name="densityID" onChange={this.handlePrimaryResultChange}>
                                                <option />
                                                {this.state.densityList.map((density, idx) => <option key={idx} value={density.breastDensityID}>{density.densityDescription}</option>)}

                                            </select>
                                        </div>

                                        <div className="form-group col-lg-3">
                                            <label className="form-label">Recall Type {!this.state.recallableDisabled && <span className="color-pink">*</span>}</label>
                                            <select className="form-control-custom" value={this.state.primaryResult.recallTypeID || ''} name="recallTypeID" onChange={this.handlePrimaryResultChange} disabled={this.state.recallableDisabled}>
                                                <option value={null} />
                                                {this.state.recallTypesList.map((type, idx) => <option key={idx} value={type.recallTypeID}>{type.recallTypeDescription}</option>)}

                                            </select>
                                        </div>

                                        <div className="form-group col-lg-3">
                                            <label className="form-label">Recall Date</label>
                                            <DatePicker
                                                selected={(this.state.primaryResult?.recallDate && Moment(this.state.primaryResult.recallDate).isValid()) ? Moment(this.state.primaryResult.recallDate).toDate() : null}
                                                onChange={(date, dateName) => this.handlePrimaryResultDateChange(date, "recallDate")}
                                                className="form-control-custom"
                                                disabled={this.state.recallableDisabled}
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                customInput={
                                                    <NumberFormat format="##/##/####" mask="_" />
                                                }
                                            />
                                        </div>

                                        <div className="col-lg-4">
                                            <div className="row">
                                                {(this.state.primaryResult.birad === 'B0' || this.state.primaryResult.birad === 'B3' || this.state.primaryResult.birad === 'B4' || this.state.primaryResult.birad === 'B5') &&
                                                    <div className="col-lg-6">
                                                        <label className="form-label">Non-Compliant</label>
                                                        <div className="form-control-custom no-border">
                                                            <div className="custom-control custom-checkbox custom-control-inline">
                                                                <input type="checkbox" className="custom-control-input" id="losttoFollowUp" name="losttoFollowUp" value={this.state.primaryResult.losttoFollowUp || ''} onChange={this.handlePrimaryResultChange} checked={this.state.primaryResult && this.state.primaryResult.losttoFollowUp === "1" ? true : false} />
                                                                <label className="custom-control-label"></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="col-lg-6">
                                                {!(this.state.categoryName === 'SCRMAM') &&
                                                    <Fragment>
                                                        <label className="form-label">Close for Dx</label>
                                                        <div className="form-control-custom no-border">
                                                            <div className="custom-control custom-checkbox custom-control-inline">
                                                                <input type="checkbox" className="custom-control-input" id="closedForDxExam" name="closedForDxExam" value={this.state.primaryResult.closedForDxExam || ''} onChange={this.handlePrimaryResultChange} checked={this.state.primaryResult && this.state.primaryResult.closedForDxExam === '1' ? true : false} />
                                                                <label className="custom-control-label"></label>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                }
                                                </div>
                                                
                                            </div>
                                        </div>

                                        <div className="form-group col-lg-8">
                                            <label className="form-label">Notes</label>
                                            <textarea className="form-control-custom" rows="6" value={this.state.primaryResult.notes || ''} name="notes" onChange={this.handlePrimaryResultChange} />
                                        </div>

                                        <div className="form-group col-lg-12">
                                            <label className="form-label">Last Modified By</label>
                                            <input className="form-control-custom" value={Globals.userInfo.fullName} readOnly />
                                        </div>


                                    </div>

                                </Card.Body>
                            </Card>
}
                        
                    </Fragment>
                    }

                </div>

                <div className="modal-footer">
                    <div className="form-group col-12 padding-25-10">
                        {!this.state.showAddFollowUp ?
                            <Fragment>
                                <button className="btn btn-submit" onClick={this.handleUpdate}>Save</button>
                                {this.state.examHistory.length > 0 && <button className="pinkTextA margin-left-15 btn btn-outline-default" onClick={() => this.printPlainLanguageLetter(this.state.examHistory[0].birad, this.state.examHistory[0].patientResultID)}>Print Plain Language Letter</button>}
                            </Fragment>
                            :
                            <Fragment>
                                {this.state.examHistory.length > 0 ?
                                    <Fragment>
                                        {this.state.sharedFollowUpIDs.length > 0 ?
                                            <button className="btn btn-submit" onClick={this.handleSubmit}>Save Shared Follow Up Result</button>    
                                            :
                                            <button className="btn btn-submit" onClick={this.handleSubmit}>Save Follow Up Result</button>    
                                        }
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <button className="btn btn-submit" onClick={() => this.addPrimaryResult(false)}>Save Primary Result</button>
                                        <button className="pinkTextA margin-left-15 btn btn-outline-default" onClick={() => this.addPrimaryResult(true)} disabled={this.state.readOnly}>Save and Print Plain Language Letter</button>
                                    </Fragment>
}
                            </Fragment>
                            
                        }
                        
                        <button className="btn btn-outline-default margin-left-15" onClick={this.hidePane}>Cancel</button>
                        <button className="btn btn-outline-default margin-left-15" onClick={this.clear}>Clear</button>
                        {!this.state.showAddFollowUp &&
                            <Fragment>
                            {this.state.examHistory.length > 0 ?
                                <Fragment>
                                        {this.state.sharedFollowUpIDs.length > 0 ?
                                            <button className="pinkTextA btn btn-outline-default float-right" onClick={this.addEmptyHistory} disabled={this.state.readOnly}>Add Shared Follow Up Result</button>    
                                            :
                                            <button className="pinkTextA btn btn-outline-default float-right" onClick={this.addEmptyHistory} disabled={this.state.readOnly}>Add Follow Up Result</button>
                                        }
                                    </Fragment>
                                
                                :
                                <button className="pinkTextA btn btn-outline-default float-right" onClick={this.addEmptyHistory} disabled={this.state.readOnly}>Add Primary Result</button>
                            }
                            
                        </Fragment>
                        }
                    </div>
                </div>

                <SlidingPane
                    className='some-custom-class'
                    className={theme === 'dark' ? "dark-theme" : 'light-theme'}
                    overlayClassName='pinkCard'
                    isOpen={this.state.showAttachments}
                    title='Exam Attachments'
                    onRequestClose={this.hideAttachments}
                >
                    <div className="slide-pane-body">
                        <PatientAttachments {...this.props} examID={this.state.formFields.patientExamID} level="Exam" hidePane={this.hideAttachments}/>
                    </div>
                </SlidingPane>
            </SlidingPane>
        );
    }
}

export default withRouter(PatientExamPane);