import Axios from '../../config/axios';

export function getAllPatientExamInfoByPatient(uniqueID) {
    return Axios.get(`/api/GetAllPatientExamInfoByPatient`, {
        params: {
            patientID: uniqueID
        }
    }).then(response => response.data).catch(error => {
        console.log(error);
    });
}

export function updateExamAndResults(postdata) {
    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    postdata.userID = userInfo.userId;

    return Axios.post('/api/UpdateExamAndResults', postdata)
        .then(response => response.data)
        .catch(error =>  console.log(error));
}

export function getWeeklyExamsByMonth() {
    return Axios.get(`/api/GetWeeklyExamsByMonth`).then(response => response.data).catch(error => {
        console.log(error);
    });
}

export function insertWeeklyExam(item) {
    return Axios.post('/api/InsertWeeklyExam', item)
        .then(response => response.data)
        .catch(error => console.log(error));
}

export function updateWeeklyExam(item) {
    return Axios.post('/api/UpdateWeeklyExam', item)
        .then(response => response.data)
        .catch(error => console.log(error));
}