import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Axios from '../../config/axios';

import { Empty } from 'antd';

import BootstrapTable from 'react-bootstrap-table-next';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import Card from 'react-bootstrap/Card';
import '../../css/card.css';
import AddAttachmentPane from './AddAttachmentPane';
import { getExamDocuments, getPatientDocuments, deleteDocument } from '../../common/services/DocumentService';

import Moment from 'moment';

import { confirm, toast } from '@rickylandino/react-messages';

function PatientAttachments(props) {

    const [state, setState] = useState({
        loaded: false,
        patientAttachments: []
    });

    const [showAddAttachmentPane, setShowAddAttachmentPane] = useState(false);

    useEffect(() => {
        if (props.level === 'Patient') {
            getPatientDocuments(props.patient.patientID).then(data => {
                setPatientAttachments(data);
            });
        } else if (props.level === 'Exam') {
            getExamDocuments(props.examID).then(data => {
                setPatientAttachments(data);
            });
        }
    }, []);

    function setPatientAttachments(patientAttachments) {
        setState({
            patientAttachments,
            loaded: true
        });
    }

    function printFormatter(cell, row) {
        return (
            <i id='print' title={row.displayName} className='fas fa-print hover fa-125x color-pink' onClick={() => handlePrint(row.documentID)}></i>
        );
    }

    function deleteFormatter(cell, row) {
        return (
            <i id='delete' title={row.displayName} className='fas fa-trash-alt hover fa-125x color-pink' onClick={() => handleDelete(row)}></i>
        );
    }

    function handlePrint(documentID) {
        const q = Axios.defaults.baseURL + "api/RenderDocument";

        var form = document.createElement("form");
        form.target = "_blank";
        form.method = "POST";
        form.action = q;
        form.style.display = "none";

        var input = document.createElement("input");
        input.type = "hidden";
        input.name = "documentID";
        input.value = documentID;
        form.appendChild(input);

        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);

    }

    function handleDelete(document) {
        confirm({
            title: "You are about to delete this document.",
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"]
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {
                deleteDocument(document).then(data => {
                    if (data === 1) {
                        var docIdx = state.patientAttachments.findIndex(pa => pa.documentID === document.documentID);

                        let attachments = [...state.patientAttachments];
                        attachments.splice(docIdx, 1);
                        setPatientAttachments(attachments);

                        toast.success("Document successfully deleted.");
                    } else {
                        toast.error("Something went wrong.");
                    }
                    
                });
                return 0;
            } else {
                return 0;
            }
        });
    }

    const columns = [{
        dataField: 'displayName',
        text: 'Document Name'
    }, {
        dataField: 'documentType',
        text: 'Document Type'
    }, {
        dataField: 'documentDate',
        text: 'Document Date',
        formatter: cell => cell ? Moment(cell).utc().format('L') : null
    }, {
        dataField: 'notes',
        text: 'Notes'
    }, {
        dataField: 'toPrint',
        isDummyField: true,
        text: 'Print',
        formatter: printFormatter
    }, {
        dataField: 'delete',
        isDummyField: true,
        text: 'Delete',
        formatter: deleteFormatter
    }];

    return (
        <>
            <Card className="pinkCard">
                <Card.Header>
                    <span>Patient Documents</span>
                    <button className="ml-3 btn btn-submit" onClick={() => setShowAddAttachmentPane(true)}>Add Attachment</button>
                </Card.Header>
                <Card.Body>
                    {(state.loaded && state.patientAttachments.length > 0) ?
                        <div>
                            <BootstrapTable keyField='documentID'
                                data={state.patientAttachments}
                                columns={columns}
                                condensed />
                        </div>
                        :
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={`There are no ${props.level.toLowerCase()}-level documents for this patient.`} />
                    }
                </Card.Body>
            </Card>   

            {showAddAttachmentPane && <AddAttachmentPane {...props} hidePane={() => setShowAddAttachmentPane(false)} updatePatientAttachments={(att) => setPatientAttachments(att)} />}
        </>
    );
}

export default withRouter(PatientAttachments);