import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import { useForm, Controller } from "react-hook-form";

import statesList from '../Helpers/StatesList';

import { Select, Transfer, Empty, List } from 'antd';

import { getAllFacilities, isUsernameUnique, isEmailUnique, getUserInformationByID, updateUser } from '../../common/services/ApiService';
import { createUser } from '../../common/services/AuthService';

import { toast } from '@rickylandino/react-messages';
import NumberFormat from 'react-number-format';
import { allFacilities, allUsers } from '../../common/services/ReportService';
import { getManageFacilityInformation, insertFacilityLogo, updateFacilityInformation } from '../../common/services/FacilityService';
import { Radio } from 'react-bootstrap';
import RadiologistModal from '../Maintenance/RadiologistModal';
import { useToggle } from '../Helpers/CustomHooks';
import ProviderModal from '../Maintenance/Providers/ProviderModal';
import ManageUser from './ManageUser';
import IssuerOfPatientModal from '../Maintenance/IssuerOfPatientModal';
import PCPModal from '../Maintenance/PCPModal';
import CategoryModal from '../Maintenance/CategoryModal';


function ManageFacility(props) {

    const states = statesList.filter(obj => { return obj.country_code === 'US' });

    const [lists, setLists] = useState({
        allRadiologists: [],
        defaultRadiologistOptions: [],
        targetRadiologists: [],
        allProviders: [],
        defaultProviderOptions: [],
        targetProviders: [],
        allTechs: [],
        allExamTypes: [],
        allIssuers: [],
        allUsers: [],
        targetUsers: [],
        defaultUserOptions: [],
        primaryCarePhysicians: [],
        allCategories: [],
        targetCategories: []
    });

    const [showPane, setShowPane] = useState(true);

    const theme = window.sessionStorage.getItem("theme");

    const { register, handleSubmit, setValue, getValues, watch, setError, clearErrors,
        formState: { errors }, control } = useForm();

    const [radiologistsToUpdate, setRadiologistsToUpdate] = useState([]);
    const [showAddRadiologist, setShowAddRadiologist] = useToggle();

    const [providersToUpdate, setProvidersToUpdate] = useState([]);
    const [showAddProvider, setShowAddProvider] = useToggle();

    const [usersToUpdate, setUsersToUpdate] = useState([]);
    const [showAddUser, setShowAddUser] = useToggle();

    const [categoriesToUpdate, setCategoriesToUpdate] = useState([]);
    const [showAddCategory, setShowAddCategory] = useToggle();

    const [showAddIssuer, setShowAddIssuer] = useToggle();
    const [showAddPCP, setShowAddPCP] = useToggle();

    const [facilityLogo, setFacilityLogo] = useState(null);

    const { Option } = Select;

    useEffect(() => {
            getManageFacilityInformation(props.facilityID).then(data => {
                setLists({
                    allRadiologists: data.allRadiologists,
                    allTechs: data.allTechs,
                    allExamTypes: data.allExamTypes,
                    allIssuers: data.allIssuers,
                    targetRadiologists: data.facilityRadiologists ? data.facilityRadiologists.map(i => (i.radiologistID)) : [],
                    defaultRadiologistOptions: data.facilityRadiologists ? data.facilityRadiologists.map(i => ({ ...i })) : [],
                    allProviders: data.allProviders,
                    targetProviders: data.facilityProviders ? data.facilityProviders.map(i => (i)) : [],
                    defaultProviderOptions: data.facilityProviders ? data.facilityProviders.map(i => ({ ...i })) : [],
                    allUsers: data.allUsers,
                    targetUsers: data.facilityUsers ? data.facilityUsers.map(i => (i)) : [],
                    defaultUserOptions: data.facilityUsers ? getCommonItems(data.allUsers, data.facilityUsers, 'userID') : [],
                    primaryCarePhysicians: data.primaryCarePhysicians ? data.primaryCarePhysicians : [],
                    allCategories: data.allCategories,
                    targetCategories: data.facilityCategories ? data.facilityCategories.map(i => (i.categoryID)) : []
                });

                if (data.facility) {
                    data.facility.inactiveYN = data.facility.inactiveYN === 1 ? true : false;
                    data.facility.b3AutoRecallYN = data.facility.b3AutoRecallYN === 1 ? true : false;
                    if(data.facility.logo)
                        setFacilityLogo(`data:image/jpeg;base64,${data.facility.logo}`);

                    setValue("facility", data.facility);
                }
                
            });
        
        
    }, [])

    function hidePane() {
        setShowPane(false);

        setTimeout(() => {
            props.hidePane();
        }, 1000)
    }

    const onError = (errors, e) => {
        toast.error("Please fill in all required information");
    }

    function onSubmit(parent) {

        parent.facility.facilityID = props.facilityID || '00000000-0000-0000-0000-000000000000';
        parent.facility.inactiveYN = parent.facility.inactiveYN ? 1 : 0;
        parent.facility.b3AutoRecallYN = parent.facility.b3AutoRecallYN ? 1 : 0;

        parent.facility.defaultExamType = parent.facility.defaultExamType ? parent.facility.defaultExamType : null;
        parent.facility.defaultRadiologist = parent.facility.defaultRadiologist ? parent.facility.defaultRadiologist : null;
        parent.facility.defaultTech = parent.facility.defaultTech ? parent.facility.defaultTech : null;

        let facilityVM = {
            facilityModel: parent.facility,
            radiologistsToUpdate,
            providersToUpdate,
            usersToUpdate,
            primaryCarePhysicians: lists.primaryCarePhysicians,
            categoriesToUpdate
        }

        updateFacilityInformation(facilityVM).then(data => {
            if (data) {
                toast.success(props.facilityID ? "Facility has been updated" : "Facility has been added");
                allFacilities.next(data);

                hidePane();
            }
        });
    }

    function getCommonItems(array1, array2, idName) {
        var common = []; // Initialize array to contain common items

        for (var i = 0; i < array1.length; i++) {
            for (var j = 0; j < array2.length; j++) {
                if (array1[i][idName] == array2[j]) { // If item is present in both arrays
                    common.push(array1[i]); // Push to common array
                }
            }
        }

        return common; // Return the common items
    }

    function handleRadChange(targetKeys, direction, moveKeys, itemToPush = null) {
        let rtu = [...radiologistsToUpdate];
        let defaultRads = [...lists.defaultRadiologistOptions];

        moveKeys.forEach((key, idx) => {
            var radIdx = rtu.findIndex(r => r.id === key);
            if (radIdx >= 0) {
                rtu[radIdx].delete = direction === 'left' ? true : false
            } else {
                var newItem = {
                    id: key,
                    delete: direction === 'left' ? true : false
                };

                rtu.push(newItem);
            }
        });

        setRadiologistsToUpdate(rtu);

        let fullList = [...lists.allRadiologists];
        if (itemToPush) {
            fullList.push(itemToPush);
        }

        defaultRads = getCommonItems(fullList, targetKeys, 'radiologistID');

        setLists({
            ...lists,
            targetRadiologists: targetKeys,
            defaultRadiologistOptions: defaultRads,
            allRadiologists: fullList
        });
    };

    function closeAndUpdateRadiologist(radiologistItem) {
        setShowAddRadiologist();

        let targetKeys = [...lists.targetRadiologists];
        targetKeys.push(radiologistItem.radiologistID);

        handleRadChange(targetKeys, 'right', [radiologistItem.radiologistID], radiologistItem);
    }

    function handleProviderChange(targetKeys, direction, moveKeys, itemToPush = null) {
        let ptu = [...providersToUpdate];
        let defaultProvs = [...lists.defaultProviderOptions];

        moveKeys.forEach((key, idx) => {
            var provIdx = ptu.findIndex(r => r.id === key);
            if (provIdx >= 0) {
                ptu[provIdx].delete = direction === 'left' ? true : false
            } else {
                var newItem = {
                    id: key,
                    delete: direction === 'left' ? true : false
                };

                ptu.push(newItem);
            }
        });

        setProvidersToUpdate(ptu);

        let fullList = [...lists.allProviders];
        if (itemToPush) {
            fullList.push(itemToPush);
        }

        defaultProvs = getCommonItems(fullList, targetKeys, 'providerID');

        setLists({
            ...lists,
            targetProviders: targetKeys,
            defaultProviderOptions: defaultProvs,
            allProviders: fullList
        });
    };

    function closeAndUpdateProvider(providerItem) {
        setShowAddProvider();

        let targetKeys = [...lists.targetProviders];
        targetKeys.push(providerItem.providerID);

        handleProviderChange(targetKeys, 'right', [providerItem.providerID], providerItem);
    }

    function handleUserChange(targetKeys, direction, moveKeys, itemToPush = null) {
        let utu = [...usersToUpdate];
        let defaultUsers = [...lists.defaultUserOptions];

        moveKeys.forEach((key, idx) => {
            var userIdx = utu.findIndex(r => r.id === key);
            if (userIdx >= 0) {
                utu[userIdx].delete = direction === 'left' ? true : false
            } else {
                var newItem = {
                    id: key,
                    delete: direction === 'left' ? true : false
                };

                utu.push(newItem);
            }
        });

        setUsersToUpdate(utu);

        let fullList = [...lists.allUsers];
        if (itemToPush) {
            fullList.push(itemToPush);
        }

        defaultUsers = getCommonItems(fullList, targetKeys, 'userID');

        setLists({
            ...lists,
            targetUsers: targetKeys,
            defaultUserOptions: defaultUsers,
            allUsers: fullList
        });
    };

    function closeAndUpdateUser(user) {
        setShowAddUser();

        let targetKeys = [...lists.targetUsers];
        targetKeys.push(user.userID);

        handleUserChange(targetKeys, 'right', [user.userID], user);
    }

    function handleLogoUpload(e) {
        let file = e.target.files[0];
        insertFacilityLogo(file, props.facilityID).then(data => {
            if (data) {
                toast.success("Logo has been uploaded");

                setValue("facility.logo", data);

                getBase64(file, (result) => {
                    setFacilityLogo(result);
                });
            }
        })
    }

    function getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    function closeAndUpdateIssuer(issuer) {
        setShowAddIssuer();
        let ai = [...lists.allIssuers];
        ai.push(issuer);

        setLists({
            ...lists,
            allIssuers: ai
        });

        setValue("facility.issuerofPatientID", issuer.issuerofPatientID);
    }

    function closeAndUpdatePCP(pcp) {
        setShowAddPCP();

        if ((props.facilityID && pcp.facilityID === props.facilityID) ||
            (!props.facilityID)) {
            let pcpList = [...lists.primaryCarePhysicians];
            pcpList.push(pcp);

            setLists({
                ...lists,
                primaryCarePhysicians: pcpList
            });
        }
    }

    function handleCategoryChange(targetKeys, direction, moveKeys, itemToPush = null) {
        let ctu = [...categoriesToUpdate];

        moveKeys.forEach((key, idx) => {
            var catIdx = ctu.findIndex(r => r.id === key);
            if (catIdx >= 0) {
                ctu[catIdx].delete = direction === 'left' ? true : false
            } else {
                var newItem = {
                    id: key,
                    delete: direction === 'left' ? true : false
                };

                ctu.push(newItem);
            }
        });

        setCategoriesToUpdate(ctu);

        let fullList = [...lists.allCategories];
        if (itemToPush) {
            fullList.push(itemToPush);
        }

        setLists({
            ...lists,
            targetCategories: targetKeys,
            allCategories: fullList
        });
    };

    function closeAndUpdateCategory(category) {
        setShowAddCategory();

        let targetKeys = [...lists.targetCategories];
        targetKeys.push(category.categoryID);

        handleCategoryChange(targetKeys, 'right', [category.categoryID], category);
    }

    const filterOption = (inputValue, option) => {
        return option.fName.toLowerCase().indexOf(inputValue.toLowerCase()) > -1 || option.lName.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    };

    return (
        <SlidingPane
            overlayClassName='pinkCard'
            className={theme === 'dark' ? "dark-theme" : 'light-theme'}
            isOpen={showPane || false}
            /*title={<div style={{ height: '50px' }}><span style={{marginTop: '15px', position: 'fixed' }}>Add a Candidate</span> <button className="btn btn-submit float-right">Submit</button></div>}*/
            title={props.facilityID ? 'Manage Facility' : 'Add Facility'}
            onRequestClose={hidePane}
            shouldCloseOnEsc={false}
        >

            <div className="slide-pane-body">
                <form>

                    <div className="row">
                    </div>
                    <div className="row">
                        <div className="form-group col-auto mx-auto">
                            {facilityLogo ?
                                <img src={facilityLogo} className="w-100 mh-150" alt="Blog Post Image" />
                                :
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Facility Logo" className="m-0" />
                            }
                        </div>
                        <div className="form-group col-3 align-items-center">
                            <label htmlFor="upload-photo" className="color-pink hover">Browse...</label>
                            <input type="file" name="photo" id="upload-photo" onChange={handleLogoUpload} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-lg-9 col-12">
                            <label className="form-label">Facility Name <span className="color-pink">*</span></label>
                            <input {...register("facility.facilityName", { required: true })} className="form-control-custom" autoComplete="off" type="text" />
                        </div>

                        <div className="form-group col-lg-3 col-12">
                            <label className="form-label">Does this facility send B3 recall letters?</label>
                            <div className="form-control-custom no-border">
                                <div className="custom-control custom-checkbox custom-control-inline">
                                    <input type="checkbox" className="custom-control-input" {...register("facility.b3AutoRecallYN")} />
                                    <label className="custom-control-label"></label>
                                </div>
                            </div>
                        </div>

                        <div className="form-group col-lg-3 col-12">
                            <label className="form-label">Address 1</label>
                            <input {...register("facility.address1")} className="form-control-custom" autoComplete="off" type="text" />
                        </div>
                        <div className="form-group col-lg-3 col-12">
                            <label className="form-label">Address 2</label>
                            <input {...register("facility.address2")} className="form-control-custom" autoComplete="off" type="text" />
                        </div>
                        <div className="form-group col-lg-2 col-12">
                            <label className="form-label">City</label>
                            <input {...register("facility.city")} className="form-control-custom" autoComplete="off" type="text" />
                        </div>
                        <div className="form-group col-lg-2 col-12">
                            <label className="form-label">State</label>
                            <select className="form-control-custom" {...register("facility.state")}>
                                <option></option>
                                {states.map((state, idx) => <option key={idx} value={state.state_code}>{state.name}</option>)}
                            </select>
                        </div>

                        <div className="form-group col-lg-2 col-12">
                            <label className="form-label">ZIP</label>
                            <input {...register("facility.zip")} className="form-control-custom" autoComplete="off" type="text" />
                        </div>

                        <div className="form-group col-lg-3 col-12">
                            <label className="form-label">Phone</label>
                            <Controller
                                name="facility.phone"
                                control={control}
                                defaultValue={getValues().phone}
                                render={({ field }) => <NumberFormat {...field} className="form-control-custom" format="(###) ###-####" mask="_" onValueChange={(val) => setValue("facility.phone", val.formattedValue)} />}
                            />
                        </div>

                        <div className="form-group col-lg-3 col-12">
                            <label className="form-label">Fax</label>
                            <Controller
                                name="facility.fax"
                                control={control}
                                defaultValue={getValues().fax}
                                render={({ field }) => <NumberFormat {...field} className="form-control-custom" format="(###) ###-####" mask="_" onValueChange={(val) => setValue("facility.fax", val.formattedValue)} />}
                            />
                        </div>

                        <div className="form-group col-lg-3 col-12">
                            <label className="form-label">Email</label>
                            <input {...register("facility.email", {
                                pattern: {
                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: 'Please enter a valid email'
                                }
                            })} className="form-control-custom" />
                            {errors.email && <pre className="text-danger mb-0 ml-3">{errors.email.message}</pre>}
                        </div>

                        <div className="form-group col-3">
                            <label className="form-label">Inactive</label>
                            <div className="form-control-custom no-border">
                                <div className="custom-control custom-checkbox custom-control-inline">
                                    <input type="checkbox" className="custom-control-input" {...register("facility.inactiveYN")} />
                                    <label className="custom-control-label"></label>
                                </div>
                            </div>
                        </div>


                        <div className="form-group col-lg-3 col-12">
                            <label className="form-label">Default Technician</label>
                            <select className="form-control-custom" {...register("facility.defaultTech")}>
                                <option></option>
                                {lists.defaultUserOptions.map((tech, idx) => <option key={tech.userID} value={tech.userID}>{tech.fName} {tech.lName}</option>)}
                            </select>
                        </div>

                        <div className="form-group col-lg-3 col-12">
                            <label className="form-label">Default Exam Type</label>
                            <select className="form-control-custom" {...register("facility.defaultExamType")}>
                                <option></option>
                                {lists.allExamTypes.map((et, idx) => <option key={et.examTypeID} value={et.examTypeID}>{et.examType}</option>)}
                            </select>
                        </div>

                        <div className="form-group col-lg-3 col-12">
                            <label className="form-label">Default Radiologist</label>
                            <select className="form-control-custom" {...register("facility.defaultRadiologist")}>
                                <option></option>
                                {lists.defaultRadiologistOptions.map((rad, idx) => <option key={rad.radiologistID} value={rad.radiologistID}>{rad.fName} {rad.lName}</option>)}
                            </select>
                        </div>

                        <div className="form-group col-lg-3 col-12">
                            <label className="form-label">Issuer of Patient <span className="color-pink">*</span><i className="fa fa-plus color-pink hover icon-in-label" onClick={() => setShowAddIssuer()} /></label>
                            <select className="form-control-custom" {...register("facility.issuerofPatientID", { required: true })}>
                                <option></option>
                                {lists.allIssuers.map((i, idx) => <option key={i.issuerofPatientID} value={i.issuerofPatientID}>{i.issuerName}</option>)}
                            </select>
                        </div>

                        <div className="form-group col-lg-3 col-12">
                            <label className="form-label">Hx Form Preference</label>
                            <select className="form-control-custom" {...register("facility.hxFormPreference")}>
                                <option></option>
                                <option>Short</option>
                                <option>Long</option>
                            </select>
                        </div>

                        <div className="form-group col-lg-3 col-12">
                            <label className="form-label">Density Display Type</label>
                            <select className="form-control-custom" {...register("facility.densityDisplayType")}>
                                <option></option>
                                <option>Text</option>
                                <option>Checkbox</option>
                            </select>
                        </div>

                        <div className="form-group col-lg-6 col-12">
                            <label className="form-label">Density Verbiage</label>
                            <textarea {...register("facility.densityVerbiage")} className="form-control-custom" autoComplete="off" type="text" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col col-xl-6 col-12">
                            <hr />
                            <h5>Radiologist Mapping</h5>
                            <Transfer
                                titles={['Source', <i className="fa fa-plus color-pink hover" onClick={() => setShowAddRadiologist()} />]}
                                className="grow"
                                dataSource={lists.allRadiologists}
                                showSearch
                                filterOption={filterOption}
                                rowKey={record => record.radiologistID}
                                targetKeys={lists.targetRadiologists}
                                onChange={handleRadChange}
                                render={item => item.fName + " " + item.lName}
                                listStyle={{
                                    height: 300
                                }}
                            />
                        </div>

                        <div className="col col-xl-6 col-12">
                            <hr />
                            <h5>Provider Mapping</h5>
                            <Transfer
                                titles={['Source', <i className="fa fa-plus color-pink hover" onClick={() => setShowAddProvider()} />]}
                                className="grow"
                                dataSource={lists.allProviders}
                                showSearch
                                filterOption={filterOption}
                                rowKey={record => record.providerID}
                                targetKeys={lists.targetProviders}
                                onChange={handleProviderChange}
                                render={item => item.fName + " " + item.lName}
                                listStyle={{
                                    height: 300
                                }}
                            />
                        </div>

                        

                        <div className="col col-xl-6 col-12">
                            <hr />
                            <h5>User Mapping</h5>
                            <Transfer
                                titles={['Source', <i className="fa fa-plus color-pink hover" onClick={() => setShowAddUser()} />]}
                                className="grow"
                                dataSource={lists.allUsers}
                                showSearch
                                filterOption={filterOption}
                                rowKey={record => record.userID}
                                targetKeys={lists.targetUsers}
                                onChange={handleUserChange}
                                render={item => item.fName + " " + item.lName}
                                listStyle={{
                                    height: 300
                                }}
                            />
                        </div>

                        <div className="col col-xl-6 col-12">
                            <hr />
                            <h5>Category Mapping</h5>
                            <Transfer
                                titles={['Source', <i className="fa fa-plus color-pink hover" onClick={() => setShowAddCategory()} />]}
                                className="grow"
                                dataSource={lists.allCategories}
                                showSearch
                                filterOption={filterOption}
                                rowKey={record => record.categoryID}
                                targetKeys={lists.targetCategories}
                                onChange={handleCategoryChange}
                                render={item => item.category}
                                listStyle={{
                                    height: 300
                                }}
                            />
                        </div>

                        <div className="col col-xl-6 col-12">
                            <hr />
                            <h5>Primary Care Physician</h5>
                            <List
                                size="small"
                                header={<div className="d-flex justify-content-between"><div>{lists.primaryCarePhysicians.length} items</div><i className="fa fa-plus color-pink hover align-self-center" onClick={() => setShowAddPCP()} /></div>}
                                bordered
                                dataSource={lists.primaryCarePhysicians}
                                renderItem={item => <List.Item>{item.fName} {item.lName}</List.Item>}
                                className="h-300-list"
                                style={{width: 300}}
                            />
                        </div>
                    </div>
                </form>
            </div>

            <div className="modal-footer">
                <div className="form-group col-12 padding-25-10">
                    <button className="btn btn-submit" onClick={handleSubmit(onSubmit, onError)}>{props.facilityID ? 'Update Facility' : 'Add Facility'}</button>
                </div>
            </div>

            {showAddRadiologist && <RadiologistModal from='manageFacility' hideModal={closeAndUpdateRadiologist} />}
            {showAddProvider && <ProviderModal from='manageFacility' hideModal={closeAndUpdateProvider} />}
            {showAddUser && <ManageUser from='manageFacility' hidePane={closeAndUpdateUser} />}
            {showAddIssuer && <IssuerOfPatientModal from='manageFacility' hideModal={closeAndUpdateIssuer} />}
            {showAddPCP && <PCPModal from='manageFacility' facilityID={props.facilityID} hideModal={closeAndUpdatePCP} />}
            {showAddCategory && <CategoryModal from='manageFacility' facilityID={props.facilityID} hideModal={closeAndUpdateCategory} />}
        </SlidingPane>
    );
}

export default withRouter(ManageFacility);