import React, { useEffect } from "react";
import { Modal, Button } from 'antd';

import Globals from '../../config/globals';
import { addLogoutAuditItem } from "../../common/services/ApiService";

const { confirm, info } = Modal;
 
function DetectActivity({ children }) {
    var countDownSeconds = 5 * 60 //countdown for 5 minutes;
    var timeInMS = 1000 * 60 * 15; //set timeout for 15 minutes

    var modalOpen = false;

    useEffect(() => {
        var time;
        let mounted = true;

        window.onload = resetTimer;
        // DOM Events
        document.onload = resetTimer;
        document.onmousemove = resetTimer;
        document.onmousedown = resetTimer; // touchscreen presses
        document.ontouchstart = resetTimer;
        document.onclick = resetTimer;

        function logout() {
            countDown(children._owner);
          }

      function resetTimer() {
          clearTimeout(time);

          if (mounted && !modalOpen) {
              time = setTimeout(logout, timeInMS)
          }
        }

        return () => {
            mounted = false;
        }
  }, []);

    function countDown(self) {
        let secondsToGo = countDownSeconds;
        modalOpen = true;

        const modal = confirm({
            title: 'Are you still there?',
            content: `You will be logged out in ${secondsToTime(secondsToGo)}.`,
            cancelText: 'I am here',
            cancelType: 'success',
            okText: 'Log me out',
            okType: 'danger',
            className: 'timeout-modal',
            onOk() {
                handleLogout(self);
                Modal.destroyAll();
                clearInterval(timer);
                clearTimeout(timeout);
                modal.visible = false;
            },
            onCancel() {
                clearInterval(timer);
                modalOpen = false;
            },
        });
        const timer = setInterval(() => {
            secondsToGo -= 1;

            if (secondsToGo === 0) {
                Modal.destroyAll();
            } else {
                modal.update({
                    content: `You will be logged out in ${secondsToTime(secondsToGo)}.`,
                });
            }

        }, 1000);
        const timeout = setTimeout(() => {
            Modal.destroyAll();
            modal.visible = false;

            clearInterval(timer);

            if (modalOpen) {
                modalOpen = false;
                Modal.destroyAll();
                modal.visible = false;
                info({
                    title: 'You have been logged out.',
                    content: 'Please log back in to continue.',
                    className: 'timeout-modal',
                    onOk: () => Modal.destroyAll()
                });

                handleLogout(self);
            }
        }, secondsToGo * 1000);
    }

    function secondsToTime(e) {
        var m = Math.floor(e % 3600 / 60).toString().padStart(2, '0'),
            s = Math.floor(e % 60).toString().padStart(2, '0');

        return m + ':' + s;
    }

    function handleLogout(self) {

        let path = '/';

        addLogoutAuditItem().then(data => {
            Globals.isDirtyPage = false;
            Globals.userInfo = {};
            Globals.selectedFacility = {};
            self.memoizedProps.history.replace(path);
        });
        
    }
 
  return children;
}
 
export default DetectActivity;