import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Moment from 'moment';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

class ReportDefinitionTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            itemID: null
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.data !== prevState.data) {
            return { data: nextProps.data };
        }
        else return null;
    }

    render() {

        const { SearchBar } = Search;

        const columns = [{
            dataField: 'definitionName',
            text: 'Definition Name'
        }];

        const columnsMobile = [{
            dataField: 'definitionName',
            text: 'Definition Name'
        }];

        const pagination = {
            sizePerPage: 5,
            pageStartIndex: 1,
            alwaysShowAllBtns: true,
            sizePerPageList: [{ text: "5", value: 5 }, { text: "10", value: 10 }, { text: "50", value: 50 }, { text: "100", value: 100 }], // A numeric array is also available. the purpose of above example is custom the text
            showTotal: true
        };

        const paginationMobile = {
            sizePerPage: 5,
            pageStartIndex: 1,
            alwaysShowAllBtns: true,
            showTotal: false
        };

        const selectRow = {
            mode: 'radio',
            clickToSelect: true,
            selected: [this.state.itemID],
            style: { outline: 'solid thin navy' },
            hideSelectColumn: true
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.props.editDefinition(row);
            }
        };

        const expandRow = {
            renderer: row => (
                <div>
                    <div><strong>Name: </strong>{row.fName} + {row.lname}</div>
                    <div><strong>DOB: </strong>{row.dob}</div>
                    <div><strong>MRN #: </strong>{row.mrn}</div>
                    <div><strong>Address: </strong>{row.address1}</div>
                    <div><strong>Referring MD: </strong>{row.primaryCarePhysician}</div>
                </div>
            ),
            showExpandColumn: true
        };

        return (
            <div>



                <div className="fullTable">
                    <ToolkitProvider
                        keyField='fName'
                        data={this.state.data}
                        columns={columns}
                        search
                    >
                        {
                            props => (
                                <div>
                                    <SearchBar {...props.searchProps} />
                                    <hr />
                                    <BootstrapTable
                                        {...props.baseProps}
                                        pagination={paginationFactory(pagination)}
                                        rowEvents={rowEvents}
                                        selectRow={selectRow}
                                        hover condensed
                                    />
                                </div>
                            )
                        }
                    </ToolkitProvider>
                </div>
                <div className="mobileTable">
                    <ToolkitProvider
                        keyField='fName'
                        data={this.state.data}
                        columns={columnsMobile}
                        search
                    >
                        {
                            props => (
                                <div>
                                    <SearchBar {...props.searchProps} />
                                    <hr />
                                    <BootstrapTable
                                        {...props.baseProps}
                                        pagination={paginationFactory(paginationMobile)}
                                        rowEvents={rowEvents}
                                        expandRow={expandRow} hover striped condensed
                                    />
                                </div>
                            )
                        }
                    </ToolkitProvider>

                </div>
            </div>
        );
    }
}

export default withRouter(ReportDefinitionTable);