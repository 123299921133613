import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PatientTab from './PatientTab';
import FamilyHistoryTab from './FamilyHistoryTab';

import Moment from 'moment';
import LettersTab from './LettersTab';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import PatientExamPane from '../Exams/PatientExamPane';

import Card from 'react-bootstrap/Card';
import '../../css/card.css';
import Axios from '../../config/axios';
import Globals from '../../config/globals';

import { OverlayTrigger, Popover } from 'react-bootstrap';

import AddExamPane from '../Exams/AddExamPane';

import { Modal } from 'react-bootstrap';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { takeWhile } from 'rxjs/operators';

import { allInactiveReasons, setPatientInactive, setPatientActive, deletePatient } from '../../common/services/ApiService';

import { Collapse } from 'antd';
import { toast, confirm } from '@rickylandino/react-messages';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import PatientAttachments from './PatientAttachments';

import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import NumberFormat from 'react-number-format';


class PatientDashboard extends Component {

    static getDerivedStateFromProps(nextProps, prevState) {

        if (JSON.stringify(nextProps.location.state.selectedObject) !== JSON.stringify(prevState.selectedObject)) {


            let examHistory = nextProps.location.state.examInfo;
            let unResultedExam = false;
            let nonCompliant = false;

            var isDense = 'No';

            if (examHistory?.length > 0) {
                isDense = examHistory[0].densityModel?.isDenseYN === 1 ? 'Yes' : 'No';
            }
            
            
            for (var i = 0; i < examHistory.length; i++) {
                if (examHistory[i].patientExamModel.isExamCompleteYN === 0) {
                    unResultedExam = true;
                }

                if (examHistory[i].patientExamResultModel?.losttoFollowUp === "1") {
                    nonCompliant = true;
                }
            }

            var nowDate = new Date();
            var mwlDateString = nowDate.getFullYear() + '/' + (nowDate.getMonth() + 1) + '/' + nowDate.getDate();
            var studyDate = new Date(mwlDateString);

            let mwlTimeFormat = "MMMM d, yyyy h:mm aa";
            if (studyDate.toString().includes('00:00:00'))
                mwlTimeFormat = "MMMM d, yyyy";

            let formFields = {
                modality: '',
                facilityID: nextProps.location.state.selectedObject.patientModel.facilityID,
                studyDate
            }

            return {
                selectedObject: nextProps.location.state.selectedObject,
                patient: nextProps.location.state.selectedObject.patientModel,
                formFields,
                insurance: nextProps.location.state.selectedObject.insuranceModel,
                facility: nextProps.location.state.selectedObject.facilityModel,
                patientRecall: nextProps.location.state.selectedObject.patientRecallModel,
                examHistory,
                unResultedExam,
                nonCompliant,
                isDense,
                mwlTimeFormat,
                filteredCategoryList: nextProps.location.state.filteredCategoryList
            }

        }
        return null;
    }

    constructor(props) {
        super(props);

        var nowDate = new Date();
        this.mwlDate = nowDate.getFullYear() + '/' + (nowDate.getMonth() + 1) + '/' + nowDate.getDate();

        this.state = {
            patient: {},
            insurance: {},
            facility: {},
            patientRecall: {},
            examHistory: [],
            examDisplay: [],
            selectedPatientExam: {},
            showPatientExam: false,
            formFields: {
                modality: '',
                studyDate: new Date(this.mwlDate)
            },
            showExamTable: true,
            showAddExamPane: false,
            showUpdateHistoryModal: false,
            patientMedicalHistory: [],
            medicalHistoryLoaded: false,
            unResultedExam: false,
            nonCompliant: false,
            updateHistory: false,
            familyHistoryDirty: false,
            clickedAdd: false,
            showChangeFacilityContent: false,
            sharedFollowUpIDs: [],
            isDense: '',
            inactiveReasons: [],
            mwlTimeFormat: "MMMM d, yyyy h:mm aa",
            selectedExamID: '',
            showAttachments: false,
            filteredCategoryList: []
        }
    }

    componentDidMount() {

        

        this.getPatientMedicalHistory();

        this.alive = true;

        allInactiveReasons.pipe(takeWhile(() => this.alive)).subscribe(data => {
            if (data.length > 0) {
                let formFields = { ...this.state.formFields };
                formFields.reasonforInactiveID = data[0].reasonforInactiveID;

                this.setState({
                    inactiveReasons: data,
                    formFields
                });
            }
        });
    }

    componentWillUnmount() {
        this.alive = false;
    }

    updateExamInfo = () => {

        let examHistory = [...this.state.examHistory];

        Axios.get(`/api/GetAllPatientExamInfoByPatient`, {
            params: {
                patientID: this.state.patient.patientID
            }
        }).then(response => {
            examHistory = response.data;
            this.manipulateExamInfo(examHistory);

        }).catch(error => {
            console.log(error);
        });
    }

    manipulateExamInfo = (examHistory) => {
        let unResultedExam = false;
        let nonCompliant = false;
        let isDense = false;

        if (examHistory?.length > 0) {
            isDense = examHistory[0].densityModel?.isDenseYN === 1 ? 'Yes' : 'No';
        }

        for (var i = 0; i < examHistory.length; i++) {
            if (examHistory[i].patientExamModel.isExamCompleteYN === 0) {
                unResultedExam = true;
            }
            if (examHistory[i].patientExamResultModel?.losttoFollowUp === "1") {
                nonCompliant = true;
            }
        }

        let patientRecall = examHistory.length > 0 ? examHistory[0].patientRecall : {};
        
        this.setState({
            examHistory,
            unResultedExam,
            nonCompliant,
            isDense,
            patientRecall
        })
    }

    updatePatientInfo = () => {
        let postdata = {
            uniqueID: this.state.patient.patientID
        };

        Axios.post(`/api/GetPatientInformationByID`, postdata
        ).then(response => {
            this.setState({
                patient: response.data.patientModel,
                updatePatient: true
            }, () => {
                    this.setState({
                        updatePatient: false
                    });
            });

        }).catch(error => {
            console.log(error);
        });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === 'providerID') {
            var selectedProvider = this.state.providersList.find(prov => prov.providerID === value);
            this.setState({ selectedProvider });
        }

        this.setState({ formFields: { ...this.state.formFields, [name]: value } });

    }

    addToWorklist = () => {
        let patient = this.state.patient;

        let modalitySplit = this.state.formFields.modality.split(" ");

        let dicom_PatientModel = {
            patientID: patient.mrn,
            patientGUID: patient.patientID,
            patientsFirstName: patient.fName,
            patientsLastName: patient.lName,
            patientsBirthDate: new Date(patient.dob),
            patientsSex: 'F',
            serverPartitionGUID: patient.facilityID,
            responsibleOrganization: this.state.facility.facilityName,
            referringPhysiciansFirstName: this.state.selectedObject.providerModel && this.state.selectedObject.providerModel.fName,
            referringPhysiciansLastName: this.state.selectedObject.providerModel && this.state.selectedObject.providerModel.lName,
            sentToModalityYN: '1',
            mirth: '0',
            modality: modalitySplit[1] === 'Mammogram' ? 'MG' : 'US',
            studyDate: this.state.formFields.studyDate,
            accessionNo: null,
            status: 'SC',
            examClass: modalitySplit[0]
        }

        Axios.post(`/api/InsertDicomPatient`, dicom_PatientModel
        ).then(response => {

            this.setState({
                formFields: {},
                studyDate: new Date(this.mwlDate)
            });

            document.body.click()

            if (response.data === 'Patient was marked non-compliant and/or has an incomplete exam on file') {
                toast.error(response.data)
            } else {
                toast.success(response.data)
            }
            

        }).catch(error => {
            console.log(error);
        });
    }

    updateFacility = () => {
        let patient = this.state.patient;

        if (this.state.formFields.facilityID !== patient.facilityID) {
            let patientModel = {
                patientID: patient.patientID,
                facilityID: this.state.formFields.facilityID
            }

            Axios.post(`/api/UpdatePatientFacility`, patientModel
            ).then(response => {

                patient.facilityID = this.state.formFields.facilityID;
                patient.insuranceID = null;
                patient.providerID = null;
                patient.primaryCarePhysicianID = null;

                let facility = Globals.associatedFacilities.find(fac => fac.facilityModel.facilityID === this.state.formFields.facilityID)?.facilityModel;

                this.setState({
                    patient,
                    facility,
                    updatePatient: true,
                    showChangeFacilityContent: false
                }, () => {
                    this.setState({
                        updatePatient: false
                    });
                });

                document.body.click()

                toast.success("Patient facility has been updated");

            }).catch(error => {
                console.log(error);
            });
        }
    }

    emptyFormFields = () => {
        let formFields = {
            modality: '',
            studyDate: new Date(this.mwlDate)
        };
        this.setState({
            formFields
        });
    }

    addExam = () => {
        this.setState({
            clickedAdd: true
        }, () => {
            this.checkPatientMedicalHistory(); this.setState({
                clickedAdd: false
            });
        });

    }

    checkPatientMedicalHistory = () => {
        if (!this.state.updateHistory || (this.state.updateHistory && this.state.familyHistoryDirty)) {
            Axios.get(`/api/CheckPatientMedicalHistoryBeforeAddExam`, {
                params: {
                    patientID: this.state.patient.patientID
                }
            }).then(response => {
                var haveYouHadBreastCancer = response.data.find(item => item.medicalHistoryItemsModel.historyItemDescription === 'HaveYouHadBreastCancer');
                var haveYouHadBreastSurgery = response.data.find(item => item.medicalHistoryItemsModel.historyItemDescription === 'HaveYouHadBreastSurgery');
                var familyHistoryOfBreastCancer = response.data.find(item => item.medicalHistoryItemsModel.historyItemDescription === 'FamilyHistoryOfBreastCancer');
                var previousMammogram = response.data.find(item => item.medicalHistoryItemsModel.historyItemDescription === 'PreviousMammogram');

                if (haveYouHadBreastCancer && haveYouHadBreastSurgery && familyHistoryOfBreastCancer && previousMammogram) {
                    this.setState({
                        showAddExamPane: true
                    });
                } else {
                    window.$('[href="#tab-family"]').tab('show');
                    window.scrollTo(0, 0);
                    this.setState({ updateHistory: true });
                    this.showModal();

                }

            }).catch(error => {
                console.log(error);
            });
        }
        else {
            this.setState({
                updateHistory: false
            });
        }
    }

    getPatientMedicalHistory = () => {
        Axios.get(`/api/GetPatientMedicalHistory`, {
            params: {
                patientID: this.state.patient.patientID
            }
        }).then(response => {
            this.setState({
                patientMedicalHistory: response.data,
                medicalHistoryLoaded: true
            }, () => {
                this.setState({
                    medicalHistoryLoaded: false
                });
            })
        }).catch(error => {
            console.log(error);
        });
    }

    setFamilyHistoryDirty = (bool) => {
        if (bool !== this.state.familyHistoryDirty) {
            this.setState({
                familyHistoryDirty: bool,
                updateHistory: true
            }, () => {
                this.checkPatientMedicalHistory();
            });
        }
    }

    showModal = () => {
        const theme = window.sessionStorage.getItem("theme");

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className={theme === 'light' ? 'light-theme custom-ui' : "dark-theme custom-ui"}>
                        <h1><i className="fas fa-exclamation-circle fa-15x mr-3"></i>
                            &nbsp;Alert: Missing Patient History</h1>
                        <p>Please make sure that the <strong>Family History</strong> and <strong>Patient Breast History</strong> is filled out first.</p>
                        <button
                            onClick={() => {
                                onClose();
                            }}
                        >
                            OK
                        </button>
                    </div>
                );
            }
        });
    }

    backToSearchResults = () => {
        if (Globals.searchCriteria.patientModel) {
            Axios.post(`/api/GetPatientsByLookup`, Globals.searchCriteria
            ).then(response => {
                Globals.patients = response.data;
                Globals.loadingPatients = false;
                this.props.history.replace({
                    pathname: '/patients',
                    state: { patients: response.data }
                })

                this.props.updatePatients();



            }).catch(error => {
                console.log(error);
            });
        } else {
            Globals.patients = [];
            Globals.loadingPatients = false;
            this.props.history.replace({
                pathname: '/patients',
                state: { patients: [] }
            })
        }

    }

    showInactivePrompt = () => {

        confirm({
            title: <span><i className="fas fa-exclamation-triangle mr-3 text-warning"></i>You are about to set this patient as inactive.</span>,
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"],
            theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {
                this.setState({
                    showSetInactiveModal: true
                });
                return 0;
            } else {
                return 0;
            }
        });
    }

    setUserInactive = () => {

        let postdata = {
            patientID: this.state.patient.patientID,
            uniqueID: this.state.formFields.reasonforInactiveID
        }

        setPatientInactive(postdata).then(data => {
            if (data > 0) {
                toast.success("This patient has been set to inactive");
                let patient = { ...this.state.patient };
                patient.inactiveYN = '1';
                patient.reasonforInactiveID = this.state.formFields.reasonforInactiveID;

                this.setState({
                    showSetInactiveModal: false,
                    patient
                });
            } else {
                toast.error("Something went wrong");
            }
        })
    }

    setUserActive = () => {

        let postdata = {
            patientID: this.state.patient.patientID
        }

        setPatientActive(postdata).then(data => {
            if (data > 0) {
                toast.success("This patient has been restored");
                let patient = { ...this.state.patient };
                patient.inactiveYN = '0';

                this.setState({
                    showSetInactiveModal: false,
                    patient
                });
            } else {
                toast.error("Something went wrong");
            }
        })
    }

    showDeletePrompt = () => {
        confirm({
            title: <span><i className="fas fa-exclamation-triangle mr-3 text-danger"></i>You are about to delete this patient.</span>,
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"],
            theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {
                let postdata = {
                    patientID: this.state.patient.patientID
                }

                deletePatient(postdata).then(data => {
                    if (data > 0) {
                        toast.success("This patient has been deleted");

                        Globals.patients = [];
                        this.props.history.replace({
                            pathname: '/patients',
                            state: { patients: [] }
                        });

                    } else {
                        toast.error("Something went wrong");
                    }
                })
                return 0;
            } else {
                return 0;
            }
        });
    }

    handleMwlDateChange = (date) => {

        let mwlTimeFormat = "MMMM d, yyyy h:mm aa";
        if (date.toString().includes('00:00:00'))
            mwlTimeFormat = "MMMM d, yyyy";

        this.setState({ formFields: { ...this.state.formFields, studyDate: date }, mwlTimeFormat });
    }

    handlePrintHx = () => {

        const q = Axios.defaults.baseURL + "api/RenderHxSheet";

        var form = document.createElement("form");
        form.target = "_blank";
        form.method = "POST";
        form.action = q;
        form.style.display = "none";

        var input = document.createElement("input");
        input.type = "hidden";
        input.name = "patientID";
        input.value = this.state.patient.patientID;
        form.appendChild(input);

        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
    }

    render() {

        const { Panel } = Collapse;

        function checkboxFormatter(cell, row) {
            return (
                <div className="no-border text-center">
                    <div className="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" className="custom-control-input" value={cell} checked={cell === 1 ? true : false} readOnly />
                        <label className="custom-control-label"></label>
                    </div>
                </div>
            );
        }

        function radiologistFormatter(cell, row) {
            return (
                <span>{row.radiologistModel?.fName} {row.radiologistModel?.lName}</span>
            );
        }

        function techFormatter(cell, row) {
            return (
                <span>{row.technologistModel?.fName} {row.technologistModel?.lName}</span>
            );
        }

        function editFormatter(cell, row) {
            return (
                <i id="editIcon" className="far fa-edit fa-125x color-pink text-center"></i>
            );
        }

        function trashFormatter(cell, row) {
            return (
                <i id="deleteIcon" className="far fa-trash-alt fa-125x color-pink text-center"></i>
            );
        }

        function latestResultFormatter(cell, row) {
            return (
                <OverlayTrigger
                                trigger={"hover focus click"}
                                rootClose
                                key='top'
                                placement='bottom'
                                overlay={
                                    <Popover id='popover' className="mwlPopover">
                                        <Popover.Content>
                                            <p>This is a shared result</p>


                                        </Popover.Content>
                                        
                                    </Popover>
                                }
                >
                    <Fragment>
                        {cell} {row.isSharedResult &&
                            <i className="fas fa-asterisk text-danger" data-container="body" data-toggle="popover" data-placement="top"></i>}
                        </Fragment>
                            </OverlayTrigger>
            )
        }

        function dateFormatter(cell, row) {
            if (cell) {
                return (
                    <span>{row.documentCount > 0 && <i id="attachmentIcon" className="fas fa-paperclip fa-125x color-pink"></i>} &nbsp; {Moment(cell).utc().format('L')}</span>
                )
            }
        }


        const columns = [{
            dataField: 'patientExamModel.examDateTime',
            text: 'Exam Date',
            formatter: dateFormatter
        }, {
            dataField: 'examTypeModel.examType',
            text: 'Exam Description'
        }, {
            dataField: 'technologistModel.fName',
            text: 'Technologist',
            formatter: techFormatter
        }, {
            dataField: 'primaryBirad',
            text: 'Initial Result'
        }, {
            dataField: 'latestBirad',
            text: 'Last Result',
            formatter: latestResultFormatter
        }, {
            dataField: 'radiologistModel.fName',
            text: 'Rad',
            formatter: radiologistFormatter
        }, {
            dataField: 'patientExamResultModel.notes',
            text: 'Notes'
        }, {
            dataField: 'patientExamModel.isExamCompleteYN',
            text: 'Complete',
            formatter: checkboxFormatter
        }, {
            dataField: 'examTypeModel.categoryDescription',
            text: 'Category'
        }, {
            dataField: 'editExam',
            text: 'Edit Exam/Results',
            isDummyField: true,
            formatter: editFormatter
        }, {
            dataField: 'deleteExam',
            text: 'Delete',
            isDummyField: true,
            formatter: trashFormatter
        }];

        const sizePerPageOptionRenderer = ({
            text,
            page,
            onSizePerPageChange
        }) => (
                <li
                    key={text}
                    role="presentation"
                    className="form-control-custom hover"
                    tabIndex="-1"
                    data-page={page}
                    onMouseDown={(e) => {
                        e.preventDefault();
                        onSizePerPageChange(page);
                    }}
                    style={{ color: 'pink' }}
                >
                    <span
                        className="a hover"
                        tabIndex="-1"
                        role="menuitem"
                        data-page={page}
                        onMouseDown={(e) => {
                            e.preventDefault();
                            onSizePerPageChange(page);
                        }}
                        style={{ color: 'rgba(243, 0, 121, 1)' }}
                    >
                        {text}
                    </span>
                </li>
            );

        const pagination = {
            sizePerPage: 5,
            sizePerPageList: [{ text: "5", value: 5 }, { text: "10", value: 10 }, { text: "50", value: 50 }, { text: "100", value: 100 }], // A numeric array is also available. the purpose of above example is custom the text
            sizePerPageOptionRenderer
        };

        const selectRow = {
            mode: 'radio',
            clickToSelect: true,
            hideSelectColumn: true
        };

        const rowClasses = (row, rowIndex) => {

            if (!row.latestBirad) {
                return "highlight-row";
            }

        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {

                if (e.target.id === 'editIcon') {
                    let postdata = {
                        uniqueID: row.patientExamModel.patientExamID,
                        facilityID: row.patientExamModel.facilityID,
                        name: row.examTypeModel.examclass
                    };

                    Axios.post(`/api/GetPatientExamResults`, postdata
                    ).then(response => {

                        this.setState({
                            selectedPatientExam: row,
                            examResults: response.data.resultInfo,
                            sharedFollowUpIDs: response.data.examIDs,
                            showPatientExam: true
                        });

                    }).catch(error => {
                        console.log(error);
                    });
                }
                else if (e.target.id === 'attachmentIcon') {
                    this.setState({
                        showAttachments: true,
                        selectedExamID: row.patientExamModel.patientExamID
                    });
                }
                else if (e.target.id === 'deleteIcon') {

                    confirm({
                        title: "You are about to delete this exam and its associated results.",
                        content: "Are you sure you would like to proceed?",
                        buttons: ["Yes", "No"],
                        theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
                    }, (buttonPressed) => {
                            if (buttonPressed === 'Yes') {
                                const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

                            let postdata = {
                                uniqueID: row.patientExamModel.patientExamID,
                                userID: userInfo.userId
                            }

                            Axios.post(`/api/DeletePatientExam`, postdata
                            ).then(response => {

                                toast.success("Exam has been deleted");

                                this.updateExamInfo();

                            }).catch(error => {
                                console.log(error);
                            });

                            return 0;
                        } else {
                            return 0;
                        }
                    });
                }
            },
            onDoubleClick: (e, row, rowIndex) => {

                let postdata = {
                    uniqueID: row.patientExamModel.patientExamID,
                    facilityID: row.patientExamModel.facilityID,
                    name: row.examTypeModel.examclass
                };

                Axios.post(`/api/GetPatientExamResults`, postdata
                ).then(response => {
                    this.setState({
                        selectedPatientExam: row,
                        examResults: response.data.resultInfo,
                        sharedFollowUpIDs: response.data.examIDs,
                        showPatientExam: true
                    });

                }).catch(error => {
                    console.log(error);
                });
            }
        };

        const calculateAge = () => {
            var today = new Date();
            var birthDate = new Date(this.state.patient.dob);  // create a date object directly from `dob1` argument
            var age_now = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age_now--;
            }

            return age_now > 0 ? age_now : 'N/A';
        }

        let disableBtn = this.state.formFields.modality === '' ? true : false;

        let patientInactive = false;
        let reasonForInactive = {};
        if (this.state.patient.inactiveYN === '1') {
            patientInactive = true;
            reasonForInactive = this.state.inactiveReasons.find(i => i.reasonforInactiveID === this.state.patient.reasonforInactiveID);
        }

        const theme = window.sessionStorage.getItem("theme");

        var modalityList = [];

        this.state.filteredCategoryList.forEach(item => {
            switch (item.category) {
                case 'SCRMAM':
                    modalityList.push("Screening Mammogram");
                    break;
                case 'DXMAM':
                    modalityList.push("Diagnostic Mammogram");
                    break;
                case 'SCRUS':
                    modalityList.push("Screening Ultrasound");
                    break;
                case 'DXUS':
                    modalityList.push("Diagnostic Ultrasound");
                    break;
                default:
                    break;
            }
        });

        return (
            <div>
                {/*<div className={this.state.showExamTable ? "patientContainer" : "patientContainerBig"}>*/}
                <span className="color-pink hover" onClick={this.backToSearchResults}> <i className="fas fa-arrow-left" onClick={this.backToSearchResults}></i> Back to search results</span>
                <div>
                    <div className="row padding-top-15 ml-3 mr-3">
                        {patientInactive &&
                            <div className="col-12 mb-4">
                            <div className="alert alert-warning" role="alert">
                                This patient is currently inactive. The supplied reason is: <strong><u>{reasonForInactive?.reasonforInactive}</u></strong>
</div>
                            </div>
                        }
                        <div className="col">
                            <h1 className="">{this.state.facility.facilityName}</h1>
                        </div>
                        <div className="col text-right mr-3">

                            <OverlayTrigger
                                trigger={"click"}
                                rootClose
                                key='top1'
                                placement='bottom'
                                overlay={
                                    <Popover id='popover' className="">
                                        {this.state.showChangeFacilityContent ?
                                            <Fragment>
                                                <Popover.Title as="h3">
                                                    Change Facility
                                            </Popover.Title>
                                                <Popover.Content>
                                                    <div className="form-group col-12">
                                                        <label className="form-label">Facility</label>
                                                        <select className="form-control-custom" value={this.state.formFields.facilityID} name="facilityID" onChange={this.handleInputChange}>
                                                            
                                                            {Globals.associatedFacilities.map((fac, idx) => <option key={idx} value={fac.facilityModel.facilityID}>{fac.facilityModel.facilityName}</option>)}
                                                        </select>
                                                    </div>
                                                </Popover.Content>

                                                <div className="form-group col-12">
                                                    <button className="btn btn-submit" onClick={this.updateFacility}>Save</button>
                                                    <button className="btn btn-outline-default margin-left-15" onClick={() => { this.setState({ showChangeFacilityContent: false }); document.body.click() }}>Cancel</button>
                                                </div>
                                            </Fragment>
                                            :
                                            <Fragment>
                                                <Popover.Title as="h3">
                                                    <i className="fas fa-exclamation-circle color-pink fa-15x mr-3"></i>
                                            Alert
                                            </Popover.Title>
                                                <Popover.Content>
                                                    Changing the facility will remove the associated insurance, referring MD, and PCP. Click OK to Proceed.
                                        </Popover.Content>

                                                <div className="form-group col-12">
                                                    <button className="btn btn-submit" onClick={() => { this.setState({ showChangeFacilityContent: true }); }}>OK</button>
                                                    <button className="btn btn-outline-default margin-left-15" onClick={() => { this.setState({ showChangeFacilityContent: false }); document.body.click() }}>Cancel</button>
                                                    
                                                </div>
                                            </Fragment>
                                        }
                                    </Popover>
                                }
                            >
                                <button type="button" className="btn btn-outline-default" data-container="body" data-toggle="popover" data-placement="top">
                                    <i className="fas fa-location-arrow fa-125x color-pink text-center" id="changeFacility"></i> Change Facility
                                    </button>
                                
                            </OverlayTrigger>

                            {/*<button className="btn btn-outline-default margin-left-15" onClick={downloadEncryptedFile}>download</button>*/}

                            <button className="btn btn-outline-default margin-left-15" onClick={this.handlePrintHx}>Print Hx Sheet</button>

                            <OverlayTrigger
                                trigger={"click"}
                                rootClose
                                key='top'
                                placement='bottom'
                                onExit={this.emptyFormFields}
                                overlay={
                                    <Popover id='popover' className="mwlPopover">
                                        <Popover.Title as="h3">Select a Modality</Popover.Title>
                                        <Popover.Content>
                                            <div>
                                                {modalityList.map((mod, idx) => (
                                                    <div className="custom-control custom-radio" key={idx}>
                                                        <input type="radio" name="modality" className="custom-control-input" value={mod} onChange={this.handleInputChange} />
                                                        <span className="custom-control-label">{mod}</span>
                                                    </div>
                                                ))}
                                                {/*<div className="custom-control custom-radio">*/}
                                                {/*    <input type="radio" name="modality" className="custom-control-input" value="Screening Mammogram" onChange={this.handleInputChange} />*/}
                                                {/*    <span className="custom-control-label">Screening Mammogram</span>*/}
                                                {/*</div>*/}
                                                {/*<div className="custom-control custom-radio">*/}
                                                {/*    <input type="radio" name="modality" className="custom-control-input" value="Diagnostic Mammogram" onChange={this.handleInputChange} />*/}
                                                {/*    <span className="custom-control-label">Diagnostic Mammogram</span>*/}
                                                {/*</div>*/}
                                                {/*<div className="custom-control custom-radio">*/}
                                                {/*    <input type="radio" name="modality" className="custom-control-input" value="Screening Ultrasound" onChange={this.handleInputChange} />*/}
                                                {/*    <span className="custom-control-label">Screening Ultrasound</span>*/}
                                                {/*</div>*/}
                                                {/*<div className="custom-control custom-radio">*/}
                                                {/*    <input type="radio" name="modality" className="custom-control-input" value="Diagnostic Ultrasound" onChange={this.handleInputChange} />*/}
                                                {/*    <span className="custom-control-label">Diagnostic Ultrasound</span>*/}
                                                {/*</div>*/}
                                            </div>

                                            <hr />

                                            <div>
                                                <div className="form-label">Study Date</div>
                                                <DatePicker
                                                    showTimeSelect
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    dateFormat={this.state.mwlTimeFormat}
                                                    className="form-control-custom"
                                                    onChange={(date) => this.handleMwlDateChange(date)}
                                                    selected={this.state.formFields.studyDate}
                                                    dateFormat="MM/dd/yyyy hh:mm aa"
                                                    timeIntervals={15}
                                                    customInput={
                                                        <NumberFormat format="##/##/####" mask="_" />
                                                    }
                                                />
                                            </div>
                                        </Popover.Content>
                                        <div className="form-group col-12">
                                            {disableBtn ?
                                                <span className="d-inline-block" tabIndex="0" data-toggle="tooltip" title="Select a modality">
                                                    <button className="btn btn-light" disabled>Add to MWL</button>
                                                </span>
                                                :
                                                <button className="btn btn-submit" onClick={this.addToWorklist}>Add to MWL</button>
                                            }

                                            <button className="btn btn-outline-default margin-left-15" onClick={() => { document.body.click() }}>Cancel</button>
                                        </div>
                                    </Popover>
                                }
                            >
                                <button type="button" className="btn btn-outline-default margin-left-15" data-container="body" data-toggle="popover" data-placement="top">
                                    <i className="fas fa-share-alt fa-125x color-pink text-center" id="addToMWL"></i> Add to Worklist
                                    </button>

                            </OverlayTrigger>

                        </div>
                    </div>

                    <div className="row ml-3 mr-3">
                        <div className="col-md-4 padding-top-15">

                            <div className="row">
                                <div className="col-md-6">
                                    <h3><strong>{this.state.patient.fName} {this.state.patient.lName}</strong></h3>
                                </div>
                                <div className="col-md-6">
                                    <h3><strong>{this.state.patient.mrn}</strong></h3>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <h3><strong>DOB: {this.state.patient.dob ? Moment(this.state.patient.dob).utc().format('L') : null}</strong></h3>
                                </div>
                                <div className="col-md-6">
                                    <h3><strong>Age: {calculateAge()}</strong></h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 padding-top-15">
                            <div>


                            </div>
                            <div className="row margin-10-0">
                                <div className="col-6">
                                    <h5>
                                        Primary Recall: <u>{(this.state.patientRecall && this.state.patientRecall.recallDate) ? Moment(this.state.patientRecall.recallDate).utc().format('L') : 'N/A'}</u>
                                    </h5>
                                    {this.state.patientRecall?.secondaryRecallDate &&
                                        <h5>
                                            Secondary Recall: <u>{Moment(this.state.patientRecall.secondaryRecallDate).utc().format('L')}</u>
                                        </h5>
                                    }
                                </div>
                                <div className="col-6">
                                    <h5>
                                        Next Appt: <u>{this.state.patient.nextApptDate ? Moment(this.state.patient.nextApptDate).format('L') : null}</u>
                                    </h5>
                                    <div className="row">
                                        <div className="col-4">
                                            <h5>
                                                Dense: <u>{this.state.isDense}</u>
                                            </h5>
                                        </div>
                                        <div className="col-4">
                                            <h5>
                                                Risk: <u>{this.state.patient.patientDense === 1 ? 'High' : 'Low'}</u>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 padding-top-15 flag-scroll-div">
                            {this.state.unResultedExam &&
                                <h5>
                                    <i className="fas fa-exclamation-triangle color-pink"></i>&nbsp;
                                    Incomplete/Open Exam!
                                </h5>
                            }
                            {this.state.nonCompliant &&
                                <h5>
                                    <i className="fas fa-exclamation-triangle color-pink"></i>&nbsp;
                                    Non-Compliant
                                </h5>
                            }
                            {this.state.patientMedicalHistory.length > 0 && this.state.patientMedicalHistory.map((item, idx) => (
                                <Fragment key={idx}>
                                    {(item.patientMedicalHistoryModel && item.patientMedicalHistoryModel.flagYN === 1) &&
                                        <h5>
                                            <i className="fas fa-flag color-pink"></i>&nbsp;&nbsp;
                                            {item.medicalHistoryItemsModel.flagDescription}

                                        </h5>

                                    }
                                </Fragment>
                            ))}
                        </div>
                        <div className="horizontal-clients-divider" />
                    </div>

                    <ul className="nav nav-tabs" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active fs-lg px-4" data-toggle="tab" href="#tab-patient" role="tab">
                                <i className="fal fa-user text-primary"></i>
                                <span className="hidden-sm-down ml-1">Patient</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link fs-lg px-4" data-toggle="tab" href="#tab-family" role="tab">
                                <i className="fal fa-history text-primary"></i>
                                <span className="hidden-sm-down ml-1">History</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link fs-lg px-4" data-toggle="tab" href="#tab-letters" role="tab">
                                <i className="far fa-envelope-open text-primary"></i>
                                <span className="hidden-sm-down ml-1">Letters & Forms</span>
                            </a>
                        </li>
                    </ul>

                    <div className="tab-content p-3">
                        <div className="tab-pane fade show active" id="tab-patient" role="tabpanel" aria-labelledby="tab-patient">
                            <PatientTab {...this.props} patient={this.state.patient} updatePatientInfo={this.updatePatientInfo} updatePatient={this.state.updatePatient} facility={this.state.facility} />
                        </div>
                        <div className="tab-pane fade" id="tab-family" role="tabpanel" aria-labelledby="tab-family">
                            <FamilyHistoryTab patient={this.state.patient} patientMedicalHistory={this.state.patientMedicalHistory} medicalHistoryLoaded={this.state.medicalHistoryLoaded}
                                getPatientMedicalHistory={this.getPatientMedicalHistory} setUpdateHistory={() => this.setState({ updateHistory: !this.state.updateHistory })}
                                updateHistory={this.state.updateHistory} checkPatientMedicalHistory={this.checkPatientMedicalHistory} familyHistoryDirty={(bool) => this.setFamilyHistoryDirty(bool)}
                                clickedAdd={this.state.clickedAdd} updateClickedAdd={() => this.setState({ clickedAdd: false })} />
                        </div>
                        <div className="tab-pane fade" id="tab-letters" role="tabpanel" aria-labelledby="tab-letters">
                            <LettersTab {...this.props} patient={this.state.patient} />
                            <PatientAttachments {...this.props} patient={this.state.patient} level="Patient" />
                        </div>
                    </div>

                    <div className="tab-content p-3">
                        <Card className="pinkCard">
                            <Card.Header>
                                <span>Exams</span>
                                <button className="ml-3 btn btn-submit" onClick={this.addExam}>Add Exam</button>
                                {this.state.showExamTable ?
                                    <i className="fas fa-chevron-up color-pink float-right hover mr-3 mt-1" onClick={() => this.setState({ showExamTable: !this.state.showExamTable })}></i>
                                    :
                                    <i className="fas fa-chevron-down color-pink float-right hover mr-3 mt-1" onClick={() => this.setState({ showExamTable: !this.state.showExamTable })}></i>
                                }
                            </Card.Header>
                            {this.state.showExamTable &&
                                <Card.Body>

                                    {this.state.examHistory.length > 0 ?
                                        <div className="fullTable">
                                            <BootstrapTable keyField='patientExamModel.patientExamID'
                                                data={this.state.examHistory}
                                                columns={columns}
                                                pagination={paginationFactory(pagination)}
                                                rowEvents={rowEvents}
                                                selectRow={selectRow}
                                            
                                            rowClasses={rowClasses}
                                                hover condensed />
                                        </div>
                                        :
                                        <em>No exams associated to this patient</em>
                                    }

                                </Card.Body>
                            }
                        </Card>
                    </div>

                    <div className="tab-content p-3 row">
                        <hr />
                        <div className="col-md-6 col-12">
                        {patientInactive ?
                            <button className="btn alert-info" onClick={this.setUserActive}>Restore this patient</button>
                            :
                            <button className="btn btn-warning" onClick={this.showInactivePrompt}>Set this patient Inactive</button>
                            }
                        </div>
                        <div className="col-md-6 col-12">
                        <Collapse className="float-right border-danger alert-danger">
                            <Panel header="Display delete patient button" key="1">
                                    <button className="btn btn-danger" onClick={this.showDeletePrompt}>Delete this patient</button>
                            </Panel>
                            </Collapse>
                        </div>
                    </div>

                </div>
                {this.state.showPatientExam && <PatientExamPane showPane={this.state.showPatientExam} examInfo={this.state.selectedPatientExam} examHistory={this.state.examResults} facility={this.state.facility} updateExamInfo={(examHistory) => this.manipulateExamInfo(examHistory)} from='general' hidePane={() => this.setState({ showPatientExam: false, updateHistory: false })} sharedFollowUpIDs={this.state.sharedFollowUpIDs} />}
                {this.state.showAddExamPane && <AddExamPane showPane={this.state.showAddExamPane} examHistory={this.state.examHistory} hidePane={() => this.setState({ showAddExamPane: false, updateHistory: false })} patient={this.state.patient} updatePatientInfo={this.updatePatientInfo} facility={this.state.facility} updateExamInfo={this.updateExamInfo} checkPatientMedicalHistory={this.checkPatientMedicalHistory} />}

                <Modal size='lg' show={this.state.showUpdateHistoryModal} onHide={() => this.setState({ showUpdateHistoryModal: false })} backdrop='static' className={theme === 'dark' ? "dark-theme modal-popup" : 'light-theme modal-popup'}>
                    <Modal.Header>
                        <i className="fas fa-exclamation-triangle color-pink fa-15x mr-3"></i>
                        <Modal.Title>Alert: Missing Patient History</Modal.Title>
                        <span className="a hover alignRight close" id="closeModal" onClick={() => this.setState({ showUpdateHistoryModal: false })}>&times;</span>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="panel-content">
                            Please make sure that the <strong>Family History</strong> and <strong>Patient Breast History</strong> is filled out first.
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" onClick={(e) => {
                            e.preventDefault(); this.setState({ showUpdateHistoryModal: false }, () => { window.scrollTo(0, 0); });
                        }} className="btn btn-submit">
                            OK
                        </button>

                    </Modal.Footer>
                </Modal>

                <Modal size='md' show={this.state.showSetInactiveModal} onHide={() => this.setState({ showSetInactiveModal: false })} backdrop='static' className={theme === 'dark' ? "dark-theme modal-popup" : 'light-theme modal-popup'} centered>
                    <Modal.Header>
                        <i className="fas fa-exclamation-triangle color-pink fa-15x mr-3"></i>
                        <Modal.Title>Select a reason</Modal.Title>
                        <span className="a hover alignRight close" id="closeModal" onClick={() => this.setState({ showSetInactiveModal: false })}>&times;</span>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="panel-content">
                            <div className="form-group">
                                <label className="form-label">Reason for Inactivity</label>
                                <select className="form-control-custom" value={this.state.formFields.reasonforInactiveID || ''} name="reasonforInactiveID" onChange={this.handleInputChange}>
                                    
                                    {this.state.inactiveReasons.map((item, idx) => <option key={idx} value={item.reasonforInactiveID}>{item.reasonforInactive}</option>)}

                                </select>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" onClick={this.setUserInactive} className="btn btn-submit">
                            Submit
                        </button>

                    </Modal.Footer>
                </Modal>

                <SlidingPane
                    className='some-custom-class'
                    className={theme === 'light' ? "light-theme" : 'dark-theme'}
                    overlayClassName='pinkCard'
                    isOpen={this.state.showAttachments}
                    title='Exam Attachments'
                    onRequestClose={() => this.setState({showAttachments: false})}
                >
                    <div className="slide-pane-body">
                        <PatientAttachments {...this.props} examID={this.state.selectedExamID} level="Exam" hidePane={() => this.setState({ showAttachments: false })} />
                    </div>
                </SlidingPane>
            </div>
        );
    }
}

export default withRouter(PatientDashboard);