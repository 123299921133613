import Axios from '../../config/axios';

import { BehaviorSubject } from 'rxjs';
import Globals from '../../config/globals';

export function insertPatientOrExamDocument(file, item) {
    let formdata = new FormData();
    formdata.append('file', file);
    formdata.append('level', item.documentLevel);
    formdata.append('documentName', item.documentName);
    formdata.append('documentType', item.documentType);
    formdata.append('notes', item.notes);
    formdata.append('entityID', item.entityID);

    return Axios.post('/api/InsertPatientOrExamDocument', formdata).then(response => response.data);
}

export function getPatientDocuments(patientID) {
    return Axios.get('/api/GetPatientDocuments', {
        params: {
            patientID
        }
    }).then(response => response.data);
}

export function getExamDocuments(examID) {
    return Axios.get('/api/GetExamDocuments', {
        params: {
            examID
        }
    }).then(response => response.data);
}

export function deleteDocument(document) {
    return Axios.post('/api/deleteDocument', document).then(response => response.data);
}