import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import '../../css/card.css';
import { Fragment } from 'react';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';
import NumberFormat from 'react-number-format';

const showRiskGrades = (findingID, birad, biradsFindings) => {
    const finding = biradsFindings[birad] && biradsFindings[birad].find(f => f.findingID === findingID);

    return finding?.riskGradeYN;
}

export class FollowUpResultCard extends Component {

    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.readOnly !== prevState.readOnly) {
            return { readOnly: nextProps.readOnly }
        }
        return null;
    }

    constructor(props) {
        super(props);

        this.state = {
            examHistory: props.examHistory,
            readOnly: props.readOnly,
            recallableDisabled: props.recallableDisabled,
            biradsList: props.biradsList,
            biradsFindings: props.biradsFindings,
            allBiopsyTypes: props.allBiopsyTypes,
            riskGrades: props.riskGrades,
            recommendationsList: props.recommendationsList,
            recallTypesList: props.recallTypesList,
            biradRecommendations: props.biradRecommendations,
            newBiradRecommendations: props.newBiradRecommendations,
            findingsList: props.findingsList,
            examInfo: props.examInfo,
            primaryResultDate: props.primaryResultDate,
            formFields: props.formFields
        }

    }

    handleExamHistoryChange = (event, i) => {
        const target = event.target;
        var value = null;
        const name = target.name;

        var result = this.state.examHistory[i];
        let formFields = this.state.formFields;
        let ffDirty = true;

        let formFieldsTouched = false;

        //var result = history.patientExamResultModel;

        if (target.type === 'checkbox') {
            if (name === 'losttoFollowUp') {
                value = target.checked ? "1" : "0";
                if (value === '1') {
                    formFields.isExamCompleteYN = 1;
                } else {
                    formFields.isExamCompleteYN = 0;
                }
                formFieldsTouched = true;
            }
            else {
                value = target.checked ? 1 : 0;
            }

        }
        else {

            this.isDirty = true;  // dirty

            switch (target.name) {
                case 'birad':
                    let bir = this.state.biradsList.find(birad => birad?.birads === target.value);

                    const findingsList = bir ? this.state.biradsFindings[bir.birads] : [];

                    result.findingID = '';

                    const recommendationsList = this.state.biradRecommendations[bir.birads];
                    const newRecommendationsAddList = bir ? this.state.newBiradRecommendations[bir.birads] : [];

                    var recommendationID = null;

                    newRecommendationsAddList.forEach(rec => {
                        if (rec.biradRecommendation?.defaultYN) {
                            recommendationID = rec.biradRecommendation.recommendationID;
                        }
                    });

                    let r1 = recommendationsList.find(rec => rec.recommendationID === recommendationID);

                    var recallTypeID = (r1 && !this.state.recallableDisabled) ? r1.defaultRecallTypeID : null;
                    var daysUntilRecall = (r1 && !this.state.recallableDisabled) ? r1.daysUntilRecall : null;

                    if (recallTypeID)
                        result.recallTypeID = recallTypeID;
                    else
                        result.recallTypeID = '00000000-0000-0000-0000-000000000000';

                    if (daysUntilRecall) {
                        var recallDate = Moment(new Date(this.state.examInfo.patientExamModel.examDateTime)).add(daysUntilRecall, 'day').toDate();
                        result.recallDate = recallDate;

                    } else {
                        result.recallDate = null;
                    }

                    if (recommendationID)
                        result.recommendationID = recommendationID;
                    else
                        result.recommendationID = '';
                    var isDefaultComplete = bir ? bir.examAutoCompleteYN : null;

                    if (isDefaultComplete) {
                        formFields.isExamCompleteYN = 1;
                    } else {
                        formFields.isExamCompleteYN = 0;
                    }
                    formFieldsTouched = true;

                    if (bir?.birads === 'B4' || bir?.birads === 'B5') {
                        result.showBiopsyRefused = true;
                    } else {
                        result.showBiopsyRefused = false;
                    }

                    this.setState({
                        recommendationsList,
                        findingsList
                    });

                    ffDirty = true;

                    break;
                case 'recommendationID':

                    let recommend = this.state.recommendationsList.find(rec => rec.recommendationID === target.value);

                    var recallTypeID = (recommend && !this.state.recallableDisabled) ? recommend.defaultRecallTypeID : '';
                    var daysUntilRecall = (recommend && !this.state.recallableDisabled) ? recommend.daysUntilRecall : null;


                    if (recallTypeID)
                        result.recallTypeID = recallTypeID;

                    if (daysUntilRecall) {
                        var recallDate = Moment(this.state.examInfo.patientExamModel.examDateTime).add(daysUntilRecall, 'day').toDate();
                        result.recallDate = recallDate;

                    }

                    break;
                default:
                    break;

            }

            value = target.value;

        }

        var missingFields = false;


        if (name !== 'closedforDxExam' ||
            name !== 'losttoFollowUp' ||
            name !== 'notes') {
            if (value === null || value === '' || !value) {
                missingFields = true;
            }
        }

        result[name] = value;
        result["isDirty"] = true;

        let missingRecallDate = false;
        let pastRecallDate = false;

        if (!result.resultDate ||
            !result.radiologistID ||
            !result.birad ||
            !result.recommendationID || result.recommendationID === '' || result.recommendationID === '00000000-0000-0000-0000-000000000000' ||
            !result.recallTypeID || result.recallTypeID === '' || result.recallTypeID === '00000000-0000-0000-0000-000000000000' ||
            ((!result.findingID || result.findingID === '' || result.findingID === '00000000-0000-0000-0000-000000000000') && this.state.findingsList?.length > 0) ||
            (showRiskGrades(result.findingID, result.birad, this.state.biradsFindings) && (!result.riskGradeID || result.riskGradeID === '' || result.riskGradeID === '00000000-0000-0000-0000-000000000000')) ||
            ((result.birad === 'NB' || result.birad === 'PB' ) && (!result.biopsyTypeID || result.biopsyTypeID === '' || result.biopsyTypeID === '00000000-0000-0000-0000-000000000000'))) {

            missingFields = true;
        } else {

            missingFields = false;
        }

        if (!result.recallDate) {
            missingRecallDate = true;
        }

        if (result.recallDate && Moment(result.recallDate).isBefore(Moment(), "day")) {
            pastRecallDate = true;
        }

        const examHistory = [...this.state.examHistory];
        examHistory[i] = result;

        this.setState({
            examHistory,
            formFields,
            ffDirty,
            missingFields,
            missingRecallDate
        });

        this.props.updateFollowUpInfo(examHistory, formFields, formFieldsTouched, ffDirty, missingFields, missingRecallDate, pastRecallDate);
    }

    handleExamHistoryDateChange = (date, dateName, i) => {

        var result = this.state.examHistory[i];

        let missingRecallDate = false;
        let pastRecallDate = false;

        if ((result?.birads === 'B1' || result?.birads === 'B2') && dateName === 'recallDate' && !date) {
            this.setState({
                missingRecallDate: true
            });
            missingRecallDate = true;
        }

        if (result.recallDate && Moment(result.recallDate).isBefore(Moment(), "day")) {
            pastRecallDate = true;
        }

        result[dateName] = date;
        result["isDirty"] = true;

        const examHistory = [...this.state.examHistory];
        examHistory[i] = result;

        this.isDirty = true;

        this.setState({
            examHistory,
            ffDirty: true
        });

        this.props.updateHistoryDateInfo(examHistory, true, missingRecallDate, pastRecallDate);
    }

    render() {
        const history = this.props.historyItem;

        const i = this.props.idx;

        const showRiskGrades = (findingID, birad) => {
            const finding = this.state.biradsFindings[birad] && this.state.biradsFindings[birad].find(f => f.findingID === findingID);

            return finding?.riskGradeYN;
        }

        const getRiskGrades = (findingID) => {
            return this.state.riskGrades[findingID];
        }

        const showBiopsyTypes = (birad) => {
            return birad === 'NB' || birad === 'PB' ? true : false;
        }

        const pbSelected = (birad) => {
            return birad === 'PB' ? true : false;
        }

        return (
            <Card className="pinkCard">
                <Card.Header><span>Follow Up Result {i}</span>
                    <i id="deleteIcon" className="far fa-trash-alt fa-125x color-pink float-right hover" onClick={(e) => this.props.deleteResult(e, i)}></i>
                </Card.Header>
                <Card.Body>

                    <div className="row">
                        <div className="form-group col-lg-3">
                            <label className="form-label">Result Date <span className="color-pink">*</span></label>
                            <DatePicker
                                selected={history.resultDate && Moment(history.resultDate).toDate()}
                                onChange={(date, dateName, index) => this.handleExamHistoryDateChange(date, "resultDate", i)}
                                className="form-control-custom"
                                readOnly={this.state.readOnly}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                customInput={
                                    <NumberFormat format="##/##/####" mask="_" />
                                }
                            />
                        </div>

                        <div className="form-group col-lg-2">
                            <label className="form-label">BIRADS/Results <span className="color-pink">*</span></label>
                            <select className="form-control-custom" name="birad" value={history.birad || ''} onChange={(e) => this.handleExamHistoryChange(e, i)} disabled={this.state.readOnly}>
                                <option />
                                {this.state.biradsList.map((birad, idx) => <option key={idx} value={birad.birads}>{birad.description}</option>)}

                            </select>
                        </div>

                        <div className="form-group col-lg-2">
                            {this.state.biradsFindings[history.birad] &&
                                <Fragment>
                                    <label className="form-label">Finding <span className="color-pink">*</span></label>
                                <select className="form-control-custom" name="findingID" value={history.findingID || ''} onChange={(e) => this.handleExamHistoryChange(e, i)} disabled={this.state.readOnly}>
                                        <option />
                                        {this.state.biradsFindings[history.birad].map((f, idx) => <option key={idx} value={f.findingID}>{f.findingDescription}</option>)}
                                    </select>
                                </Fragment>
                            }
                        </div>

                        <div className="form-group col-lg-2">
                            {showRiskGrades(history.findingID, history.birad) &&

                                <Fragment>
                                    {getRiskGrades(history.findingID)?.length === 1 ?
                                        <Fragment>
                                        <label className="form-label">Risk/Grade <span className="color-pink">*</span></label>
                                            <input className="form-control-custom no-border" value={getRiskGrades(history.findingID)[0]?.riskGradeDescription} readOnly />
                                        </Fragment>
                                        :
                                        <Fragment>
                                        <label className="form-label">Risk/Grade <span className="color-pink">*</span></label>
                                        <select className="form-control-custom" value={history.riskGradeID || ''} name="riskGradeID" onChange={(e) => this.handleExamHistoryChange(e, i)} disabled={this.state.readOnly}>
                                                <option />
                                                {getRiskGrades(history.findingID).map((rg, idx) => <option key={idx} value={rg.riskGradeID}>{rg.riskGradeDescription}</option>)}
                                            </select>
                                        </Fragment>
                                    }
                                </Fragment>
                            }
                        </div>

                        <div className="col-lg-2">
                            {showBiopsyTypes(history.birad) &&
                                <Fragment>
                                <label className="form-label">Biopsy Type <span className="color-pink">*</span></label>
                                <select className="form-control-custom" value={history.biopsyTypeID || ''} name="biopsyTypeID" onChange={(e) => this.handleExamHistoryChange(e, i)} disabled={this.state.readOnly}>
                                        <option />
                                        {this.state.allBiopsyTypes.map((b, idx) => <option key={idx} value={b.biopsyTypeID}>{b.biopsyTypeDescription}</option>)}
                                    </select>
                                </Fragment>
                            }
                        </div>

                        

                        <div className="col-lg-2">
                            {history.showBiopsyRefused &&
                                <Fragment>
                                    <label className="form-label">Biopsy refused/not performed</label>
                                    <div className="form-control-custom no-border">
                                        <div className="custom-control custom-checkbox custom-control-inline">
                                            <input type="checkbox" className="custom-control-input" id="" name="" disabled={this.state.readOnly} />
                                            <label className="custom-control-label"></label>
                                        </div>
                                    </div>
                                </Fragment>
                            }
                        </div>
                        <div className="form-group col-lg-4">
                            <label className="form-label">Recommendation <span className="color-pink">*</span></label>
                            <select className="form-control-custom" value={history.recommendationID || ''} name="recommendationID" onChange={(e) => this.handleExamHistoryChange(e, i)} disabled={this.state.readOnly}>
                                <option />
                                {this.state.recommendationsList.map((rec, idx) => <option key={idx} value={rec.recommendationID}>{rec.description}</option>)}

                            </select>
                        </div>


                        <div className="form-group col-lg-3">
                            <label className="form-label">Recall Type <span className="color-pink">*</span></label>
                            <select className="form-control-custom" value={history.recallTypeID || ''} name="recallTypeID" onChange={(e) => this.handleExamHistoryChange(e, i)} readOnly={this.state.readOnly} disabled={this.state.recallableDisabled || this.state.readOnly}>
                                <option />
                                {this.state.recallTypesList.map((type, idx) => <option key={idx} value={type.recallTypeID}>{type.recallTypeDescription}</option>)}

                            </select>
                        </div>

                        <div className="form-group col-lg-3">
                            <label className="form-label">Recall Date <span className="color-pink">*</span></label>
                            <DatePicker
                                selected={history.recallDate ? Moment(history.recallDate).toDate() : null}
                                onChange={(date, dateName, index) => this.handleExamHistoryDateChange(date, "recallDate", i)}
                                className="form-control-custom"
                                readOnly={this.state.readOnly}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                disabled={this.state.recallableDisabled}
                                customInput={
                                    <NumberFormat format="##/##/####" mask="_" />
                                }
                            />
                        </div>

                        {(history.birad === 'B0' || history.birad === 'B3' || history.birad === 'B4' || history.birad === 'B5') &&
                            <div className="col-lg-2">
                                <label className="form-label">Non-Compliant</label>
                                <div className="form-control-custom no-border">
                                    <div className="custom-control custom-checkbox custom-control-inline">
                                        <input type="checkbox" className="custom-control-input" id="losttoFollowUp" name="losttoFollowUp" value={history.losttoFollowUp || ''} onChange={(e) => this.handleExamHistoryChange(e, i)} checked={history.losttoFollowUp === "1" ? true : false} disabled={this.state.readOnly} />
                                        <label className="custom-control-label"></label>
                                    </div>
                                </div>
                            </div>
                        }

                        {pbSelected(history.birad) &&
                            <div className="col-12">
                                <fieldset>
                                    <legend>&nbsp;Biopsy Details&nbsp;</legend>
                                    <div className="row">
                                        <div className="form-group col col-lg-3 col-sm-4 col-12">
                                            <label className="form-label">ER</label>
                                            <select className="form-control-custom" value={history.erReceptor || ''} name="erReceptor" onChange={(e) => this.handleExamHistoryChange(e, i)} disabled={this.state.readOnly}>
                                                <option />
                                                <option>Positive</option>
                                                <option>Negative</option>
                                            </select>
                                        </div>
                                        <div className="form-group col col-lg-3 col-sm-4 col-12">
                                            <label className="form-label">PR</label>
                                            <select className="form-control-custom" value={history.prReceptor || ''} name="prReceptor" onChange={(e) => this.handleExamHistoryChange(e, i)} disabled={this.state.readOnly}>
                                                <option />
                                                <option>Positive</option>
                                                <option>Negative</option>
                                            </select>
                                        </div>
                                        <div className="form-group col col-lg-3 col-sm-4 col-12">
                                            <label className="form-label">Her2/Neu</label>
                                            <select className="form-control-custom" value={history.her2NeuReceptor || ''} name="her2NeuReceptor" onChange={(e) => this.handleExamHistoryChange(e, i)} disabled={this.state.readOnly}>
                                                <option />
                                                <option>Positive</option>
                                                <option>Negative</option>
                                                <option>Indeterminate</option>
                                            </select>
                                        </div>
                                        <div className="form-group col col-lg-3 col-sm-4 col-12">
                                            {history.her2NeuReceptor === 'Indeterminate' &&
                                                <Fragment>
                                                    <label className="form-label">FISH</label>
                                                    <select className="form-control-custom" value={history.fishReceptor || ''} name="fishReceptor" onChange={(e) => this.handleExamHistoryChange(e, i)} disabled={this.state.readOnly}>
                                                        <option />
                                                        <option>Positive</option>
                                                        <option>Negative</option>
                                                    </select>
                                                </Fragment>
                                            }
                                        </div>
                                        <div className="form-group col-lg-2 col-sm-4 col-12">
                                            <label className="form-label">Size of Finding (cm)</label>
                                            <input type="number" name="sizeofFinding" value={history.sizeofFinding || ''} onChange={(e) => this.handleExamHistoryChange(e, i)} disabled={this.state.readOnly} className="form-control-custom" />
                                        </div>
                                        <div className="col col-lg-2 col-sm-4 col-12">
                                            <label className="form-label">Axillary Node Status</label>
                                            <select className="form-control-custom" value={history.axillaryNodeStatus || ''} name="axillaryNodeStatus" onChange={(e) => this.handleExamHistoryChange(e, i)} disabled={this.state.readOnly}>
                                                <option />
                                                <option>Positive</option>
                                                <option>Negative</option>
                                            </select>
                                        </div>
                                        <div className="col col-lg-3 col-sm-4 col-12">
                                            {history.axillaryNodeStatus === 'Positive' &&
                                                <Fragment>
                                                    <label className="form-label"># of Axillary Nodes Dissected</label>
                                                    <input type="number" name="numberofAxillaryNodes" value={history.numberofAxillaryNodes || ''} onChange={(e) => this.handleExamHistoryChange(e, i)} disabled={this.state.readOnly} className="form-control-custom" />
                                                </Fragment>
                                            }
                                        </div>
                                        <div className="col col-lg-2 col-sm-4 col-12">
                                            <label className="form-label">Stage</label>
                                            <select className="form-control-custom" value={history.stage || ''} name="stage" onChange={(e) => this.handleExamHistoryChange(e, i)} disabled={this.state.readOnly}>
                                                <option />
                                                <option>0</option>
                                                <option>I</option>
                                                <option>II</option>
                                                <option>III</option>
                                                <option>IV</option>
                                            </select>
                                        </div>
                                        <div className="col col-lg-2 col-sm-4 col-12">
                                            <label className="form-label">MRI Performed</label>
                                            <select className="form-control-custom" value={history.mriPerformed || ''} name="mriPerformed" onChange={(e) => this.handleExamHistoryChange(e, i)} disabled={this.state.readOnly}>
                                                <option />
                                                <option>Yes</option>
                                                <option>No</option>
                                            </select>
                                        </div>

                                    </div>
                                </fieldset>
                            </div>
                        }

                        <div className="form-group col-lg-8">
                            <label className="form-label">Notes</label>
                            <textarea className="form-control-custom" rows="6" name="notes" value={history.notes || ''} onChange={(e) => this.handleExamHistoryChange(e, i)} disabled={this.state.readOnly} />
                        </div>

                    </div>

                </Card.Body>
            </Card>
        );
    }
}