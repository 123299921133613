import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ReportComponentTable from './ReportComponentTable';
import Axios from '../../../config/axios';
import NumberFormat from 'react-number-format'
import { Button, Modal } from 'react-bootstrap';
import { MyToast } from '../../Helpers/Toast';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Editor } from '@tinymce/tinymce-react';

class ReportComponents extends Component {

    constructor(props) {
        super(props);

        this.state = {
            components: [],
            component: null,
            loading: true,
            loadComponent: true,
            showModal: false,
            formFields: {
                componentID: "00000000-0000-0000-0000-000000000000",
                componentName: "",
                componentType: "",
                componentClass: "",
                componentHtml: "",
                inactiveYN: 0
            },
            showToast: false,
            toastContent: {
                title: '',
                content: ''
            }
        }

        this.handleClick = this.handleClick.bind(this);
        this.GetAllComponents = this.GetAllComponents.bind(this);
        this.addNew = this.addNew.bind(this);
        this.toggleToast = this.toggleToast.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.notifyFail = this.notifyFail.bind(this);
        this.notifySuccess = this.notifySuccess.bind(this);

    }

    componentDidMount() {
        this.GetAllComponents();
    }

    notifySuccess = () => toast.success('Component updated.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true
    });

    notifyFail = () => toast.error('Something went wrong', {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true
    });

    handleClick(event) {
        event.preventDefault();

        switch (event.target.id) {
            case 'backToResults':

                this.setState({
                    patient: null,
                    loading: true,
                    loadPatient: true
                }, () => this.GetAllPatients());
                break;
            case 'closeModal':
                this.setState({
                    showModal: !this.state.showModal
                });
                break;
            default:
                break;
        }
    }

    editComponent(component) {

        //console.log(component)

        this.setState({
            formFields: component,
            //editorContent: '<div>Hello</div>',
            //editorContent: component.componentHtml,
            showModal: true
        });
    }

    addNew(event) {
        event.preventDefault();

        this.setState({
            formFields: {
                componentID: "00000000-0000-0000-0000-000000000000",
                componentName: "",
                componentType: "",
                componentClass: "",
                componentHtml: "",
                inactiveYN: 0
            },
            //showModal: !this.state.showModal
            showModal: true
        });
    }

    toggleToast(toastContent) {
        if (toastContent) {
            this.setState({
                showToast: !this.state.showToast,
                toastContent
            });
        } else {
            this.setState({
                showToast: !this.state.showToast
            });
        }
    }

    GetAllComponents() {
        Axios.get(`/api/GetAllComponents`
        ).then(response => {
            console.log(response.data)
            this.setState({
                components: response.data,
                loading: false
            });
        }).catch(error => {

            console.log(error);
        });
    }

    handleInputChange(event) {
        const target = event.target;
        var value = "";
        if (target.type === 'checkbox') {
            value = target.checked === true ? "1" : "0";
        }
        else {
            value = target.value;
        }
        const name = target.name;

        console.log(name);
        console.log(value);

        this.setState({ formFields: { ...this.state.formFields, [name]: value } });
    }

    handleSubmit(event) {
        event.preventDefault();

        let ffCopy = { ...this.state.formFields };

        let postdata = ffCopy

        let toastContent = {
            title: '',
            content: ''
        };

        if (ffCopy.componentID && ffCopy.componentID !== "00000000-0000-0000-0000-000000000000") {
            //if updating current entry
            
            Axios.post(`/api/UpdateComponent`, postdata
            ).then(response => {
                toastContent.title = 'Success';
                toastContent.content = "Component has been updated."
                this.toggleToast(toastContent);
                this.setState({
                    showModal: !this.state.showModal
                });
                this.GetAllComponents();
            }).catch(error => {
                this.notifyFail('Fail');
                console.log(error);
            });
        } else {
            //if new entry
            
            Axios.post(`/api/InsertComponent`, postdata
            ).then(response => {
                toastContent.title = 'Success';
                toastContent.content = "Component has been added."
                this.toggleToast(toastContent);
                this.setState({
                    showModal: !this.state.showModal
                });
                this.GetAllComponents();
            }).catch(error => {
                console.log(error);
            });
        }
    }

    handleEditorChange = (content, editor) => {

        //console.log(content);

        //this.setState({
        //    editorContent: content
        //    formFields: { ...this.state.formFields, [name]: value }
        //});

        this.setState({ formFields: { ...this.state.formFields, 'componentHtml': content } });
    }

    render() {
        return (
            <div>
                {
                    this.state.loadComponent &&
                        <div className="row">
                            <div className="col-lg-12" >
                                <div className="panel-hdr">
                                    <h2>
                                        Report Components
                                    </h2>

                                    <button type="button" onClick={this.addNew} className="btn btn-outline-primary margin-0-10">
                                        New
                                    </button>
                                </div>
                                <div className="frame-wrap">
                                    {!this.state.loading && !this.state.showModal &&
                                        <ReportComponentTable data={this.state.components} editComponent={(component) => this.editComponent(component)} />
                                    }

                                </div>
                            </div >
                        </div >
                }

                {this.state.showModal &&
                    <div style={{ backgroundColor: 'gainsboro', padding: '10px' }}>
                        <div className="panel-content">
                            <form>
                                <div className="row">
                                    <div className="form-group col-lg-4">
                                        <label className="form-label">Component Name</label>
                                        <input type="text" name="componentName" value={this.state.formFields.componentName || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                    </div>
                                </div>
                                <br />
                                <br />
                                <div className="row">
                                    <div className="form-group col-lg-12">
                                        <label className="form-label">Component Html</label>
                                        <Editor
                                            onEditorChange={this.handleEditorChange}
                                            //initialValue={this.state.formFields.componentHtml}
                                            value={this.state.formFields.componentHtml}
                                            init={{
                                                valid_elements: '+*[*]',
                                                height: 500,
                                                selector: 'textarea',
                                                image_title: true,
                                                plugins: 'advlist autolink lists link image charmap print preview hr anchor pagebreak code',
                                                branding: false,
                                                toolbar: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | image | mergeFieldsBtn | code',
                                                setup: function (editor) {
                                                    editor.ui.registry.addMenuButton('mergeFieldsBtn', {
                                                        text: 'Merge Fields',
                                                        fetch: function (callback) {
                                                            var items = [
                                                                {
                                                                    type: 'nestedmenuitem',
                                                                    text: 'Patient Fields',
                                                                    getSubmenuItems: function () {
                                                                        return [
                                                                            { type: 'menuitem', text: 'First Name', onAction: function () { editor.insertContent('{{patient.firstname}}'); } },
                                                                            { type: 'menuitem', text: 'Last Name', onAction: function () { editor.insertContent('{{patient.lastname}}'); } },
                                                                            { type: 'menuitem', text: 'Address 1', onAction: function () { editor.insertContent('{{patient.address1}}'); } },
                                                                            { type: 'menuitem', text: 'Address 2', onAction: function () { editor.insertContent('{{patient.address2}}'); } },
                                                                            { type: 'menuitem', text: 'City', onAction: function () { editor.insertContent('{{patient.city}}'); } },
                                                                            { type: 'menuitem', text: 'State', onAction: function () { editor.insertContent('{{patient.state}}'); } },
                                                                            { type: 'menuitem', text: 'Zip', onAction: function () { editor.insertContent('{{patient.zip}}'); } },
                                                                            { type: 'menuitem', text: 'Recall Date', onAction: function () { editor.insertContent('{{patient.recalldate}}'); } }
                                                                        ];
                                                                    }
                                                                },
                                                                {
                                                                    type: 'nestedmenuitem',
                                                                    text: 'Facility Fields',
                                                                    getSubmenuItems: function () {
                                                                        return [
                                                                            { type: 'menuitem', text: 'Facility Name', onAction: function () { editor.insertContent('{{facility.facilityname}}'); } },
                                                                            { type: 'menuitem', text: 'Logo', onAction: function () { editor.insertContent('{{facility.logo}}'); } },
                                                                            { type: 'menuitem', text: 'Address 1', onAction: function () { editor.insertContent('{{facility.address1}}'); } },
                                                                            { type: 'menuitem', text: 'City', onAction: function () { editor.insertContent('{{facility.city}}'); } },
                                                                            { type: 'menuitem', text: 'State', onAction: function () { editor.insertContent('{{facility.state}}'); } },
                                                                            { type: 'menuitem', text: 'Zip', onAction: function () { editor.insertContent('{{facility.zip}}'); } },
                                                                            { type: 'menuitem', text: 'Phone', onAction: function () { editor.insertContent('{{facility.phone}}'); } }
                                                                        ];
                                                                    }
                                                                },
                                                            ];
                                                            callback(items);
                                                        }
                                                    });
                                                }
                                            }}
                                            config={{

                                            }}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <button type="button" onClick={this.handleSubmit} className="btn btn-primary">
                            Save
                        </button>
                        &nbsp;<a id="closeModal" onClick={this.handleClick}>Cancel</a>

                    </div>
                }
            </div>
        );
    }
}

export default withRouter(ReportComponents);