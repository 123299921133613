import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ManageReportBuilder from './Manage/ManageReportBuilder';
import { getAdminItems } from '../../common/services/ReportService';
import ManagePrintIcons from './Manage/ManagePrintIcons';
import ManageRecallDefinitions from './Manage/ManageRecallDefinitions';
import ManageBiradDefinitions from './Manage/ManageBiradDefinitions';
import ManageUsers from './Manage/ManageUsers';
import ManageFacilities from './Manage/ManageFacilities';

class AdminHome extends Component {

    componentDidMount() {
        getAdminItems();
    }

    render() {
        return (
            <div className="container-fluid h-100">
                <div className="row h-100">
                    <div className="col-sm-3 col-12 left-nav">
                        <ul className="nav flex-column nav-stacked">
                            <li className="nav-item">
                                <a className='active nav-link fs-lg px-4' data-toggle="tab" href="#tab-manage-reports" role="tab">
                                    <i className="fal fa-th-large fa-1x text-primary" />
                                    <span className="hidden-sm-down ml-1">Manage Report Builder</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className='nav-link fs-lg px-4' data-toggle="tab" href="#tab-manage-print" role="tab">
                                    <i className="fas fa-print fa-1x text-primary" />
                                    <span className="hidden-sm-down ml-1">Manage Print Icons</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className='nav-link fs-lg px-4' data-toggle="tab" href="#tab-manage-recall-definitions" role="tab">
                                    <i className="fas fa-ticket-alt fa-1x text-primary" />
                                    <span className="hidden-sm-down ml-1">Manage Recall Definitions</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className='nav-link fs-lg px-4' data-toggle="tab" href="#tab-manage-birad-definitions" role="tab">
                                    <i className="far fa-envelope-open fa-1x text-primary" />
                                    <span className="hidden-sm-down ml-1">Manage PPL Definitions</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className='nav-link fs-lg px-4' data-toggle="tab" href="#tab-manage-users" role="tab">
                                    <i className="fas fa-user-cog fa-1x text-primary" />
                                    
                                    <span className="hidden-sm-down ml-1">Manage Users</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className='nav-link fs-lg px-4' data-toggle="tab" href="#tab-manage-facilities" role="tab">
                                    <i className="far fa-hospital fa-1x text-primary" />

                                    <span className="hidden-sm-down ml-1">Manage Facilities</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-sm-9 col-12 tab-content p-3">
                        <div className="tab-pane fade show active" id="tab-manage-reports" role="tabpanel" aria-labelledby="tab-manage-reports">
                            <ManageReportBuilder />
                        </div>

                        <div className="tab-pane fade" id="tab-manage-print" role="tabpanel" aria-labelledby="tab-manage-print">
                            <ManagePrintIcons />
                        </div>

                        <div className="tab-pane fade" id="tab-manage-recall-definitions" role="tabpanel" aria-labelledby="tab-manage-recall-definitions">
                            <ManageRecallDefinitions />
                        </div>
                        <div className="tab-pane fade" id="tab-manage-birad-definitions" role="tabpanel" aria-labelledby="tab-manage-birad-definitions">
                            <ManageBiradDefinitions />
                        </div>
                        <div className="tab-pane fade" id="tab-manage-users" role="tabpanel" aria-labelledby="tab-manage-users">
                            <ManageUsers />
                        </div>
                        <div className="tab-pane fade" id="tab-manage-facilities" role="tabpanel" aria-labelledby="tab-manage-facilities">
                            <ManageFacilities />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(AdminHome);