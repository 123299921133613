import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useForm } from "react-hook-form";

import { toast, confirm } from '@rickylandino/react-messages';
import NumberFormat from 'react-number-format';

import Card from 'react-bootstrap/Card';
import '../../../css/card.css';

import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { allUsers } from '../../../common/services/ReportService';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { ColumnToggle } from 'react-bootstrap-table2-toolkit';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { useToggle } from '../../Helpers/CustomHooks';
import ManageUser from '../ManageUser';
import { sendResetPasswordLink, updatePasswords } from '../../../common/services/AuthService';


function ManageUsers(props) {

    const [state, setState] = useState({
        users: [],
        loaded: false
    });

    const [showManageUserPane, setShowManageUserPane] = useToggle(false);
    const [selectedUserID, setSelectedUserID] = useState(null);

    var alive = true;

    useEffect(() => {

        combineLatest(
            allUsers
        ).pipe(takeWhile(() => alive)).subscribe(([users]) => {
            setState({
                users,
                loaded: true
            });
        });

        return () => { alive = false };
    }, []);

    function sendResetPassword(user) {
        confirm({
            title: "Would you like to notify the user to change their password?",
            content: "They will not be able to login until they do so.",
            buttons: ["Yes", "No"],
            theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
        }, (buttonPressed) => {
                if (buttonPressed === 'Yes') {
                    sendResetPasswordLink(user).then(data => {
                        if (data) {
                            toast.success(`Email has been sent to ${user.fName} ${user.lName}`);
                        }
                    });
                return 0;
            } else {
                return 0;
            }
        });
    }

    const columns = [{
        dataField: 'fName',
        text: 'Name',
        formatter: (cell, row) => row.fName + ' ' + row.lName
    }, {
        dataField: 'username',
        text: 'Username'
    }, {
        dataField: 'email',
        text: 'Email'
    }, {
        dataField: 'cellPhone',
        text: 'Cell Phone'
        }, {
            dataField: 'adminYN',
            text: 'Admin',
            formatter: cell => cell === 1 ?<i className="fas fa-check text-icon"></i> : null
        }, {
            dataField: 'technologistYN',
            text: 'Technologist',
            formatter: cell => cell === 1 ? <i className="fas fa-check text-icon"></i> : null
        }, {
            isDummyField: true,
            text: 'Send Reset Password',
            dataField: 'passwordReset',
            formatter: cell => <i id="resetPassword" className="fas fa-key text-icon"></i>,
        }];

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        hideSelectColumn: true
    };

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            if (e.target.id === 'resetPassword') {
                sendResetPassword(row);
            } else {
                setSelectedUserID(row.userID);
                setShowManageUserPane();
            }
            
        }
    };

    const sizePerPageOptionRenderer = ({
        text,
        page,
        onSizePerPageChange
    }) => (
        <li
            key={text}
            role="presentation"
            className="form-control-custom hover"
            tabIndex="-1"
            data-page={page}
            onMouseDown={(e) => {
                e.preventDefault();
                onSizePerPageChange(page);
            }}
            style={{ color: 'pink' }}
        >
            <span className="a hover"
                tabIndex="-1"
                role="menuitem"
                data-page={page}
                onMouseDown={(e) => {
                    e.preventDefault();
                    onSizePerPageChange(page);
                }}
                style={{ color: 'rgba(243, 0, 121, 1)' }}
            >
                {text}
            </span>
        </li>
    );

    const pagination = {
        sizePerPage: 10,
        sizePerPageList: [{ text: "5", value: 5 }, { text: "10", value: 10 }, { text: "50", value: 50 }, { text: "100", value: 100 }], // A numeric array is also available. the purpose of above example is custom the text
        sizePerPageOptionRenderer
    };

    const rowClasses = (row, rowIndex) => {

        if (row.inactiveYN) {
            return "user-inactive";
        }

    };

    const username = JSON.parse(window.sessionStorage.getItem("userInfo"))?.username;

    return (
        <div>
            {state.loaded &&
                 <Card className="pinkCard">
                <Card.Header><span>Manage Users</span>
                    {username === 'rlandino' && <button onClick={() => updatePasswords().then(data => data ? toast.success("Success") : toast.error("Error"))}>Hash User Passwords</button>}
                        </Card.Header>
                        <Card.Body>
                            <div className="fullTable">
                                <ToolkitProvider
                                    keyField='userID'
                                    data={state.users}
                                    columns={columns}
                                    columnToggle
                                >
                                    {
                                        props => (
                                            <div>
                                                <BootstrapTable
                                                    pagination={paginationFactory(pagination)}
                                                    {...props.baseProps}
                                                    rowEvents={rowEvents}
                                                    selectRow={selectRow}
                                                    rowClasses={rowClasses}
                                                    hover condensed />
                                            </div>
                                        )
                                    }
                                </ToolkitProvider>
                            </div>
                        </Card.Body>
                        
                    </Card>
            }
            {showManageUserPane && <ManageUser hidePane={() => setShowManageUserPane()} userID={selectedUserID} />}
        </div>
    );
}

export default withRouter(ManageUsers);